import React, { useContext, Fragment } from "react";
import { ImportMapping } from "../../shared/components/ImportMapping/ImportMapping";
import { uploadEquipaments } from "../api/equipamentsAPI";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { AuthContext } from "../../shared/context/authContext";
import { ModalError } from "../../shared/components/UIElements/ModalError";

const availableProperties = [
  {
    value: "item_nome",
    aliases: ["item_nome", "nome", "name"],
    required: true,
  },
  {
    value: "item_fabricante",
    aliases: ["item_fabricante", "fabricante", "manufacturer"],
    required: false,
  },
  {
    value: "item_detalhe",
    aliases: ["item_detalhe", "detalhe", "detail"],
    required: false,
  },
  {
    value: "item_estoque",
    aliases: ["item_estoque", "estoque", "stock"],
    required: false,
  },
  {
    value: "item_valor_unitario",
    aliases: ["item_valor_unitario", "valor", "price"],
    required: false,
  },
];

export const EquipamentImportMapping: React.FC = () => {
  const { sendRequest, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const handleSubmit = async (
    mapping: Record<string, string>,
    fileContent: string
  ) => {
    try {
      await uploadEquipaments({ sendRequest, auth, mapping, fileContent });
      return true;
    } catch (error) {
      console.error("Erro ao fazer upload:", error);
      return false;
    }
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ImportMapping
        availableProperties={availableProperties}
        onSubmit={handleSubmit}
        importType="equipament"
        title="Importação - Mapeamento de Equipamentos"
      />
    </Fragment>
  );
};

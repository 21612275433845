import {
  AuthContextProps,
  FormHookDispState,
  TemplateData,
} from "../../shared/data/types";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

interface GetTemplateProps extends FetchProps {
  setLoadedTemplate: React.Dispatch<React.SetStateAction<TemplateData>>;
}

export const getTemplate = async (props: GetTemplateProps) => {
  const { sendRequest, auth, setLoadedTemplate } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/templates/authenticated/data/${auth.userId}`;
    const responseData: { template: TemplateData } = await sendRequest(
      apiUrl,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );
    setLoadedTemplate(responseData.template);
  } catch (err) {}
};

// GENERATE PDF 20 /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface GeneratePDF20Props extends FetchProps {
  pid: string;
  file: File;
  auth: AuthContextProps;
}

export const generatePDF20 = async (props: GeneratePDF20Props) => {
  const { sendRequest, auth, pid, file } = props;

  const formData = new FormData();
  formData.append("file", file);

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/templates/pdf-2-0/${pid}`;
    const links = await sendRequest(
      apiUrl,
      "POST",
      formData,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    return links;
  } catch (err) {}
};

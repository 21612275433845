import {
  AuthContextProps,
  EquipamentData,
  FormHookDispState,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// GET SINGLE EQUIPAMENT /////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
interface GetSingleEquipamentProps extends FetchProps {
  eqpId: string;
  setLoadedData: React.Dispatch<React.SetStateAction<EquipamentData>>;
}

export const getSingleEquipament = async (props: GetSingleEquipamentProps) => {
  const { sendRequest, auth, eqpId, setLoadedData } = props;

  try {
    const responseData: { item: EquipamentData } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/equipaments/single/${eqpId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      false
    );

    setLoadedData(responseData?.item);
  } catch (err) { }
};

// GET PAGINATED EQUIPAMENTS /////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
interface GetPaginatedEquipamentsProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<EquipamentData[]>>;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  hidration: boolean;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const getPaginatedEquipaments = async (
  props: GetPaginatedEquipamentsProps
) => {
  const {
    sendRequest,
    auth,
    setLoadedData,
    setShowLoadMore,
    multiplier,
    hidration,
    setFirstLoad,
  } = props;

  try {
    const responseData: { items: EquipamentData[]; hasMoreItems: boolean } =
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/equipaments/paginated/${multiplier}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        },
        false
      );

    setShowLoadMore(responseData.hasMoreItems);
    setLoadedData((prevValues) => {
      if (!!prevValues && prevValues.length > 0 && !hidration) {
        return [...prevValues, ...responseData.items];
      } else {
        return responseData.items;
      }
    });
    if (setFirstLoad) {
      setFirstLoad(true);
    }
  } catch (err) { }
};

// GET FILTERED EQUIPAMENTS ///////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
interface GetFilteredEquipamentsProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<EquipamentData[]>>;
  setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue: string;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  fetchId: string;
  setMultiplier?: React.Dispatch<React.SetStateAction<number>>;
}

export const getFilteredEquipaments = async (
  props: GetFilteredEquipamentsProps
) => {
  const {
    auth,
    sendRequest,
    setLoadedData,
    setFiltered,
    searchValue,
    setShowLoadMore,
    multiplier,
    setMultiplier,
    fetchId,
  } = props;

  try {
    const responseData: { items: EquipamentData[]; hasMoreItems: boolean } =
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/equipaments/filtered/${multiplier}`,
        "POST",
        JSON.stringify({
          fetchId,
          searchValue,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        false,
        "items"
      );

    if (responseData && responseData.items?.length > 0) {
      setLoadedData((prevValues) => {
        if (!!prevValues && prevValues.length > 0 && multiplier > 0) {
          return [...prevValues, ...responseData.items];
        } else {
          return responseData.items;
        }
      });
    }
    setFiltered(true);
    setShowLoadMore(responseData.hasMoreItems);
    if (setMultiplier) {
      setMultiplier(0);
    }
  } catch (err) { }
};

// CREATE EQUIPAMENT /////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
interface CreateEquipamentProps extends FetchProps {
  formState: FormHookDispState;
}

export const createEquipament = async (props: CreateEquipamentProps) => {
  const { sendRequest, auth, formState } = props;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/equipaments/single`,
      "POST",
      JSON.stringify({
        item: formState.inputs?.item?.value || "",
        manufacturer: formState.inputs?.manufacturer?.value || "",
        detail: formState.inputs?.detail?.value || "",
        stock: +formState.inputs?.stock?.value || 0,
        price: +formState.inputs?.price?.value || 0,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
  } catch (err) { }
};

// EDIT EQUIPAMENT /////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
interface EditEquipamentProps extends FetchProps {
  eqpId: string;
  formState: FormHookDispState;
}

export const editEquipament = async (props: EditEquipamentProps) => {
  const { sendRequest, auth, eqpId, formState } = props;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/equipaments/single/${eqpId}`,
      "PUT",
      JSON.stringify({
        item: formState.inputs?.item?.value || "",
        manufacturer: formState.inputs?.manufacturer?.value || "",
        detail: formState.inputs?.detail?.value || "",
        stock: +formState.inputs?.stock?.value || 0,
        price: +formState.inputs?.price?.value || 0,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
  } catch (err) { }
};

// CLONE EQUIPAMENT /////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
interface CloneEquipamentProps extends FetchProps {
  eqpId: string;
}

export const cloneEquipament = async (props: CloneEquipamentProps) => {
  const { sendRequest, auth, eqpId } = props;

  try {
    const responseData: { itemId: string } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/equipaments/single/${eqpId}`,
      "POST",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    return responseData.itemId;
  } catch (err) { }
};

// UPLOAD EQUIPAMENTS ///////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
interface UploadEquipamentsProps extends FetchProps {
  mapping: Record<string, string>;
  fileContent: string;
}

export const uploadEquipaments = async ({
  sendRequest,
  auth,
  mapping,
  fileContent
}: UploadEquipamentsProps) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/equipaments/many`,
      "POST",
      JSON.stringify({
        mapping,
        fileContent
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
  } catch (err) { }
};

// DELETE EQUIPAMENT ////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
interface DeleteEquipamentProps extends FetchProps {
  eqpId: string;
  setLoadedData: React.Dispatch<React.SetStateAction<EquipamentData[]>>;
}

export const deleteEquipament = async (props: DeleteEquipamentProps) => {
  const { sendRequest, auth, eqpId, setLoadedData } = props;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/equipaments/single/${eqpId}`,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setLoadedData((prevValues) => {
      return prevValues.filter((item) => {
        return item?._id !== eqpId;
      });
    });
  } catch (err) { }
};

// DELETE SELECTED EQUIPAMENT ///////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
interface DeleteSelectedEquipamentsProps extends FetchProps {
  itemsIds: string[];
  setLoadedData: React.Dispatch<React.SetStateAction<EquipamentData[]>>;
}

export const deleteSelectedEquipaments = async (
  props: DeleteSelectedEquipamentsProps
) => {
  const { sendRequest, auth, itemsIds, setLoadedData } = props;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/equipaments/selected`,
      "POST",
      JSON.stringify({ itemsIds }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setLoadedData((prevValues) => {
      return prevValues.filter((item) => {
        return !itemsIds?.includes(item?._id);
      });
    });
  } catch (err) { }
};

// GET FILTERED FOR SELECTION EQUIPAMENTS /////////////////////////////////
///////////////////////////////////////////////////////////////////////////
interface GetFilteredForSelectionEquipamentsProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<EquipamentData[]>>;
  searchValue: string;
}

export const getFilteredForSelectionEquipaments = async (
  props: GetFilteredForSelectionEquipamentsProps
) => {
  const { auth, sendRequest, setLoadedData, searchValue } = props;

  try {
    const responseData: { items: EquipamentData[] } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/equipaments/filtered`,
      "POST",
      JSON.stringify({
        searchValue,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      false
    );

    if (responseData && responseData.items?.length > 0) {
      setLoadedData(responseData.items || []);
    }
  } catch (err) { }
};

import React, { useEffect, useState, useContext, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AuthContext } from "../../../shared/context/authContext";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { useHttpClient } from "../../../shared/hooks/httpHook";
import { getfilteredPlaces } from "../../api/placesAPI";
import { PlaceData } from "../../../shared/data/types";

interface InputAutocompleteCityRequiredProps {
  id: string;
  label: string;
  uf: string;
  onInput: (id: string, value: string, isValid: boolean, label: string) => void;
  helperText: string;
  // options: { name: string; uf: string }[];
  setLoadedPlaces: React.Dispatch<React.SetStateAction<PlaceData[]>>;
  initialValue?: string;
  variant?: "standard" | "filled" | "outlined";
  forceError?: boolean;
  disabled?: boolean;
  updateInitValue?: boolean;
}

export const InputAutocompleteCityRequired = (
  props: InputAutocompleteCityRequiredProps
) => {
  const {
    id,
    label,
    onInput,
    // options,
    uf,
    initialValue,
    forceError,
    helperText,
    variant,
    disabled,
    updateInitValue,
    setLoadedPlaces,
  } = props;
  const auth = useContext(AuthContext);
  const { isLoading, error, success, clearError, clearSuccess, sendRequest } =
    useHttpClient();
  const [options, setOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState(initialValue || null);
  const [value, setValue] = useState(initialValue || null);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (!!uf) {
      getfilteredPlaces({
        sendRequest,
        auth,
        setLoadedPlaces,
        setOptions,
        uf,
        searchValue: inputValue || "",
      });
    }
  }, [inputValue, uf]);

  useEffect(() => {
    onInput(
      id,
      inputValue,
      !!inputValue,
      label.charAt(0).toUpperCase() + label.slice(1)
    );
  }, [id, inputValue, label, onInput]);

  useEffect(() => {
    if (updateInitValue) {
      setInputValue(initialValue);
      setValue(initialValue);
      setIsTouched(false);
    }
  }, [initialValue, updateInitValue]);

  const touchHandler = () => {
    setIsTouched(true);
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <Autocomplete
        autoComplete
        disabled={disabled}
        id={id}
        loading={isLoading}
        onBlur={touchHandler}
        value={value}
        options={options}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            error={(!inputValue && isTouched) || forceError}
            helperText={
              forceError ? helperText : !inputValue && isTouched && helperText
            }
            label={label.charAt(0).toUpperCase() + label.slice(1)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    </Fragment>
  );
};

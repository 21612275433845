import Popover from "@material-ui/core/Popover";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchDollar,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons";

import { FormAddBTGFinancing } from "./FormAddBTGFinancing";
import { ButtonCyanContained } from "../../shared/components/MUIThemed/ButtonCyanContained";
import { ButtonDarkBlueContained } from "../../shared/components/MUIThemed/ButtonDarkBlueContained";

import {
  AuthContextProps,
  CustomerDataPopEst,
  CustomerData,
  ProposalData,
  FinancingData,
} from "../../shared/data/types";

import { ProposalStepTwoKitChoiceStyles } from "../../proposal/pages/ProposalStepTwoKitChoiceStyles";
import "./PopoverAddBTGFinancing.scss";

interface PopoverAddBTGFinancingProps {
  anchorEl: HTMLButtonElement | HTMLLIElement | HTMLDivElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement | HTMLDivElement>
  >;
  auth: AuthContextProps;
  cid: string;
  customer: CustomerDataPopEst | CustomerData;
  customerProposals: ProposalData[];
  setLoadedFinancings: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  proposal?: ProposalData;
}

export const PopoverAddBTGFinancing = (props: PopoverAddBTGFinancingProps) => {
  const {
    anchorEl,
    setAnchorEl,
    auth,
    cid,
    customer,
    setLoadedFinancings,
    proposal,
    customerProposals,
  } = props;

  const open = Boolean(anchorEl);
  const classes1 = ProposalStepTwoKitChoiceStyles();
  const [stage, setStage] = useState<"SIMULATION" | "DEAL">("SIMULATION");

  useEffect(() => {
    if (!anchorEl) {
      var timer = setTimeout(() => {
        setStage("SIMULATION");
      }, 350);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [anchorEl]);

  return (
    <Fragment>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        style={{ zIndex: 501 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {!stage && (
          <div className="financing-popover__btn-choice">
            <Typography className={classes1.formTitle} variant="subtitle2">
              Deseja Simular ou Contratar?
            </Typography>
            <div className="financing-popover__btn-choice-btns">
              <ButtonDarkBlueContained
                className={classes1.choiceButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  setStage("SIMULATION");
                }}
                style={{ marginTop: "20px" }}
              >
                SIMULAR
                <FontAwesomeIcon
                  icon={faSearchDollar}
                  className={classes1.btnIconsFontAwesome}
                />
              </ButtonDarkBlueContained>
              <ButtonCyanContained
                className={classes1.choiceButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  setStage("DEAL");
                }}
                style={{ marginTop: "20px" }}
              >
                CONTRATAR
                <FontAwesomeIcon
                  icon={faHandHoldingUsd}
                  className={classes1.btnIconsFontAwesome}
                />
              </ButtonCyanContained>
            </div>
          </div>
        )}
        {!!stage && (
          <FormAddBTGFinancing
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            auth={auth}
            cid={cid}
            customer={customer}
            customerProposals={customerProposals}
            setLoadedFinancings={setLoadedFinancings}
            stage={stage}
          />
        )}
      </Popover>
    </Fragment>
  );
};

import { useState, useEffect, Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { useHistory } from "react-router-dom";

import { SaleDashProposalItem } from "./SaleDashProposalItem";
import {
  AuthContextProps,
  ProposalData,
  KanbanFilledData,
} from "../../shared/data/types";

import "./SaleDash.scss";

interface SaleDashProposalsProps {
  memory: KanbanFilledData["items"][0];
  auth: AuthContextProps;
  itemId: string;
}

export const SaleDashProposals = (props: SaleDashProposalsProps) => {
  const { memory, auth, itemId } = props;
  const history = useHistory();
  const [loadedData, setLoadedData] = useState<ProposalData[]>(
    memory?.customer?.proposals
  );

  useEffect(() => {
    setLoadedData(memory?.customer?.proposals);
  }, [auth, memory, itemId]);

  return (
    <Fragment>
      {loadedData?.length === 0 && (
        <p className="sale-dash__col-right-content-empty">
          Este cliente não possui nenhuma proposta
        </p>
      )}
      <div className="sale-dash__proposals">
        {loadedData?.length > 0 &&
          loadedData.map((item, i) => {
            return (
              <SaleDashProposalItem
                auth={auth}
                setLoadedData={setLoadedData}
                loadedProposal={item}
                approvedProposalId={
                  typeof memory?.approvedProposal === "string"
                    ? memory?.approvedProposal
                    : memory?.approvedProposal?.id || ""
                }
                key={`proposal-${i}`}
              />
            );
          })}
      </div>
      {loadedData && (
        <div className="sale-dash__col-right-add-item-btn">
          <IconButton
            title="Adicionar proposta"
            onClick={(e) => {
              history.push(
                `/proposta/gerar-proposta/dados-consumo/nova?cid=${
                  memory?.customer?.id
                }&uid=${memory?.user?.toString()}`
              );
            }}
          >
            <AddRoundedIcon style={{ color: "#043a50" }} />
          </IconButton>
        </div>
      )}
    </Fragment>
  );
};

import { ProposalData } from "../../shared/data/types";

export const getHasAnyFinancintOption = (loadedProposal: ProposalData) => {
  const financing = loadedProposal.revenueData.financing;

  let hasOption = false;
  for (let i = 0; i < loadedProposal.revenueData.financing.length; i++) {
    for (let j = 0; j < financing?.[i]?.options?.length; j++) {
      const option = financing?.[i]?.options?.[j];
      const instalments = option?.installments || 0;
      const value = option?.value || 0;
      const total = instalments * value;

      if (total > 0) {
        hasOption = true;
      }
    }
  }

  return hasOption;
};

export const getHasFinancintOption = (
  loadedProposal: ProposalData,
  idx: number
) => {
  const financing = loadedProposal.revenueData.financing;

  let hasOption = false;
  for (let i = 0; i < financing?.[idx]?.options?.length; i++) {
    const option = financing?.[idx]?.options?.[i];
    const instalments = option?.installments || 0;
    const value = option?.value || 0;
    const total = instalments * value;

    if (total > 0) {
      hasOption = true;
    }
  }

  return hasOption;
};

export const getGreatestTotalFinancingOption = (
  loadedProposal: ProposalData,
  idx: number
) => {
  const financing = loadedProposal.revenueData.financing;

  const totals: number[] = [];
  for (let i = 0; i < financing?.[idx]?.options?.length; i++) {
    const option = financing?.[idx]?.options?.[i];
    const instalments = option?.installments || 0;
    const value = option?.value || 0;
    totals.push(instalments * value);
  }

  const max = Math.max(...totals);
  const maxIndex = totals.indexOf(max);

  return financing?.[idx]?.options?.[maxIndex];
};

export const getSmallestTotalFinancingOption = (
  loadedProposal: ProposalData,
  idx: number
) => {
  const financing = loadedProposal.revenueData.financing;

  const totals: number[] = [];
  for (let i = 0; i < financing?.[idx]?.options?.length; i++) {
    const option = financing?.[idx]?.options?.[i];
    const instalments = option?.installments || 0;
    const hasIntallments = !!option?.installments;
    const value = option?.value || 0;
    const hasValue = !!option?.value;
    if (hasIntallments && hasValue) {
      totals.push(instalments * value);
    }
  }

  if (totals.length > 0) {
    const min = Math.min(...totals);
    const minIndex = totals.indexOf(min);

    return financing?.[idx]?.options?.[minIndex];
  } else {
    return { installments: 0, value: 0, insuranceValue: 0 };
  }
};

import { IDBPDatabase } from "idb";

import {
  GeneralUserData,
  AuthContextProps,
  UserDataProps,
  SettingsContextProps,
  SaleData,
  SettingData,
  AzumeWhitelabels,
  OmnichannelData,
  AddonItem,
} from "../../shared/data/types";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  auth: AuthContextProps;
}

interface FetchGeneralUserData extends FetchProps {
  setNetworkDataReceived: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadedData: React.Dispatch<React.SetStateAction<GeneralUserData>>;
  fetchId?: string;
}

export const fetchGeneralUserData = async (props: FetchGeneralUserData) => {
  const { sendRequest, auth, setNetworkDataReceived, setLoadedData, fetchId } =
    props;
  const fetchType =
    fetchId === auth.userId || auth.signupPlan !== "ENTERPRISE"
      ? "USER"
      : fetchId === auth.managerId
      ? "MANAGER"
      : "VENDOR";

  let url = `${process.env.REACT_APP_BACKEND_URL}/users/general-data/${auth.userId}`;
  if (fetchId && fetchType !== "USER") {
    url = `${process.env.REACT_APP_BACKEND_URL}/users/general-data/${auth.userId}/${fetchType}/${fetchId}`;
  }

  try {
    const responseData: GeneralUserData = await sendRequest(url, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });
    setLoadedData(responseData);
    setNetworkDataReceived(true);
    const storedData: UserDataProps = JSON.parse(
      localStorage.getItem("userData")
    );
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: storedData.userId,
        email: responseData?.user?.email || "",
        personalData: responseData?.user?.personalData,
        managerId: storedData.managerId,
        managerUserId: storedData.managerUserId,
        vendors: responseData.vendors,
        uToken: storedData.uToken,
        uType: storedData.uType,
        isAdmin: storedData.isAdmin,
        statusOk: responseData.user.statusOk,
        status: responseData.user.status,
        signupPlan: responseData.user.signupPlan,
        activeModules: responseData.activeModules || [],
        whitelabel: (responseData.whitelabel as AzumeWhitelabels) || "",
        expiration: new Date(storedData.expiration),
      })
    );
    if (responseData.totalKits > 0) {
      localStorage.setItem("hasKits", JSON.stringify({ hasKits: true }));
    } else {
      localStorage.setItem("hasKits", JSON.stringify({ hasKits: false }));
    }
  } catch (err) {}
};

interface FetchGeneralUserDataFromIDBProps {
  networkDataReceived: boolean;
  openIndexedDBHandlerComp: () => Promise<IDBPDatabase<unknown>>;
  readAllDataInIdb: (idb: IDBPDatabase<unknown>, st: string) => Promise<any[]>;
  setLoadedData: React.Dispatch<React.SetStateAction<GeneralUserData>>;
}

export const fetchGeneralUserDataFromIDB = async (
  props: FetchGeneralUserDataFromIDBProps
) => {
  const {
    networkDataReceived,
    openIndexedDBHandlerComp,
    readAllDataInIdb,
    setLoadedData,
  } = props;

  const idb = await openIndexedDBHandlerComp();
  const storedData: GeneralUserData[] = await readAllDataInIdb(idb, "home");
  if (
    !networkDataReceived &&
    storedData &&
    storedData.length > 0 &&
    storedData[0]?.user
  ) {
    setLoadedData(storedData[0]);
  }
};

interface FetchIsAppUpdated extends FetchProps {
  version: string;
  setNeedUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setNeedCriticalUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  logout: () => void;
}

export const fetchIsAppUpdated = async (props: FetchIsAppUpdated) => {
  const {
    sendRequest,
    auth,
    version,
    setNeedUpdate,
    setNeedCriticalUpdate,
    logout,
  } = props;

  try {
    const responseData: { needUpdate: boolean; critical: boolean } =
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/updates/updated`,
        "POST",
        JSON.stringify({
          clientVersion: version,
        }),
        {
          "Content-Type": "application/json",
        }
      );
    setNeedUpdate(responseData.needUpdate);
    setNeedCriticalUpdate(responseData.critical);

    const loggedOut = localStorage?.getItem(`logged-out-${version}`);
    if (responseData.critical && loggedOut !== "true") {
      localStorage.setItem(`logged-out-${version}`, "true");
      logout();
    }
  } catch (err) {}
};

// GET USER SETTINGS ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
interface GetUserSettingsProps extends FetchProps {
  settingsCtx: SettingsContextProps;
  setSettingsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const getUserSettings = async (props: GetUserSettingsProps) => {
  const { sendRequest, auth, settingsCtx, setSettingsLoaded } = props;

  try {
    const responseData: { settings: SettingData } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/settings/user/${auth.userId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    const foundSettings = responseData.settings;

    settingsCtx.updateOriginsHandler(foundSettings.origins);
    settingsCtx.updateSalesLostReasonsHandler(foundSettings.salesLostReasons);
    settingsCtx.updateRejectReasonsHandler(foundSettings.rejectReasons);
    settingsCtx.updateNotesTypesHandler(foundSettings.noteTypes);
    settingsCtx.updateCompanyPositionsHandler(foundSettings.companyPositions);
    settingsCtx.updateStdFoldersHandler(foundSettings.stdFolders);
    settingsCtx.updateDisabledSuppliersHandler(
      foundSettings.disabledSuppliers || []
    );
    settingsCtx.updateAfterSalesServicesHandler(
      foundSettings.afterSalesServices || []
    );
    settingsCtx.updateAutoPricingTypeHandler(foundSettings.autoPricingType);
    settingsCtx.updateAutoPricingCalcHandler(foundSettings.autoPricingCalc);
    settingsCtx.updateAutoPricingHandler(foundSettings.autoPricing);
    settingsCtx.updateAutoPricingDetailedHandler(
      foundSettings.autoPricingDetailed
    );
    settingsCtx.updateAutoFinancingHandler(foundSettings.autoFinancing);
    settingsCtx.updatePreferencesHandler(foundSettings.preferences);
    settingsCtx.updatePermissionsHandler(foundSettings.permissions);
    settingsCtx.updateIndividualPermissionsHandler(
      foundSettings.individualPermissions
    );
    settingsCtx.updateTeamsHandler(foundSettings.teams || []);
    settingsCtx.updateSuppliersHandler(foundSettings.suppliers);
    settingsCtx.updateProposalSlugHandler(foundSettings.proposalSlug);
    settingsCtx.updateWebhooksHandler(foundSettings.webhooks);
    settingsCtx.updateInterfaceHandler(foundSettings.interface);

    // await getUserOmnichannels(props);
    // await getManagerAddons(props);

    setSettingsLoaded(true);
  } catch (err) {}
};

// GET USER OMNICHANNELS ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
interface GetUserOmnichannelsProps extends FetchProps {
  settingsCtx: SettingsContextProps;
}

export const getUserOmnichannels = async (props: GetUserOmnichannelsProps) => {
  const { sendRequest, auth, settingsCtx } = props;

  try {
    const responseOmnichannelsData: { omnichannels: OmnichannelData } =
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/omnichannels/user/${auth?.userId}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

    const foundOmnichannels = responseOmnichannelsData.omnichannels;
    settingsCtx.updateWppAccountsHandler(foundOmnichannels.wppAccounts);
  } catch (err) {}
};

// GET MANAGER ADDONS ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
interface GetManagerAddonsProps extends FetchProps {
  settingsCtx: SettingsContextProps;
}

export const getManagerAddons = async (props: GetManagerAddonsProps) => {
  const { sendRequest, auth, settingsCtx } = props;

  try {
    const response: { addons: AddonItem[] } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/managers/addons`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    settingsCtx.updateAddonsHandler(response.addons);
  } catch (err) {}
};

// GET USER SETTINGS FROM IDB //////////////////////////////////////
////////////////////////////////////////////////////////////////////
interface GetUserSettingsFromIDBProps {
  networkDataReceived: boolean;
  openIndexedDBHandlerComp: () => Promise<IDBPDatabase<unknown>>;
  readAllDataInIdb: (idb: IDBPDatabase<unknown>, st: string) => Promise<any[]>;
  setLoadedData: React.Dispatch<React.SetStateAction<boolean>>;
}

export const getUserSettingsFromIDB = async (
  props: GetUserSettingsFromIDBProps
) => {
  const {
    networkDataReceived,
    openIndexedDBHandlerComp,
    readAllDataInIdb,
    setLoadedData,
  } = props;

  const idb = await openIndexedDBHandlerComp();
  const storedData: SettingData[] = await readAllDataInIdb(idb, "userSettings");
  if (
    !networkDataReceived &&
    storedData &&
    storedData.length > 0 &&
    storedData[0]?.user
  ) {
    setLoadedData(!!storedData[0]);
  }
};

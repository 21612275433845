import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
  Fragment,
} from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Button,
  Typography,
  Checkbox,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Card } from "../UIElements/Card";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveImportTemplate, getImportTemplate } from "../../api/importAPI";
import { useHttpClient } from "../../hooks/httpHook";
import { useAuth } from "../../hooks/authHook";
import { generateHeaderHash } from "../../utils/headerHash";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import { AlertType } from "../../types/AlertType";
import { ImportTemplateType } from "../../data/types";
import { arrayBufferToBase64 } from "../../utils/fileUtils";
import { CardStyles } from "../UIElements/CardStyles";
import { AuthContext } from "../../../shared/context/authContext";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import "./ImportMapping.scss";

interface Property {
  value: string;
  aliases: string[];
  required: boolean | ((mappedFields: string[]) => boolean);
}

interface MappingProps {
  availableProperties: Property[];
  onSubmit: (
    mapping: Record<string, string>,
    fileContent: string,
    useTemplate: boolean,
    templateName: string
  ) => Promise<boolean>;
  redirectTo?: string;
  hideUsedProperties?: boolean;
  importType: ImportTemplateType;
  title: string;
}

const useStyles = makeStyles((theme) => ({
  templateContainer: {
    marginBottom: "20px",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    minWidth: "200px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
      marginRight: "20px",
      flexShrink: 0,
    },
  },
  templateInput: {
    [theme.breakpoints.up("sm")]: {
      flexGrow: 1,
    },
  },
}));

export const ImportMapping: React.FC<MappingProps> = ({
  availableProperties,
  onSubmit,
  redirectTo = "/importacoes/lista",
  hideUsedProperties = true,
  importType,
  title,
}) => {
  const history = useHistory();
  const { sendRequest, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [headers, setHeaders] = useState<string[]>([]);
  const [mapping, setMapping] = useState<Record<string, string>>({});
  const usedProperties = useMemo(() => {
    return new Set(
      Object.values(mapping).filter(
        (value) => value && value !== "Não importar"
      )
    );
  }, [mapping]);
  const [alerts, setAlerts] = useState<Record<string, AlertType[]>>({});
  const [useTemplate, setUseTemplate] = useState(true);
  const [templateName, setTemplateName] = useState("");
  const [savedTemplate, setSavedTemplate] = useState(null);
  const location = useLocation();
  const { fileContent, fileName } = location.state as {
    fileContent: ArrayBuffer;
    fileName: string;
  };
  const [headerHash, setHeaderHash] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [itemCount, setItemCount] = useState<number>(0);
  const [dataSample, setDataSample] = useState<Record<string, string>>({});
  const classes = CardStyles();
  const templateClasses = useStyles();
  const [openAlert, setOpenAlert] = useState(false);

  const MAX_SAMPLE_ROWS = 100; // Número máximo de linhas a serem verificadas em busca de amostras de dados

  const parseFile = useCallback(() => {
    if (!fileContent) return [];

    let workbook = XLSX.read(fileContent, {
      type: "array",
      cellDates: true,
      dateNF: "dd/mm/yyyy",
    });

    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];

    const data = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      raw: false,
      dateNF: "dd/mm/yyyy",
    });

    setItemCount(data.length - 1);

    const headers = data[0] as string[];
    const sampleObject: Record<string, string> = {};

    // Buscar amostras para todas as colunas em uma única passagem
    for (
      let rowIndex = 1;
      rowIndex < Math.min(data.length, MAX_SAMPLE_ROWS);
      rowIndex++
    ) {
      const row = data[rowIndex] as any[];
      headers.forEach((header, columnIndex) => {
        if (
          !sampleObject[header] &&
          row[columnIndex] !== undefined &&
          row[columnIndex] !== ""
        ) {
          sampleObject[header] = row[columnIndex];
        }
      });

      // Se todas as colunas tiverem uma amostra, podemos parar
      if (Object.keys(sampleObject).length === headers.length) break;
    }

    // Preencher colunas vazias com string vazia
    headers.forEach((header) => {
      if (!sampleObject[header]) {
        sampleObject[header] = "";
      }
    });

    setDataSample(sampleObject);
    return headers;
  }, [fileContent]);

  const autoMapHeaders = useCallback(
    (headers: string[]) => {
      const propertyMap = new Map(
        availableProperties.flatMap((prop) =>
          prop.aliases.map((alias) => [alias.toLowerCase(), prop.value])
        )
      );

      return headers.reduce((acc, header) => {
        const headerLower = header.toLowerCase().trim();
        acc[header] = propertyMap.get(headerLower) || "Não importar";
        return acc;
      }, {} as Record<string, string>);
    },
    [availableProperties]
  );

  useEffect(() => {
    const fetchTemplateAndParseFile = async () => {
      setIsLoading(true);
      const parsedHeaders = parseFile();
      const headersToHash = [...parsedHeaders];
      setHeaders(parsedHeaders);

      const hash = await generateHeaderHash(headersToHash);
      setHeaderHash(hash);

      try {
        const template = await getImportTemplate({
          sendRequest,
          auth,
          importType,
          hash,
        });
        if (template) {
          setSavedTemplate(template);
          setTemplateName(template.name);
          setMapping(template.mapping);
          setUseTemplate(true);
        } else {
          setUseTemplate(false);
          const initialMapping = autoMapHeaders(parsedHeaders);
          setMapping(initialMapping);
        }
      } catch (error) {
        console.error("Erro ao buscar template:", error);
        setUseTemplate(false);
        setSavedTemplate(null);
        const initialMapping = autoMapHeaders(parsedHeaders);
        setMapping(initialMapping);
      }
      setIsLoading(false);
    };

    if (auth.token) {
      fetchTemplateAndParseFile();
    }
  }, [fileContent, auth.token, fileName, importType]);

  const checkMappingWarning = useCallback(
    (header: string, value: string): string => {
      if (!value || value === "Não importar") return "";

      const headerLower = header.toLowerCase().trim();
      const selectedProperty = availableProperties.find(
        (prop) => prop.value === value
      );
      const exactMatches = availableProperties.filter((prop) =>
        prop.aliases.some((alias) => alias.toLowerCase() === headerLower)
      );

      if (exactMatches.length > 1) {
        return `O cabeçalho "${header}" pode corresponder a múltiplas propriedades. Verifique o mapeamento.`;
      }

      if (
        selectedProperty &&
        !selectedProperty.aliases.some(
          (alias) => alias.toLowerCase() === headerLower
        )
      ) {
        const otherExactMatch = availableProperties.find(
          (prop) =>
            prop.value !== value &&
            prop.aliases.some((alias) => alias.toLowerCase() === headerLower)
        );

        if (otherExactMatch) {
          return `O cabeçalho "${header}" corresponde exatamente à propriedade "${otherExactMatch.value}", mas foi mapeado para "${value}".`;
        } else {
          return `O cabeçalho "${header}" pode não ser adequado para a propriedade "${value}". Verifique o mapeamento.`;
        }
      }

      return "";
    },
    [availableProperties]
  );

  const updateAlerts = useCallback(() => {
    const newAlerts: Record<string, AlertType[]> = {};
    const mappedFields = Object.values(mapping);

    headers.forEach((header) => {
      const value = mapping[header];
      const warning = checkMappingWarning(header, value);
      if (warning) {
        newAlerts[header] = [{ type: "warning", message: warning }];
      }
    });

    availableProperties.forEach((prop) => {
      const isRequired =
        typeof prop.required === "function"
          ? prop.required(mappedFields)
          : prop.required;
      if (isRequired && !mappedFields.includes(prop.value)) {
        newAlerts["unmapped"] = newAlerts["unmapped"] || [];
        newAlerts["unmapped"].push({
          type: "error",
          message: `Campo obrigatório não mapeado: ${prop.value}`,
        });

        const suggestedHeader = headers.find((header) =>
          prop.aliases.some(
            (alias) => alias.toLowerCase() === header.toLowerCase()
          )
        );

        if (
          suggestedHeader &&
          (mapping[suggestedHeader] === "Não importar" ||
            mapping[suggestedHeader] !== prop.value)
        ) {
          newAlerts[suggestedHeader] = newAlerts[suggestedHeader] || [];
          newAlerts[suggestedHeader].push({
            type: "info",
            message: `Sugestão: mapear para "${prop.value}"`,
          });
        }
      }
    });

    setAlerts(newAlerts);
  }, [mapping, availableProperties, headers, checkMappingWarning]);

  useEffect(() => {
    updateAlerts();
  }, [mapping, availableProperties, headers]);

  const headersWithAlerts = useMemo(() => {
    return Object.keys(alerts).filter((key) => key !== "unmapped");
  }, [alerts]);

  const handleMappingChange = useCallback((header: string, value: string) => {
    setMapping((prev) => {
      const newMapping = { ...prev, [header]: value };
      return newMapping;
    });
  }, []);

  const availablePropertiesForHeader = useMemo(() => {
    const baseProperties = [
      { value: "Não importar", aliases: [], isUsed: false, required: false },
      ...availableProperties,
    ];
    return (header: string) => {
      return baseProperties
        .map((prop) => ({
          ...prop,
          isUsed:
            usedProperties.has(prop.value) && mapping[header] !== prop.value,
        }))
        .filter((prop) => !hideUsedProperties || !prop.isUsed);
    };
  }, [availableProperties, usedProperties, mapping, hideUsedProperties]);

  const handleSubmit = async () => {
    setIsLoadingSubmit(true);
    const mappedColumns = availableProperties.reduce((acc, property) => {
      const mappedHeader =
        Object.entries(mapping).find(
          ([_, value]) => value === property.value
        )?.[0] || "";
      acc[property.value] = mappedHeader;
      return acc;
    }, {} as Record<string, string>);

    const statusOk = await onSubmit(
      mappedColumns,
      arrayBufferToBase64(fileContent),
      useTemplate,
      templateName
    );

    if (statusOk) {
      setOpenAlert(true);

      if (useTemplate) {
        saveImportTemplate({
          sendRequest,
          auth,
          templateName,
          mapping,
          importType,
          hash: headerHash,
        }).catch((error) => console.error("Erro ao salvar template:", error));
      }
    }

    setIsLoadingSubmit(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    history.push(redirectTo);
  };

  const handleUseTemplateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUseTemplate(event.target.checked);
    if (event.target.checked && savedTemplate) {
      setIsTemplateOverwrite(true);
    } else {
      setIsTemplateOverwrite(false);
    }
  };

  const handleTemplateNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTemplateName(event.target.value);
  };

  const handleResetMapping = () => {
    setMapping({});
    setUseTemplate(false);
    setTemplateName("");
  };

  const handleUseTemplate = () => {
    if (savedTemplate) {
      setMapping(savedTemplate.mapping);
      setUseTemplate(true);
      setTemplateName(savedTemplate.name);
    }
  };

  const checkTemplateCompatibility = () => {
    if (savedTemplate) {
      const templateHeaders = Object.keys(savedTemplate.mapping);
      const isCompatible = templateHeaders.every((header) =>
        headers.includes(header)
      );
      return isCompatible;
    }
    return false;
  };

  useEffect(() => {
    if (savedTemplate) {
      const isCompatible = checkTemplateCompatibility();
      if (isCompatible) {
        setMapping(savedTemplate.mapping);
        setUseTemplate(true);
      } else {
        setSavedTemplate(null);
        setUseTemplate(false);
      }
    }
  }, [savedTemplate, headers]);

  const [isTemplateOverwrite, setIsTemplateOverwrite] = useState(false);

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <Card cardType="mediumCard">
        <div style={{ textAlign: "center" }}>
          <Typography
            className={classes.title + " " + "import-mapping__title"}
            variant="h5"
            component="h2"
            gutterBottom
          >
            {title}
          </Typography>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              {savedTemplate && useTemplate && (
                <>
                  <Typography>
                    Template compatível encontrado: {savedTemplate.name}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleResetMapping}
                  >
                    Não usar template
                  </Button>
                </>
              )}
              {savedTemplate && !useTemplate && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleUseTemplate}
                >
                  Usar template: {savedTemplate.name}
                </Button>
              )}
            </>
          )}
        </div>
        {!isLoading && (
          <form className="simpleForm">
            <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{ backgroundColor: "#f5f5f5" }}
                    className="import-mapping__row"
                  >
                    <TableCell className="import-mapping__row--1 import-mapping__header-cell">
                      Coluna
                    </TableCell>
                    <TableCell className="import-mapping__row--2 import-mapping__header-cell hide-on-mobile">
                      Amostra de Dado
                    </TableCell>
                    <TableCell className="import-mapping__row--3 import-mapping__header-cell">
                      Propriedade
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {headers.map((header) => (
                    <TableRow key={header} className="import-mapping__row">
                      <TableCell className="import-mapping__cell">
                        <Typography style={{ fontWeight: "bold" }}>
                          {header}
                        </Typography>
                      </TableCell>
                      <TableCell className="import-mapping__cell hide-on-mobile">
                        <Typography>{dataSample[header] || ""}</Typography>
                      </TableCell>
                      <TableCell className="import-mapping__cell">
                        <Autocomplete
                          value={mapping[header] || null}
                          onChange={(_, newValue) =>
                            handleMappingChange(
                              header,
                              newValue || "Não importar"
                            )
                          }
                          options={availablePropertiesForHeader(header).map(
                            (prop) => prop.value
                          )}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                          renderOption={(option) => (
                            <Typography
                              style={{
                                fontWeight:
                                  usedProperties.has(option) &&
                                  mapping[header] !== option
                                    ? "bold"
                                    : "normal",
                                color:
                                  usedProperties.has(option) &&
                                  mapping[header] !== option
                                    ? "gray"
                                    : "inherit",
                              }}
                            >
                              {option}
                              {usedProperties.has(option) &&
                                mapping[header] !== option &&
                                " (já mapeado)"}
                            </Typography>
                          )}
                        />
                        {alerts[header]?.map((alert, index) => (
                          <Alert
                            key={index}
                            severity={alert.type}
                            style={{ marginTop: "4px" }}
                          >
                            {alert.message}
                          </Alert>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={templateClasses.templateContainer}>
              <div className={templateClasses.checkboxContainer}>
                <Checkbox
                  checked={useTemplate}
                  onChange={handleUseTemplateChange}
                  color="primary"
                />
                <Typography noWrap>Salvar como template</Typography>
              </div>
              {useTemplate && (
                <TextField
                  className={templateClasses.templateInput}
                  value={templateName}
                  onChange={handleTemplateNameChange}
                  placeholder="Nome do template"
                  fullWidth
                />
              )}
            </div>
            {isTemplateOverwrite && (
              <Typography color="error" style={{ marginBottom: "20px" }}>
                Atenção: Este novo template irá sobrescrever o template
                existente.
              </Typography>
            )}
            {alerts["unmapped"] && (
              <Alert severity="error" style={{ marginBottom: "20px" }}>
                {alerts["unmapped"].map((alert) => alert.message).join(", ")}
              </Alert>
            )}
            {headersWithAlerts.length > 0 && (
              <Alert severity="warning" style={{ marginBottom: "20px" }}>
                Por favor, verifique as configurações de importação para os
                seguintes campos:
                {" " + headersWithAlerts.join(", ")}
              </Alert>
            )}
            {!isLoadingSubmit && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
                disabled={!!alerts["unmapped"]}
              >
                Importar {itemCount} registros
              </Button>
            )}
            {isLoadingSubmit && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            )}
          </form>
        )}
        <Dialog
          open={openAlert}
          onClose={handleCloseAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ color: "#189ab4" }} id="alert-dialog-title">
            {"Importação Iniciada"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ color: "#444444" }}
              id="alert-dialog-description"
            >
              A importação foi salva e será finalizada em instantes.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAlert} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Fragment>
  );
};

import Popover from "@material-ui/core/Popover";
import React, { Fragment, useContext } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";

import { ModalError } from "../../shared/components/UIElements/ModalError";
import { InputSelectWithId } from "../../shared/components/FormElements/InputSelectWithId";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { useForm } from "../../shared/hooks/formHook";
import { SettingsContext } from "../../shared/context/settingsContext";
import { chooseFinProposal } from "../api/financingsAPI";
import { addPreApprovedFinToProposal } from "../../sales/api/financingsAPI";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { ButtonCyanContained } from "../../shared/components/MUIThemed/ButtonCyanContained";
import { AuthContextProps, FinancingData } from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface FormAddFinToProposalProps {
  auth: AuthContextProps;
  financing: FinancingData;
  setLoadedData: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  isLoading: boolean;
  uniqueFinProps: FinancingData["proposals"];
  continueHandler?: () => void;
  isPopover?: boolean;
}

export const FormAddFinToProposal = (props: FormAddFinToProposalProps) => {
  const {
    auth,
    financing,
    sendRequest,
    setLoadedData,
    isLoading,
    uniqueFinProps,
    isPopover = false,
    continueHandler,
  } = props;
  const classes = PopoverFormStyles();
  const settingsCtx = useContext(SettingsContext);
  const { formState, inputHandler, reportInvalid } = useForm({}, false);
  const history = useHistory();

  const initOpts =
    uniqueFinProps?.length > 0
      ? uniqueFinProps
          ?.sort((a, b) => {
            if (a?.gracePeriod === 3 && b?.gracePeriod !== 3) {
              return -1; // Put 'a' before 'b'
            } else if (a?.gracePeriod !== 3 && b?.gracePeriod === 3) {
              return 1; // Put 'b' before 'a'
            } else if (a?.gracePeriod !== 3 && b?.gracePeriod !== 3) {
              return b?.gracePeriod - a?.gracePeriod; // Sort descending if neither 'a' nor 'b' is 3
            } else {
              return a?.gracePeriod - b?.gracePeriod; // Keep '3' sorted normally
            }
          })
          ?.sort((a, b) => b?.installmentValue - b?.installmentValue)
      : [];

  const inPropOpts = initOpts?.filter((p) => p.addedToProp) || [];

  const options = uniqueFinProps?.map((p, i) => {
    return {
      txt: `${addThousandSeparator(p?.fee * 100)}% - x${
        p?.installments
      } de R$ ${addThousandSeparator(p?.installmentValue)} - ${
        p?.gracePeriod
      } ${p?.gracePeriod === 1 ? "Mês" : "Meses"}`,
      id: p?._id,
    };
  });

  const selOpts = [
    formState?.inputs?.option1?.value?.toString(),
    formState?.inputs?.option2?.value?.toString(),
    formState?.inputs?.option3?.value?.toString(),
    formState?.inputs?.option4?.value?.toString(),
  ];

  const hasSelOpt = !!selOpts?.find((o) => !!o);

  const addFinToPropHandler = () => {
    addPreApprovedFinToProposal({
      sendRequest,
      auth,
      fid: financing?._id,
      options: selOpts?.filter((o) => !!o),
      setLoadedFinancings: setLoadedData,
    }).then(() => {
      if (
        !!settingsCtx?.proposalSlug &&
        typeof financing?.proposal?.count === "number" &&
        financing?.proposal?.count > 0 &&
        !continueHandler
      ) {
        history.push(
          `/proposta/final/${settingsCtx.proposalSlug}/${
            financing?.proposal?.count
          }/${financing?.proposal?.frontId?.substring(0, 5)}`
        );
      } else if (!continueHandler) {
        history.push(`/proposta/final/${financing?.proposal?.frontId}`);
      } else {
        continueHandler();
      }
    });
  };

  return (
    <Fragment>
      <form
        style={{ zIndex: 502 }}
        className={!isPopover ? classes.formRoot2 : classes.formRoot}
      >
        <InputSelectWithId
          id="option1"
          label="Opção 1"
          variant="outlined"
          options={options}
          disabled={!options?.length}
          onInput={inputHandler}
          initialValue={inPropOpts?.[0]?._id || initOpts?.[0]?._id || ""}
        />
        <InputSelectWithId
          id="option2"
          label="Opção 2"
          variant="outlined"
          options={options}
          disabled={!options?.length}
          onInput={inputHandler}
          initialValue={inPropOpts?.[1]?._id || initOpts?.[1]?._id || ""}
        />
        <InputSelectWithId
          id="option3"
          label="Opção 3"
          variant="outlined"
          options={options}
          disabled={!options?.length}
          onInput={inputHandler}
          initialValue={inPropOpts?.[2]?._id || initOpts?.[2]?._id || ""}
        />
        <InputSelectWithId
          id="option4"
          label="Opção 4"
          variant="outlined"
          options={options}
          disabled={!options?.length}
          onInput={inputHandler}
          initialValue={inPropOpts?.[3]?._id || initOpts?.[3]?._id || ""}
        />
        {!isLoading && (
          <ButtonCyanContained
            variant="contained"
            color="primary"
            disabled={!hasSelOpt}
            onClick={addFinToPropHandler}
          >
            ADICIONAR À PROPOSTA
          </ButtonCyanContained>
        )}
        {isLoading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "1.5rem",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        )}
      </form>
    </Fragment>
  );
};

import React, { useContext, Fragment } from "react";
import { ImportMapping } from "../../shared/components/ImportMapping/ImportMapping";
import { uploadModules } from "../api/modulesAPI";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { AuthContext } from "../../shared/context/authContext";
import { ModalError } from "../../shared/components/UIElements/ModalError";

const availableProperties = [
  {
    value: "modulo_modelo",
    aliases: ["modulo_modelo", "modelo", "name"],
    required: true,
  },
  {
    value: "modulo_fabricante",
    aliases: ["modulo_fabricante", "fabricante", "manufacturer"],
    required: true,
  },
  {
    value: "modulo_potencia_em_watts",
    aliases: ["modulo_potencia_em_watts", "potencia", "power"],
    required: true,
  },
  {
    value: "modulo_garantia_defeito",
    aliases: ["modulo_garantia_defeito", "garantia_defeito", "defect_warranty"],
    required: false,
  },
  {
    value: "modulo_garantia_eficiencia",
    aliases: [
      "modulo_garantia_eficiencia",
      "garantia_eficiencia",
      "efficiency_warranty",
    ],
    required: false,
  },
  {
    value: "modulo_peso_em_kg",
    aliases: ["modulo_peso_em_kg", "peso", "weight"],
    required: false,
  },
  {
    value: "modulo_comprimento_em_milimetros",
    aliases: ["modulo_comprimento_em_milimetros", "comprimento", "length"],
    required: false,
  },
  {
    value: "modulo_largura_em_milimetros",
    aliases: ["modulo_largura_em_milimetros", "largura", "width"],
    required: false,
  },
  {
    value: "modulo_estoque",
    aliases: ["modulo_estoque", "estoque", "stock"],
    required: false,
  },
  {
    value: "modulo_valor_unitario",
    aliases: ["modulo_valor_unitario", "valor", "price"],
    required: false,
  },
];

export const ModuleImportMapping: React.FC = () => {
  const { sendRequest, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const handleSubmit = async (
    mapping: Record<string, string>,
    fileContent: string
  ) => {
    try {
      await uploadModules({ sendRequest, auth, mapping, fileContent });
      return true;
    } catch (error) {
      console.error("Erro ao fazer upload:", error);
      return false;
    }
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ImportMapping
        availableProperties={availableProperties}
        onSubmit={handleSubmit}
        importType="module"
        title="Importação - Mapeamento de Módulos"
      />
    </Fragment>
  );
};

import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Collapse from "@material-ui/core/Collapse";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";

import { MenuSaleContractOptions } from "./MenuSaleContractOptions";
import { SaleDashSignature } from "./SaleDashSignature";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { AuthContextProps, KanbanFilledData } from "../../shared/data/types";

import "./SaleDashContractItem.scss";

interface SaleDashContractItemProps {
  auth: AuthContextProps;
  saleId: string;
  loadedContract: KanbanFilledData["items"][0]["contracts"][0];
  loadedSale: KanbanFilledData["items"][0];
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const SaleDashContractItem = (props: SaleDashContractItemProps) => {
  const { loadedContract, auth, saleId, setKanbanData, loadedSale } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);

  return (
    <div>
      <MenuSaleContractOptions
        auth={auth}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        sendRequest={sendRequest}
        saleId={saleId}
        loadedContract={loadedContract}
        setKanbanData={setKanbanData}
      />
      <div className="sale-prop-contract-item" onClick={(e) => {}}>
        {isLoading && <LoadingSpinnerOverlayRegular image />}
        <div className="sale-prop-contract-item__data">
          <FontAwesomeIcon
            title="Assinatura Digital"
            onClick={(e) => {
              setOpen(!open);
              e.stopPropagation();
            }}
            className="sale-prop-contract-item__data-sign"
            icon={faFileSignature}
            style={{ fontSize: "26px", padding: "4px" }}
          />
          <p className="sale-prop-contract-item__data-item sale-prop-contract-item__data-item--thin">
            {!!loadedContract?.number
              ? `#${loadedContract?.number?.toLocaleString("pt-BR", {
                  minimumIntegerDigits: 5,
                  useGrouping: false,
                })} - `
              : ""}
            {loadedContract.name}
          </p>
        </div>
        <div className="sale-prop-contract-item__controls">
          <IconButton
            size="small"
            title={open ? "Ver Mais" : "Fechar"}
            className={`table__item-action${open ? "--open" : ""}`}
            onClick={(e) => {
              if (!anchorEl) {
                setOpen(!open);
                e.stopPropagation();
              }
            }}
          >
            <ExpandMoreRoundedIcon style={{ fontSize: "20px" }} />
          </IconButton>
          <IconButton
            size="small"
            title="Opções"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              e.stopPropagation();
            }}
          >
            <MoreHorizRoundedIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </div>
        <div className="sale-prop-contract-item__details">
          <Collapse in={open}>
            <div className="sale-prop-contract-item__details-content">
              <p className="sale-prop-contract-item__data-item sale-prop-contract-item__data-item--thin">
                Modificado em: <span>{loadedContract.lastModified}</span>
              </p>
              <p className="sale-prop-contract-item__data-item sale-prop-contract-item__data-item--thin">
                Registro: <span>{loadedContract?.registryDate || ""}</span>
              </p>
            </div>
            <div className="sale-prop-contract-item__details-content">
              <SaleDashSignature
                auth={auth}
                saleId={saleId}
                loadedContract={loadedContract}
                loadedSale={loadedSale}
              />
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

import { makeStyles } from "@material-ui/core/styles";

export const ModalConfirmStyles = makeStyles(() => ({
  confirmModalTitle: {
    color: "#f50057",
  },
  confirmModalTitlePos: {
    color: "#08a9c9",
  },
  confirmModalContent: {
    color: "#424242",
  },
}));

import { makeStyles } from "@material-ui/core/styles";

export const UserFormsStyles = makeStyles((theme) => ({
  title: {
    color: "#08a9c9",
  },
  title2: {
    color: "#043a50",
    // textTransform: "capitalize",
    fontSize: "1.2rem",
    // textAlign: "left",
    // width: "92%",
  },
  subtitle: {
    color: "#08a9c9",
    textAlign: "center",
    textDecoration: "underline",
    fontSize: "0.9rem",
    "&:hover": {
      color: "#15c2e4",
    },
    "&:focus": {
      color: "#15c2e4",
    },
  },
  root: {
    margin: "0",
    padding: "0",
    display: "flex",
    justifyContent: "center",
    "& form": {
      width: "1100px",
      minHeight: "300px",
      padding: "1rem 0",
      border: "1px solid rgba(61, 116, 255, 0.35)",
      borderRadius: "15px",
      boxShadow: "0px 0px 9px -1px rgba(61,116,255,0.3)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      [theme.breakpoints.down("lg")]: {
        width: "920px",
      },
      [theme.breakpoints.down("md")]: {
        width: "630px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "500px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "310px",
      },
      "& > *": {
        margin: theme.spacing(1),
        width: "300px",
        [theme.breakpoints.down("xs")]: {
          width: "280px",
        },
      },
    },
  },
  tagsList: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    padding: "0 2rem",
    gridGap: "15px 25px",
    marginTop: "1rem",
    fontSize: "0.85rem",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

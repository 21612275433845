import { useState, useCallback, useEffect } from "react";
import {
  UserData,
  UserStatus,
  SignupPlans,
  UserDataProps,
  AzumeModules,
  AzumeWhitelabels,
} from "../data/types";

let logoutTimer = null;

export const useAuth = () => {
  const [token, setToken] = useState<string>(null);
  const [type, setType] = useState<string>(null);
  const [tokenExpirationDate, setTokenExpirationDate] = useState<Date>(null);
  const [userId, setUserId] = useState<string>(null);
  const [personalData, setPersonalData] =
    useState<UserData["personalData"]>(null);
  const [email, setEmail] = useState<string>(null);
  const [managerId, setManagerId] = useState<string>(null);
  const [managerUserId, setManagerUserId] = useState<string>(null);
  const [vendors, setVendors] = useState<UserDataProps["vendors"]>(null);
  const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);
  const [statusOk, setstatusOk] = useState<boolean>(false);
  const [status, setstatus] = useState<UserStatus>(null);
  const [signupPlan, setSignupPlan] = useState<SignupPlans>(null);
  const [activeModules, setActiveModules] = useState<AzumeModules[]>([]);
  const [whitelabel, setWhitelabel] = useState<AzumeWhitelabels>("");

  const login = useCallback(
    (
      userId: string,
      email: string,
      personalData: UserData["personalData"],
      managerId: string,
      managerUserId: string,
      vendors: UserDataProps["vendors"],
      uToken: string,
      uType: string,
      uIsAdmin: boolean,
      uStatusOk: boolean,
      uStatus: UserStatus,
      signupPlan: SignupPlans,
      activeModules: AzumeModules[],
      whitelabel: AzumeWhitelabels,
      expirationDate: Date
    ) => {
      setToken(uToken);
      setType(uType);
      setUserId(userId);
      setPersonalData(personalData);
      setEmail(email);
      setManagerId(managerId);
      setManagerUserId(managerUserId);
      setVendors(vendors);
      setUserIsAdmin(uIsAdmin);
      setstatusOk(uStatusOk);
      setstatus(uStatus);
      setSignupPlan(signupPlan);
      setActiveModules(activeModules);
      setWhitelabel(whitelabel);
      const uTokenExpirationDate =
        expirationDate ||
        new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7);

      setTokenExpirationDate(uTokenExpirationDate);
      localStorage.setItem(
        // Local storage of user id and token
        "userData",
        JSON.stringify({
          userId: userId,
          email: email,
          personalData: personalData,
          managerId: managerId,
          managerUserId: managerUserId,
          vendors: vendors,
          uToken: uToken,
          uType: uType,
          isAdmin: uIsAdmin,
          statusOk: uStatusOk,
          status: uStatus,
          signupPlan: signupPlan,
          activeModules: activeModules,
          whitelabel: whitelabel,
          expiration: uTokenExpirationDate.toISOString(),
        })
      );
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    setManagerId(null);
    setManagerUserId(null);
    setVendors(null);
    setType(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setEmail(null);
    setPersonalData(null);
    setUserIsAdmin(false);
    setstatusOk(false);
    setstatus(null);
    setSignupPlan(null);
    setActiveModules([]);
    setWhitelabel("");
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const hasRemainingTime =
        tokenExpirationDate.getTime() > new Date().getTime();

      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();

      if (!hasRemainingTime) {
        logout();
      } else {
        logoutTimer = setTimeout(logout, remainingTime);
      }
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    // Loading stored user id and token
    const storedData: UserDataProps = JSON.parse(
      localStorage.getItem("userData")
    );
    if (
      storedData &&
      storedData.uToken &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.email || "",
        storedData.personalData,
        storedData.managerId,
        storedData.managerUserId,
        storedData.vendors,
        storedData.uToken,
        storedData.uType,
        storedData.isAdmin,
        storedData.statusOk,
        storedData.status,
        storedData.signupPlan,
        storedData.activeModules || [],
        storedData.whitelabel || "",
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  return {
    token,
    type,
    userId,
    email,
    personalData,
    managerId,
    managerUserId,
    vendors,
    userIsAdmin,
    statusOk,
    status,
    signupPlan,
    activeModules,
    whitelabel,
    login,
    logout,
  };
};

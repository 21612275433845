import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import PlaylistAddCheckRoundedIcon from "@material-ui/icons/PlaylistAddCheckRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import PostAddRoundedIcon from "@material-ui/icons/PostAddRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";
import InsertDriveFileRoundedIcon from "@material-ui/icons/InsertDriveFileRounded";

import { FinancingData, ArchiveData } from "../../shared/data/types";

export const getFinancingCta = (financing: FinancingData) => {
  let cta = {
    txt: "CONTRATAR",
    icon: <MonetizationOnRoundedIcon />,
  };

  if (!!financing) {
    const isCNPJ = financing?.isCNPJ || false;
    const associates = financing?.customer?.associates || 1;
    const addedToPropOpts =
      financing.proposals?.filter((p) => p?.addedToProp)?.length || 0;
    const hasChosenProp = !!financing.proposals?.find((p) => p?.chosen);
    const customerArchive = financing.customer?.archive as ArchiveData;
    // const uploadedAllDocs =
    //   customerArchive?.files?.filter(
    //     (f) =>
    //       f?.semantic === "CNH" ||
    //       f?.semantic === "Comprovante de Renda" ||
    //       f?.semantic === "Conta de Energia" ||
    //       f?.semantic === "RG"
    //   )?.length === 4;
    const approvedAllDocs = !isCNPJ
      ? customerArchive?.files?.filter(
          (f) =>
            (f?.semantic === "CNH" && f?.status === "APPROVED") ||
            (f?.semantic === "Comprovante de Renda" &&
              f?.status === "APPROVED") ||
            (f?.semantic === "Conta de Energia" && f?.status === "APPROVED") ||
            (f?.semantic === "RG" && f?.status === "APPROVED")
        )?.length >= 4
      : customerArchive?.files?.filter(
          (f) =>
            (f?.semantic === "DRE" ||
              f?.semantic === "Balanço" ||
              f?.semantic === "Conta de Energia" ||
              f?.semantic?.includes("RG Sócio") ||
              f?.semantic?.includes("CNH Sócio") ||
              f?.semantic?.includes("IR Sócio")) &&
            f?.status === "APPROVED"
        )?.length ===
        3 + 3 * associates;
    const hasRejectedDocs = !isCNPJ
      ? customerArchive?.files?.filter(
          (f) =>
            (f?.semantic === "CNH" && f?.status === "REJECTED") ||
            (f?.semantic === "Comprovante de Renda" &&
              f?.status === "REJECTED") ||
            (f?.semantic === "Conta de Energia" && f?.status === "REJECTED") ||
            (f?.semantic === "RG" && f?.status === "REJECTED")
        )?.length > 0
      : customerArchive?.files?.filter(
          (f) =>
            (f?.semantic === "DRE" ||
              f?.semantic === "Balanço" ||
              f?.semantic === "Conta de Energia" ||
              f?.semantic?.includes("RG Sócio") ||
              f?.semantic?.includes("CNH Sócio") ||
              f?.semantic?.includes("IR Sócio")) &&
            f?.status === "REJECTED"
        )?.length > 0;
    // const uploadedAllNFs =
    //   customerArchive?.files?.filter(
    //     (f) =>
    //       f?.semantic === "NFe de Serviço" ||
    //       f?.semantic === "NFe dos Equipamentos"
    //   )?.length === 4;
    const approvedAllNFs =
      customerArchive?.files?.filter(
        (f) =>
          (f?.semantic === "NFe de Serviço" && f?.status === "APPROVED") ||
          (f?.semantic === "NFe dos Equipamentos" && f?.status === "APPROVED")
      )?.length >= 2;
    const hasRejectedNFs =
      customerArchive?.files?.filter(
        (f) =>
          (f?.semantic === "NFe de Serviço" && f?.status === "REJECTED") ||
          (f?.semantic === "NFe dos Equipamentos" && f?.status === "REJECTED")
      )?.length > 0;
    // const uploadedAllSvcDocs =
    //   customerArchive?.files?.filter(
    //     (f) =>
    //       f?.semantic === "ART de Execução" ||
    //       f?.semantic === "Parecer de Acesso" ||
    //       f?.semantic === "Foto dos Módulos" ||
    //       f?.semantic === "Foto dos Inversores" ||
    //       f?.semantic === "Foto do Medidor"
    //   )?.length === 5;
    const approvedAllSvcDocs =
      customerArchive?.files?.filter(
        (f) =>
          (f?.semantic === "ART de Execução" && f?.status === "APPROVED") ||
          (f?.semantic === "Parecer de Acesso" && f?.status === "APPROVED") ||
          (f?.semantic === "Foto dos Módulos" && f?.status === "APPROVED") ||
          (f?.semantic === "Foto dos Inversores" && f?.status === "APPROVED") ||
          (f?.semantic === "Foto do Medidor" && f?.status === "APPROVED")
      )?.length >= 5 &&
      financing.proposal?.monitoringLink?.status === "APPROVED";
    const hasRejectedSvcDocs =
      customerArchive?.files?.filter(
        (f) =>
          (f?.semantic === "ART de Execução" && f?.status === "REJECTED") ||
          (f?.semantic === "Parecer de Acesso" && f?.status === "REJECTED") ||
          (f?.semantic === "Foto dos Módulos" && f?.status === "REJECTED") ||
          (f?.semantic === "Foto dos Inversores" && f?.status === "REJECTED") ||
          (f?.semantic === "Foto do Medidor" && f?.status === "REJECTED")
      )?.length > 0 ||
      financing.proposal?.monitoringLink?.status === "REJECTED";

    if (financing?.stage === "DEAL") {
      if (!addedToPropOpts && !hasChosenProp) {
        cta = {
          txt: "ADD À PROPOSTA",
          icon: <PostAddRoundedIcon />,
        };
      } else if (!hasChosenProp) {
        cta = {
          txt: "SELECIONAR OPÇÃO",
          icon: <PlaylistAddCheckRoundedIcon />,
        };
      } else if (hasChosenProp && !approvedAllDocs && !hasRejectedDocs) {
        cta = {
          txt: "ENVIAR DOCUMENTOS",
          icon: <PublishRoundedIcon />,
        };
      } else if (hasRejectedDocs) {
        cta = {
          txt: "AJUSTAR DOCUMENTOS",
          icon: <PublishRoundedIcon />,
        };
      } else if (approvedAllDocs && !approvedAllNFs && !hasRejectedNFs) {
        cta = {
          txt: "ENVIAR NOTAS",
          icon: <PublishRoundedIcon />,
        };
      } else if (approvedAllDocs && hasRejectedNFs) {
        cta = {
          txt: "AJUSTAR NOTAS",
          icon: <PublishRoundedIcon />,
        };
      } else if (
        approvedAllNFs &&
        financing.contractSigned &&
        !approvedAllSvcDocs &&
        !hasRejectedSvcDocs
      ) {
        cta = {
          txt: "ENVIAR COMPROVANTES",
          icon: <PublishRoundedIcon />,
        };
      } else if (
        approvedAllNFs &&
        financing.contractSigned &&
        hasRejectedSvcDocs
      ) {
        cta = {
          txt: "AJUSTAR COMPROVANTES",
          icon: <PublishRoundedIcon />,
        };
      } else if (financing.serviceApproved) {
        cta = {
          txt: "CONCLUÍDO",
          icon: <DoneOutlineRoundedIcon />,
        };
      } else {
        cta = {
          txt: "VER DOCUMENTOS",
          icon: <InsertDriveFileRoundedIcon />,
        };
      }
    } else {
      cta = {
        txt: "CONTRATAR",
        icon: <MonetizationOnRoundedIcon />,
      };
    }
  }

  return cta;
};

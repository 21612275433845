import { PricingComponentType } from "../../shared/data/types";

interface CalcKitPriceForVendorGrossMarginProps {
  eqpPriceTotalNoMarkup: number;
  serviceCosts: {
    name: string;
    value: number;
    isRevenue?: boolean;
  }[];
  costPercentages: {
    name: string;
    value: number;
    type: PricingComponentType;
    isRevenue?: boolean;
  }[];
}

export const calcKitPriceForVendorGrossMargin = (
  props: CalcKitPriceForVendorGrossMarginProps
) => {
  const { eqpPriceTotalNoMarkup, serviceCosts, costPercentages } = props;

  // MONETARY VALUES /////////////////////////////////////
  ////////////////////////////////////////////////////////
  const svcPriceTotalNoMarkup =
    serviceCosts.length > 0
      ? serviceCosts
          ?.map((comp) => {
            return Math.round(comp?.value) || 0;
          })
          ?.reduce((a, b) => {
            return a + b;
          })
      : 0;

  // EQP PERCS ///////////////////////////////////////////
  ////////////////////////////////////////////////////////
  const eqpPerc = costPercentages.filter((comp) => {
    return comp.type === "PERCENTAGE_KIT";
  });

  const eqpPercTotal =
    eqpPerc.length > 0
      ? eqpPerc
          ?.map((comp) => {
            return Math.round(comp?.value) || 0;
          })
          ?.reduce((a, b) => {
            return a + b;
          })
      : 0;

  const eqpMarkup = 1 + eqpPercTotal / 100 || 0;
  const eqpPriceTotalWithMarkup = eqpPriceTotalNoMarkup * eqpMarkup || 0;

  // console.log(costPercentages);
  // console.log("[EQP PRICE NO MARKUP]: ", eqpPriceTotalNoMarkup);
  // console.log("[EQP PERC TOTAL]: ", eqpPercTotal);
  // console.log("[EQP MARKUP]: ", eqpMarkup);
  // console.log("[EQP PRICE WITH MARKUP]: ", eqpPriceTotalWithMarkup);

  // SVC PERCS ///////////////////////////////////////////
  ////////////////////////////////////////////////////////
  const svcPerc = costPercentages.filter((comp) => {
    return comp.type === "PERCENTAGE_SERVICE";
  });

  const svcPercTotal =
    svcPerc.length > 0
      ? svcPerc
          ?.map((comp) => {
            return Math.round(comp?.value) || 0;
          })
          ?.reduce((a, b) => {
            return a + b;
          })
      : 0;

  const svcMarkup = 1 + svcPercTotal / 100 || 1;
  const svcPriceTotalWithMarkup = svcPriceTotalNoMarkup * svcMarkup || 0;

  // console.log("[SVC PRICE NO MARKUP]: ", svcPriceTotalNoMarkup);
  // console.log("[SVC PERC TOTAL]: ", svcPercTotal);
  // console.log("[SVC MARKUP]: ", svcMarkup);
  // console.log("[SVC PRICE WITH MARKUP]: ", svcPriceTotalWithMarkup);

  // TOTAL PERCS /////////////////////////////////////////
  ////////////////////////////////////////////////////////
  const totalPerc = costPercentages.filter((comp) => {
    return (
      comp.type === "PERCENTAGE_SERVICE_AND_KIT" ||
      comp.type === "COMMISSION" ||
      comp.type === "INDIVIDUAL_COMMISSION"
    );
  });

  const totalPercTotal =
    totalPerc.length > 0
      ? totalPerc
          ?.map((comp) => {
            return Math.round(comp?.value) || 0;
          })
          ?.reduce((a, b) => {
            return a + b;
          })
      : 0;

  const totalNoMarkup = eqpPriceTotalWithMarkup + svcPriceTotalWithMarkup || 0;
  const totalMarkup = 1 + totalPercTotal / 100 || 1;
  let totalWithMarkup = totalNoMarkup * totalMarkup || 0;

  const maxMargin =
    costPercentages.filter((comp) => {
      return comp.type === "TRADING_MARGIN";
    })?.[0]?.value || 0;

  if (!!maxMargin) {
    totalWithMarkup = totalWithMarkup * (1 + maxMargin / 100);
  }

  // console.log("[totalNoMarkup]: ", totalNoMarkup);
  // console.log("[markupPercsTotal]: ", markupPercsTotal);
  // console.log("[percsTotalRev]: ", percsTotalRev);
  // console.log("[markupTotal]: ", totalMarkup);
  // console.log("[totalWithMarkup]: ", totalWithMarkup);

  // console.log("[TOTAL PRICE NO MARKUP]: ", totalNoMarkup);
  // console.log("[TOTAL PERC TOTAL]: ", totalPercTotal);
  // console.log("[TOTAL MARKUP]: ", totalMarkup);
  // console.log("[TOTAL PRICE WITH MARKUP]: ", totalWithMarkup);

  return totalWithMarkup;
};

import React, { Fragment, useContext } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useHistory } from "react-router-dom";

import { ButtonTealContained } from "../../shared/components/MUIThemed/ButtonTealContained";
import { FileUploadSemantic } from "../../shared/components/FormElements/FileUploadSemantic";
import { InputWithSubmitAndStatus } from "../../shared/components/FormElements/InputWithSubmitAndStatus";
import { useForm } from "../../shared/hooks/formHook";
import { openWhatsapp } from "../../shared/util/openWhatsapp";
import { SettingsContext } from "../../shared/context/settingsContext";
import { editMonitoringLink } from "../api/proposalsAPI";
import { VALIDATOR_URI } from "../../shared/util/validators";
import {
  ArchiveData,
  AuthContextProps,
  FinancingData,
} from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface FormUploadSvcDocsProps {
  auth: AuthContextProps;
  financing: FinancingData;
  setLoadedData: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  isLoading: boolean;
  uniqueFinProps: FinancingData["proposals"];
  setTab: React.Dispatch<React.SetStateAction<number>>;
  isSmallWidth: boolean;
}

export const FormUploadSvcDocs = (props: FormUploadSvcDocsProps) => {
  const {
    auth,
    financing,
    sendRequest,
    setLoadedData,
    isLoading,
    uniqueFinProps,
    setTab,
    isSmallWidth,
  } = props;
  const classes = PopoverFormStyles();
  const settingsCtx = useContext(SettingsContext);
  const { formState, inputHandler, reportInvalid } = useForm({}, false);
  const history = useHistory();

  const customerArchive = financing.customer?.archive as ArchiveData;

  const art = customerArchive?.files?.find(
    (f) => f.semantic === "ART de Execução"
  );
  const rightOfAcess = customerArchive?.files?.find(
    (f) => f.semantic === "Parecer de Acesso"
  );
  const modulesPhoto = customerArchive?.files?.find(
    (f) => f.semantic === "Foto dos Módulos"
  );
  const invertersPhoto = customerArchive?.files?.find(
    (f) => f.semantic === "Foto dos Inversores"
  );
  const meterPhoto = customerArchive?.files?.find(
    (f) => f.semantic === "Foto do Medidor"
  );

  const openWhatsappHandler = () => {
    openWhatsapp(
      "31986281765",
      "Quero contratar um financiamento pré-aprovado."
    );
  };

  const editMonitoringLinkHandler = () => {
    editMonitoringLink({
      sendRequest,
      auth,
      setLoadedFinancings: setLoadedData,
      pid: financing?.proposal?._id,
      formState,
    });
  };

  return (
    <Fragment>
      <form style={{ zIndex: 502 }} className={classes.formRoot3}>
        <InputWithSubmitAndStatus
          id="monitoringLink"
          label="Link de Monitoramento"
          validators={[VALIDATOR_URI()]}
          helperText="Insira o link de monitoramento do sistema."
          onInput={inputHandler}
          labelWidth={180}
          initialValue={financing?.proposal?.monitoringLink?.url || ""}
          initStatus={financing?.proposal?.monitoringLink?.status || ""}
          initialValid={!!financing?.proposal?.monitoringLink?.url}
          onClick={editMonitoringLinkHandler}
          isLoading={isLoading}
          useStatus
        />
        <div className="u-margin-top-28">
          <FileUploadSemantic
            fid={financing?._id}
            setLoadedFinancings={setLoadedData}
            cid={financing?.customer?._id}
            initFile={art}
            initialFileName={art?.name || art?.semantic}
            label="ART de Execução"
            fileSemantic="ART de Execução"
            folderSemantic="Financiamento"
            helperText="ART"
            isSmallWidth={isSmallWidth}
            useStatus
          />
        </div>
        <FileUploadSemantic
          fid={financing?._id}
          setLoadedFinancings={setLoadedData}
          cid={financing?.customer?._id}
          initFile={rightOfAcess}
          initialFileName={rightOfAcess?.name || rightOfAcess?.semantic}
          label="Parecer de Acesso"
          fileSemantic="Parecer de Acesso"
          folderSemantic="Financiamento"
          helperText="Parecer de Acesso"
          isSmallWidth={isSmallWidth}
          useStatus
        />
        <FileUploadSemantic
          fid={financing?._id}
          setLoadedFinancings={setLoadedData}
          cid={financing?.customer?._id}
          initFile={modulesPhoto}
          initialFileName={modulesPhoto?.name || modulesPhoto?.semantic}
          label="Foto dos Módulos"
          fileSemantic="Foto dos Módulos"
          folderSemantic="Financiamento"
          helperText="Foto Nítida dos Módulos"
          isSmallWidth={isSmallWidth}
          useStatus
        />
        <FileUploadSemantic
          fid={financing?._id}
          setLoadedFinancings={setLoadedData}
          cid={financing?.customer?._id}
          initFile={invertersPhoto}
          initialFileName={invertersPhoto?.name || invertersPhoto?.semantic}
          label="Foto dos Inversores"
          fileSemantic="Foto dos Inversores"
          folderSemantic="Financiamento"
          helperText="Foto Nítida dos Inversores"
          isSmallWidth={isSmallWidth}
          useStatus
        />
        <FileUploadSemantic
          fid={financing?._id}
          setLoadedFinancings={setLoadedData}
          cid={financing?.customer?._id}
          initFile={meterPhoto}
          initialFileName={meterPhoto?.name || meterPhoto?.semantic}
          label="Foto do Medidor"
          fileSemantic="Foto do Medidor"
          folderSemantic="Financiamento"
          helperText="Foto do Medidor Bidirecional"
          isSmallWidth={isSmallWidth}
          useStatus
        />
        {!isLoading && (
          <ButtonTealContained
            variant="contained"
            color="primary"
            onClick={openWhatsappHandler}
          >
            <WhatsAppIcon />
            &nbsp; CHAMAR CONSULTOR
          </ButtonTealContained>
        )}
        {isLoading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "1.5rem",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        )}
      </form>
    </Fragment>
  );
};

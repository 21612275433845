import { FinancingData, ArchiveData } from "../../shared/data/types";
import { BTG_STATUS_MAP } from "../../shared/data/static";

export const getFinancingStage = (financing: FinancingData) => {
  const isCNPJ = financing?.isCNPJ || false;
  const associates = financing?.customer?.associates || 1;
  const addedToPropOpts =
    financing.proposals?.filter((p) => p?.addedToProp)?.length || 0;
  const hasChosenProp = !!financing.proposals?.find((p) => p?.chosen);
  const customerArchive = financing.customer?.archive as ArchiveData;
  const uploadedAllDocs = !isCNPJ
    ? customerArchive?.files?.filter(
        (f) =>
          f?.semantic === "CNH" ||
          f?.semantic === "Comprovante de Renda" ||
          f?.semantic === "Conta de Energia" ||
          f?.semantic === "RG"
      )?.length >= 4
    : customerArchive?.files?.filter(
        (f) =>
          f?.semantic === "DRE" ||
          f?.semantic === "Balanço" ||
          f?.semantic === "Conta de Energia" ||
          f?.semantic?.includes("RG Sócio") ||
          f?.semantic?.includes("CNH Sócio") ||
          f?.semantic?.includes("IR Sócio")
      )?.length >=
      3 + 3 * associates;
  const approvedAllDocs = !isCNPJ
    ? customerArchive?.files?.filter(
        (f) =>
          (f?.semantic === "CNH" && f?.status === "APPROVED") ||
          (f?.semantic === "Comprovante de Renda" &&
            f?.status === "APPROVED") ||
          (f?.semantic === "Conta de Energia" && f?.status === "APPROVED") ||
          (f?.semantic === "RG" && f?.status === "APPROVED")
      )?.length >= 4
    : customerArchive?.files?.filter(
        (f) =>
          (f?.semantic === "DRE" ||
            f?.semantic === "Balanço" ||
            f?.semantic === "Conta de Energia" ||
            f?.semantic?.includes("RG Sócio") ||
            f?.semantic?.includes("CNH Sócio") ||
            f?.semantic?.includes("IR Sócio")) &&
          f?.status === "APPROVED"
      )?.length ===
      3 + 3 * associates;
  const hasRejectedDocs = !isCNPJ
    ? customerArchive?.files?.filter(
        (f) =>
          (f?.semantic === "CNH" && f?.status === "REJECTED") ||
          (f?.semantic === "Comprovante de Renda" &&
            f?.status === "REJECTED") ||
          (f?.semantic === "Conta de Energia" && f?.status === "REJECTED") ||
          (f?.semantic === "RG" && f?.status === "REJECTED")
      )?.length > 0
    : customerArchive?.files?.filter(
        (f) =>
          (f?.semantic === "DRE" ||
            f?.semantic === "Balanço" ||
            f?.semantic === "Conta de Energia" ||
            f?.semantic?.includes("RG Sócio") ||
            f?.semantic?.includes("CNH Sócio") ||
            f?.semantic?.includes("IR Sócio")) &&
          f?.status === "REJECTED"
      )?.length > 0;
  const uploadedAllNFs =
    customerArchive?.files?.filter(
      (f) =>
        f?.semantic === "NFe de Serviço" ||
        f?.semantic === "NFe dos Equipamentos"
    )?.length >= 2;
  const approvedAllNFs =
    customerArchive?.files?.filter(
      (f) =>
        (f?.semantic === "NFe de Serviço" && f?.status === "APPROVED") ||
        (f?.semantic === "NFe dos Equipamentos" && f?.status === "APPROVED")
    )?.length >= 2;
  const hasRejectedNFs =
    customerArchive?.files?.filter(
      (f) =>
        (f?.semantic === "NFe de Serviço" && f?.status === "REJECTED") ||
        (f?.semantic === "NFe dos Equipamentos" && f?.status === "REJECTED")
    )?.length > 0;
  const uploadedAllSvcDocs =
    customerArchive?.files?.filter(
      (f) =>
        f?.semantic === "ART de Execução" ||
        f?.semantic === "Parecer de Acesso" ||
        f?.semantic === "Foto dos Módulos" ||
        f?.semantic === "Foto dos Inversores" ||
        f?.semantic === "Foto do Medidor"
    )?.length >= 5 && !!financing.proposal?.monitoringLink?.url;
  const approvedAllSvcDocs =
    customerArchive?.files?.filter(
      (f) =>
        (f?.semantic === "ART de Execução" && f?.status === "APPROVED") ||
        (f?.semantic === "Parecer de Acesso" && f?.status === "APPROVED") ||
        (f?.semantic === "Foto dos Módulos" && f?.status === "APPROVED") ||
        (f?.semantic === "Foto dos Inversores" && f?.status === "APPROVED") ||
        (f?.semantic === "Foto do Medidor" && f?.status === "APPROVED")
    )?.length >= 5 && financing.proposal?.monitoringLink?.status === "APPROVED";
  const hasRejectedSvcDocs =
    customerArchive?.files?.filter(
      (f) =>
        (f?.semantic === "ART de Execução" && f?.status === "REJECTED") ||
        (f?.semantic === "Parecer de Acesso" && f?.status === "REJECTED") ||
        (f?.semantic === "Foto dos Módulos" && f?.status === "REJECTED") ||
        (f?.semantic === "Foto dos Inversores" && f?.status === "REJECTED") ||
        (f?.semantic === "Foto do Medidor" && f?.status === "REJECTED")
    )?.length > 0 || financing.proposal?.monitoringLink?.status === "REJECTED";

  let status = {
    status: "SIMULAÇÃO_GERADA",
    color: financing?.btg?.preApproved ? "positive_light" : "neutral",
    txt: financing?.btg?.preApproved ? "Crédito Pré-Aprovado" : "Análise",
  };

  if (financing?.stage === "DEAL") {
    if (!addedToPropOpts && !hasChosenProp) {
      status = {
        status: "ADICIONAR_A_PROPOSTA",
        color: "positive_dark",
        txt: "Adicionar à Proposta",
      };
    } else if (!hasChosenProp) {
      status = {
        status: "SELECAO_OPCAO",
        color: "positive_dark",
        txt: "Seleção da Opção",
      };
    } else if (hasChosenProp && !uploadedAllDocs) {
      status = {
        status: "PENDENTE_DOCUMENTACAO",
        color: "warning",
        txt: "Documentação Pendente",
      };
    } else if (uploadedAllDocs && !approvedAllDocs && !hasRejectedDocs) {
      status = {
        status: "ANALISE_DOCUMENTACAO",
        color: "positive_dark",
        txt: "Documentação em Análise",
      };
    } else if (!approvedAllDocs && hasRejectedDocs) {
      status = {
        status: "DOCUMENTACAO_REPROVADA",
        color: "negative",
        txt: "Documentação Reprovada",
      };
    } else if (approvedAllDocs && !uploadedAllNFs) {
      status = {
        status: "PENDENTE_NOTAS",
        color: "warning",
        txt: "NFs Pendentes",
      };
    } else if (uploadedAllNFs && !approvedAllNFs && !hasRejectedNFs) {
      status = {
        status: "ANALISE_NOTAS",
        color: "positive_dark",
        txt: "NFs em Análise",
      };
    } else if (!approvedAllNFs && hasRejectedNFs) {
      status = {
        status: "NOTAS_REPROVADAS",
        color: "negative",
        txt: "NFs Reprovadas",
      };
    } else if (!financing?.contractSigned) {
      status =
        BTG_STATUS_MAP?.find((s) => s.status === financing?.btg?.status) ||
        BTG_STATUS_MAP?.[0];
    } else if (
      financing?.contractSigned &&
      financing?.expirationTimestamp < new Date().getTime() &&
      !financing?.serviceApproved
    ) {
      status = {
        status: "INFRACAO_ATRASO",
        color: "negative",
        txt: "Infração por Atraso",
      };
    } else if (
      financing?.contractSigned &&
      !uploadedAllSvcDocs &&
      !approvedAllSvcDocs
    ) {
      status = {
        status: "PENDENTE_COMPROVANTES",
        color: "warning",
        txt: "Comprovantes Pendentes",
      };
    } else if (
      financing?.contractSigned &&
      uploadedAllSvcDocs &&
      !approvedAllSvcDocs &&
      !hasRejectedSvcDocs
    ) {
      status = {
        status: "ANALISE_COMPROVANTES",
        color: "positive_dark",
        txt: "Comprovantes em Análise",
      };
    } else if (
      financing?.contractSigned &&
      !approvedAllSvcDocs &&
      hasRejectedSvcDocs
    ) {
      status = {
        status: "COMPROVANTES_REPROVADOS",
        color: "negative",
        txt: "Comprovantes Reprovados",
      };
    } else if (financing.serviceApproved) {
      status = {
        status: "CONCLUIDO",
        color: "positive_light",
        txt: "Concluído",
      };
    } else {
      status =
        BTG_STATUS_MAP?.find((s) => s.status === financing?.btg?.status) ||
        BTG_STATUS_MAP?.[0];
    }
  }

  return { foundStatus: status, approvedAllDocs, hasChosenProp };
};

import Popover from "@material-ui/core/Popover";
import React, { Fragment, useContext } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useHistory } from "react-router-dom";

import { ModalError } from "../../shared/components/UIElements/ModalError";
import { InputSelectWithId } from "../../shared/components/FormElements/InputSelectWithId";
import { ButtonTealContained } from "../../shared/components/MUIThemed/ButtonTealContained";
import { useForm } from "../../shared/hooks/formHook";
import { SettingsContext } from "../../shared/context/settingsContext";
import { chooseFinProposal } from "../api/financingsAPI";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { openWhatsapp } from "../../shared/util/openWhatsapp";
import { AuthContextProps, FinancingData } from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface FormSelectFinOptProps {
  auth: AuthContextProps;
  financing: FinancingData;
  setLoadedData: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  isLoading: boolean;
  uniqueFinProps: FinancingData["proposals"];
  setTab: React.Dispatch<React.SetStateAction<number>>;
}

export const FormSelectFinOpt = (props: FormSelectFinOptProps) => {
  const {
    auth,
    financing,
    sendRequest,
    setLoadedData,
    isLoading,
    uniqueFinProps,
    setTab,
  } = props;
  const classes = PopoverFormStyles();
  const settingsCtx = useContext(SettingsContext);
  const { formState, inputHandler, reportInvalid } = useForm({}, false);
  const history = useHistory();

  const chosenOpt = uniqueFinProps?.find((p) => p?.chosen);

  const options = uniqueFinProps
    ?.sort((a, b) => {
      return a?.addedToProp === b?.addedToProp ? 0 : a?.addedToProp ? -1 : 1;
    })
    ?.map((p, i) => {
      return {
        txt: `${addThousandSeparator(p?.fee * 100)}% - x${
          p?.installments
        } de R$ ${addThousandSeparator(p?.installmentValue)} - ${
          p?.gracePeriod
        } ${p?.gracePeriod === 1 ? "Mês" : "Meses"}`,
        id: p?._id,
        highlighted: p?.addedToProp,
      };
    });

  const selOpts = [formState?.inputs?.option?.value?.toString()];

  const hasSelOpt = !!selOpts?.find((o) => !!o);

  const openWhatsappHandler = () => {
    openWhatsapp(
      "31986281765",
      "Quero contratar um financiamento pré-aprovado."
    );
  };

  const selectFinOptHandler = () => {
    chooseFinProposal({
      auth,
      sendRequest,
      setLoadedFinancings: setLoadedData,
      pid: formState?.inputs?.option?.value?.toString(),
      fid: financing?._id,
    }).then(() => {
      setTab(2);
    });
  };

  return (
    <Fragment>
      <form style={{ zIndex: 502 }} className={classes.formRoot2}>
        <InputSelectWithId
          id="option"
          label="Opção Financiamento"
          variant="outlined"
          options={options}
          disabled={!options?.length}
          onInput={inputHandler}
          initialValue={chosenOpt?._id || ""}
          updateInitValue
        />
        {!isLoading && (
          <Button
            variant="contained"
            color="primary"
            disabled={!hasSelOpt}
            onClick={selectFinOptHandler}
          >
            SELECIONAR OPÇÃO
          </Button>
        )}
        {isLoading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "1.5rem",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        )}
      </form>
    </Fragment>
  );
};

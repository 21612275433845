import {
  AuthContextProps,
  InverterData,
  FormHookDispState,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// GET SINGLE INVERTER ///////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
interface GetSingleInverterProps extends FetchProps {
  invId: string;
  setLoadedData: React.Dispatch<React.SetStateAction<InverterData>>;
}

export const getSingleInverter = async (props: GetSingleInverterProps) => {
  const { sendRequest, auth, invId, setLoadedData } = props;

  try {
    const responseData: { item: InverterData } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/inverters/single/${invId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      false
    );

    setLoadedData(responseData?.item);
  } catch (err) { }
};

// GET PAGINATED INVERTERS ///////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
interface GetPaginatedInvertersProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<InverterData[]>>;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  hidration: boolean;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const getPaginatedInverters = async (
  props: GetPaginatedInvertersProps
) => {
  const {
    sendRequest,
    auth,
    setLoadedData,
    setShowLoadMore,
    multiplier,
    hidration,
    setFirstLoad,
  } = props;

  try {
    const responseData: { items: InverterData[]; hasMoreItems: boolean } =
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/inverters/paginated/${multiplier}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        },
        false
      );

    setShowLoadMore(responseData.hasMoreItems);
    setLoadedData((prevValues) => {
      if (!!prevValues && prevValues.length > 0 && !hidration) {
        return [...prevValues, ...responseData.items];
      } else {
        return responseData.items;
      }
    });
    if (setFirstLoad) {
      setFirstLoad(true);
    }
  } catch (err) { }
};

// GET FILTERED INVERTERS ///////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
interface GetFilteredInvertersProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<InverterData[]>>;
  setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue: string;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  fetchId: string;
  setMultiplier?: React.Dispatch<React.SetStateAction<number>>;
}

export const getFilteredInverters = async (
  props: GetFilteredInvertersProps
) => {
  const {
    auth,
    sendRequest,
    setLoadedData,
    setFiltered,
    searchValue,
    setShowLoadMore,
    multiplier,
    setMultiplier,
    fetchId,
  } = props;

  try {
    const responseData: { items: InverterData[]; hasMoreItems: boolean } =
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/inverters/filtered/${multiplier}`,
        "POST",
        JSON.stringify({
          fetchId,
          searchValue,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        false,
        "items"
      );

    if (responseData && responseData.items?.length > 0) {
      setLoadedData((prevValues) => {
        if (!!prevValues && prevValues.length > 0 && multiplier > 0) {
          return [...prevValues, ...responseData.items];
        } else {
          return responseData.items;
        }
      });
    }
    setFiltered(true);
    setShowLoadMore(responseData.hasMoreItems);
    if (setMultiplier) {
      setMultiplier(0);
    }
  } catch (err) { }
};

// CREATE INVERTER /////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
interface CreateInverterProps extends FetchProps {
  formState: FormHookDispState;
}

export const createInverter = async (props: CreateInverterProps) => {
  const { sendRequest, auth, formState } = props;

  const isMicro = formState.inputs?.isMicro?.value as boolean;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/inverters/single`,
      "POST",
      JSON.stringify({
        model: formState.inputs?.model?.value,
        manufacturer: formState.inputs?.manufacturer?.value,
        power: +formState.inputs?.power?.value,
        warranty: +formState.inputs?.warranty?.value,
        monitoring: formState.inputs?.monitoring?.value,
        voltage: +formState.inputs?.voltage?.value
          ?.toString()
          ?.replace(/\D/g, ""),
        type: !isMicro ? "inverter" : "microinverter",
        stock: +formState.inputs?.stock?.value || 0,
        price: +formState.inputs?.price?.value || 0,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
  } catch (err) { }
};

// EDIT INVERTER /////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
interface EditInverterProps extends FetchProps {
  invId: string;
  formState: FormHookDispState;
}

export const editInverter = async (props: EditInverterProps) => {
  const { sendRequest, auth, invId, formState } = props;

  const isMicro = formState.inputs?.isMicro?.value as boolean;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/inverters/single/${invId}`,
      "PUT",
      JSON.stringify({
        model: formState.inputs?.model?.value,
        manufacturer: formState.inputs?.manufacturer?.value,
        power: +formState.inputs?.power?.value,
        warranty: +formState.inputs?.warranty?.value,
        monitoring: formState.inputs?.monitoring?.value,
        voltage: +formState.inputs?.voltage?.value
          ?.toString()
          ?.replace(/\D/g, ""),
        type: !isMicro ? "inverter" : "microinverter",
        stock: +formState.inputs?.stock?.value || 0,
        price: +formState.inputs?.price?.value || 0,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
  } catch (err) { }
};

// CLONE INVERTER /////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
interface CloneInverterProps extends FetchProps {
  invId: string;
}

export const cloneInverter = async (props: CloneInverterProps) => {
  const { sendRequest, auth, invId } = props;

  try {
    const responseData: { itemId: string } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/inverters/single/${invId}`,
      "POST",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    return responseData.itemId;
  } catch (err) { }
};

// UPLOAD INVERTERS ///////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
interface UploadInvertersProps extends FetchProps {
  mapping: Record<string, string>;
  fileContent: string;
}

export const uploadInverters = async ({
  sendRequest,
  auth,
  mapping,
  fileContent
}: UploadInvertersProps) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/inverters/many`,
      "POST",
      JSON.stringify({
        mapping,
        fileContent
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
  } catch (err) { }
};

// DELETE INVERTER ////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
interface DeleteInverterProps extends FetchProps {
  invId: string;
  setLoadedData: React.Dispatch<React.SetStateAction<InverterData[]>>;
}

export const deleteInverter = async (props: DeleteInverterProps) => {
  const { sendRequest, auth, invId, setLoadedData } = props;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/inverters/single/${invId}`,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setLoadedData((prevValues) => {
      return prevValues.filter((item) => {
        return item?._id !== invId;
      });
    });
  } catch (err) { }
};

// DELETE SELECTED INVERTER ///////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
interface DeleteSelectedInvertersProps extends FetchProps {
  itemsIds: string[];
  setLoadedData: React.Dispatch<React.SetStateAction<InverterData[]>>;
}

export const deleteSelectedInverters = async (
  props: DeleteSelectedInvertersProps
) => {
  const { sendRequest, auth, itemsIds, setLoadedData } = props;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/inverters/selected`,
      "POST",
      JSON.stringify({ itemsIds }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setLoadedData((prevValues) => {
      return prevValues.filter((item) => {
        return !itemsIds?.includes(item?._id);
      });
    });
  } catch (err) { }
};

// GET FILTERED FOR SELECTION INVERTERS ///////////////////////////////////
///////////////////////////////////////////////////////////////////////////
interface GetFilteredForSelectionInvertersProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<InverterData[]>>;
  searchValue: string;
}

export const getFilteredForSelectionInverters = async (
  props: GetFilteredForSelectionInvertersProps
) => {
  const { auth, sendRequest, setLoadedData, searchValue } = props;

  try {
    const responseData: { items: InverterData[] } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/inverters/filtered`,
      "POST",
      JSON.stringify({
        searchValue,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      false
    );

    if (responseData && responseData.items?.length > 0) {
      setLoadedData(responseData.items || []);
    }
  } catch (err) { }
};

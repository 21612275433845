import { makeStyles } from "@material-ui/core/styles";

export const CommonSimpleFormStyles = makeStyles((theme) => ({
  title: {
    color: "#08a9c9",
  },
  root: {
    margin: "0",
    padding: "0",
    display: "flex",
    justifyContent: "center",
    "& form": {
      margin: "10% auto",
      width: "400px",
      minHeight: "300px",
      padding: "1rem 0",
      border: "1px solid rgba(61, 116, 255, 0.35)",
      borderRadius: "15px",
      boxShadow: "0px 0px 9px -1px rgba(61,116,255,0.3)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      [theme.breakpoints.down("xs")]: {
        width: "95%",
        maxWidth: "380px",
        minHeight: "280px",
        margin: "30% auto",
      },
      "& > *": {
        margin: theme.spacing(1),
        width: "300px",
        [theme.breakpoints.down("xs")]: {
          width: "280px",
        },
      },
    },
  },
  gridCoordinates: {
    display: "grid",
    gridTemplateColumns: "47% 47%",
    gridGap: "6%",
  },
}));

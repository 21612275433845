export const formatPostalCode = (data: string) => {
  const postalCodeNumbers = data?.replace(/\D/g, "");

  if (postalCodeNumbers?.length === 8) {
    const strA = postalCodeNumbers.substr(0, 5);
    const strB = postalCodeNumbers.substr(5, 8);

    return `${strA}-${strB}`;
  } else {
    return "";
  }
};

import { AuthContextProps } from "../../shared/data/types";

interface UploadPreSalesProps {
  sendRequest: any;
  auth: AuthContextProps;
  mapping: Record<string, string>;
  fileContent: string;
}

export const uploadPreSales = async ({
  sendRequest,
  auth,
  mapping,
  fileContent,
}: UploadPreSalesProps) => {
  try {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/sales/leads/upload/${auth.userId}`,
      "POST",
      JSON.stringify({ mapping, fileContent }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );
    return responseData;
  } catch (err) {
    throw err;
  }
};

import { Fragment, useEffect, useState } from "react";

import { useForm } from "../../../shared/hooks/formHook";
import { HomeDashboardUser } from "./HomeDashboardUser";
import { HomeDashboardViewedPropsSales } from "./HomeDashboardViewedPropsSales";
import {
  AuthContextProps,
  KanbanFilledData,
  SettingsContextProps,
  NotePopCustData,
} from "../../../shared/data/types";
import { HomeDashboardTabs } from "./HomeDashboardTabs";

interface HomeDashboardProps {
  auth: AuthContextProps;
  settingsCtx: SettingsContextProps;
}

export const HomeDashboard = (props: HomeDashboardProps) => {
  const { auth, settingsCtx } = props;
  const { formState, inputHandler, reportInvalid } = useForm({}, false);
  const isSalesManager =
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip?.user?.toString() === auth?.userId;
    })?.salesManager || false;
  const [fetchId, setFetchId] = useState<string>(
    auth.type !== "VENDOR" || isSalesManager ? auth.managerId : auth.userId
  );
  const [isLoadingGenSales, setIsLoadingGenSales] = useState<boolean>(false);
  const [isLoadingClosedSales, setIsLoadingClosedSales] =
    useState<boolean>(false);
  const [loadedGenSales, setLoadedGenSales] = useState<
    {
      label: string;
      val: number;
      sales: number;
      delta: number;
      deltaIsPositive: boolean;
    }[]
  >([
    {
      label: "",
      val: 0,
      sales: 0,
      delta: 0,
      deltaIsPositive: true,
    },
  ]);
  const [loadedClosedSales, setLoadedClosedSales] = useState<
    {
      label: string;
      val: number;
      sales: number;
      power: number;
      delta: number;
      deltaIsPositive: boolean;
    }[]
  >([
    {
      label: "",
      val: 0,
      sales: 0,
      power: 0,
      delta: 0,
      deltaIsPositive: true,
    },
  ]);
  const [loadedSalesInProgress, setLoadedSalesInProgress] = useState<{
    totalSales: number;
    totalAmount: number;
    totalPotProfit: number;
  }>({ totalSales: 0, totalAmount: 0, totalPotProfit: 0 });
  const [loadedLastViewedPropsSales, setLoadedLastViewedPropsSales] =
    useState<KanbanFilledData>(null);
  const [loadedNextSchedules, setLoadedNextSchedules] = useState<
    NotePopCustData[]
  >([]);

  useEffect(() => {
    if (formState?.inputs?.user?.value) {
      setFetchId(formState?.inputs?.user?.value as string);
    }
  }, [formState?.inputs?.user?.value]);

  return (
    <Fragment>
      <HomeDashboardUser
        inputHandler={inputHandler}
        settingsCtx={settingsCtx}
        auth={auth}
        formState={formState}
        fetchId={fetchId}
      />
      <HomeDashboardViewedPropsSales
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        loadedData={loadedLastViewedPropsSales}
        setLoadedData={setLoadedLastViewedPropsSales}
      />
      <HomeDashboardTabs 
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        loadedNextSchedules={loadedNextSchedules}
        setLoadedNextSchedules={setLoadedNextSchedules}
        loadedGenSales={loadedGenSales} 
        setLoadedGenSales={setLoadedGenSales} 
        setIsLoadingGenSales={setIsLoadingGenSales} 
        loadedClosedSales={loadedClosedSales} 
        setIsLoadingClosedSales={setIsLoadingClosedSales} 
        setLoadedClosedSales={setLoadedClosedSales} 
        isLoadingGenSales={isLoadingGenSales} 
        isLoadingClosedSales={isLoadingClosedSales}
        loadedSalesInProgress={loadedSalesInProgress}
        setLoadedSalesInProgress={setLoadedSalesInProgress}
      />
     
    </Fragment>
  );
};

import { PlaceData, AuthContextProps } from "../../shared/data/types";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
}
// GET FILTERED PLACES /////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface GetFilteredPlacesProps extends FetchProps {
  auth: AuthContextProps;
  uf: string;
  searchValue: string;
  setLoadedPlaces: React.Dispatch<React.SetStateAction<PlaceData[]>>;
  setOptions: React.Dispatch<React.SetStateAction<string[]>>;
}

export const getfilteredPlaces = async (props: GetFilteredPlacesProps) => {
  const { sendRequest, auth, setLoadedPlaces, setOptions, uf, searchValue } =
    props;

  try {
    const responseData: { places: PlaceData[] } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/places/filtered`,
      "POST",
      JSON.stringify({ uf, searchValue }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedPlaces(responseData.places);
    setOptions(
      responseData.places?.map((p) => {
        return p?.name || "";
      })
    );

    return responseData.places?.map((p) => {
      return p?.name || "";
    });
  } catch (err) {}
};

import { useState, useCallback, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";

import { AuthContext } from "../context/authContext";

export const useHttpClient = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [errorTitle, setErrorTitle] = useState<string>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [zeroResults, setZeroResults] = useState<boolean>(false);

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async (
      url: string,
      method: string = "GET",
      body: BodyInit = null,
      headers: HeadersInit = {},
      successMessage: boolean = false,
      zeroResultsKey: string = "items"
    ) => {
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);

      try {
        const response = await fetch(url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal,
        });

        var responseData = await response.json();

        activeHttpRequests.current = activeHttpRequests.current.filter(
          (reqCtrl) => {
            return reqCtrl !== httpAbortCtrl;
          }
        );

        if (!response.ok) {
          if (responseData?.shouldLogout as boolean) {
            auth.logout();
          }

          throw new Error(responseData?.message);
        }

        if (successMessage) {
          setSuccess(true);
        }

        if (
          responseData?.[zeroResultsKey] &&
          responseData?.[zeroResultsKey]?.length === 0
        ) {
          setZeroResults(true);
        }

        setIsLoading(false);
        localStorage.setItem("connectionStatus", "ONLINE");

        return responseData;
      } catch (err: any) {
        console.log(err.message);
        setError(err.message || "Algo deu errado, por favor tente novamente.");
        setErrorTitle(responseData?.title || "Erro");
        if (
          err.message?.toLowerCase()?.includes("fetch") ||
          err.message?.toLowerCase()?.includes("event")
        ) {
          localStorage.setItem("connectionStatus", "OFFLINE");
        }
        setIsLoading(false);
        throw err;
      }
    },
    []
  );

  useEffect(() => {
    return () => {
      activeHttpRequests.current.forEach((abortCtrl) => {
        abortCtrl.abort();
      });
    };
  }, []);

  const clearError = (needPush: boolean = false) => {
    setError(null);
    if (needPush) {
      history.push("/");
    }
  };

  const clearSuccess = () => {
    setSuccess(false);
  };

  const clearZeroResults = useCallback(() => {
    setZeroResults(false);
  }, []);

  return {
    isLoading,
    error,
    errorTitle,
    success,
    sendRequest,
    clearError,
    clearSuccess,
    zeroResults,
    clearZeroResults,
  };
};

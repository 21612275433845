import { makeStyles } from "@material-ui/core/styles";

export const ProposalStepTwoKitChoiceStyles = makeStyles((theme) => ({
  stepper: {
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      fontWeight: "700",
    },
  },
  outerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContainer: {
    width: "100%",
  },
  container100Centered: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  formTitle: {
    color: "#043a50",
    display: "flex",
    flexDirection: "row",
    width: "90%",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    fontSize: "1.1rem",
    height: "2.2rem",
    padding: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  formTitleLessPadding: {
    color: "#043a50",
    display: "flex",
    flexDirection: "row",
    width: "90%",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    fontSize: "1.1rem",
    height: "2.2rem",
    padding: ".2rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  choiceButtonsGrid: {
    display: "grid",
    width: "90%",
    height: "auto",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "100%",
      // marginTop: "1rem",
    },
  },
  choiceButton: {
    marginBottom: "20px",
    "& span": {
      display: "flex",
      flexDirection: "column",
    },
  },
  choiceButtonWide: {
    width: "100%",
    marginBottom: "20px",
    "& span": {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  btnIcons: {
    margin: "0.5rem 0 0.5rem",
    fontSize: "3.5rem",
  },
  btnIconsFontAwesome: {
    margin: "0.7rem 0 0.3rem",
    fontSize: "3.25rem",
  },
  separator: {
    height: "40px",
  },
}));

import { useState, Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { PopoverFormAddService } from "./PopoverFormAddService";
import { SaleDashServiceItem } from "./SaleDashServiceItem";
import {
  AuthContextProps,
  ProposalData,
  KanbanFilledData,
} from "../../shared/data/types";

import "./SaleDash.scss";

interface SaleDashServicesProps {
  memory: KanbanFilledData["items"][0];
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  auth: AuthContextProps;
  itemId: string;
}

export const SaleDashServices = (props: SaleDashServicesProps) => {
  const { memory, auth, itemId, setLoadedData } = props;
  const { sendRequest, isLoading, error, clearError } = useHttpClient();
  const services = memory?.afterSalesData?.services || [];
  const proposal = memory?.approvedProposal as ProposalData;
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <Fragment>
      {isLoading && <LoadingSpinnerOverlayRegular image color="primary" />}
      <ModalError error={error} onClear={clearError} />
      <PopoverFormAddService
        item={memory}
        itemId={memory?._id}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        auth={auth}
        sendRequest={sendRequest}
        setKanbanData={setLoadedData}
      />
      {services?.length === 0 && (
        <p className="sale-dash__col-right-content-empty">
          Esta atividade não possui nenhum serviço / produto
        </p>
      )}
      <div className="sale-dash__proposals">
        {services?.length > 0 &&
          services.map((item, i) => {
            return (
              <SaleDashServiceItem
                auth={auth}
                setLoadedData={setLoadedData}
                proposal={proposal}
                sale={memory}
                service={item}
                key={`service-${i}`}
              />
            );
          })}
      </div>
      <div className="sale-dash__col-right-add-item-btn">
        <IconButton
          title="Adicionar serviço"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <AddRoundedIcon style={{ color: "#043a50" }} />
        </IconButton>
      </div>
    </Fragment>
  );
};

import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

export const SwitchCustomAlwaysBlue = withStyles({
  switchBase: {
    color: "#189ab4",
    "&$checked": {
      color: "#189ab4",
    },
  },
  checked: {},
  track: {},
})(Switch);

import React, { useEffect, useState, FocusEvent } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

interface InputSelectWithIdProps {
  id: string;
  label: string;
  onInput: (
    id: string,
    value: string | number,
    isValid: boolean,
    label: string
  ) => void;
  options: { txt: string; id: string | number; highlighted?: boolean }[];
  initialValue?: string | number;
  variant?: "standard" | "filled" | "outlined";
  disabled?: boolean;
  forceError?: boolean;
  trackFocus?: boolean;
  focusHandler?: (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  reset?: boolean;
  resetOnOptionsChange?: boolean;
  updateInitValue?: boolean;
  onChangeHandler?: () => void;
}

export const InputSelectWithId = (props: InputSelectWithIdProps) => {
  const {
    id,
    label,
    onInput,
    options,
    initialValue,
    forceError,
    variant,
    disabled,
    trackFocus,
    focusHandler,
    resetOnOptionsChange,
    reset,
    updateInitValue,
    onChangeHandler,
  } = props;
  const [inputValue, setInputValue] = useState(initialValue || "");
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    onInput(
      id,
      inputValue,
      true,
      label.charAt(0).toUpperCase() + label.slice(1)
    );
  }, [id, inputValue, label, onInput]);

  useEffect(() => {
    if (reset) {
      onInput(id, "", true, label.charAt(0).toUpperCase() + label.slice(1));
      setInputValue("");
      setIsTouched(false);
    }
  }, [reset, id, inputValue, label, onInput]);

  useEffect(() => {
    if (updateInitValue) {
      setInputValue(initialValue);
    }
  }, [initialValue, updateInitValue]);

  useEffect(() => {
    if (
      resetOnOptionsChange &&
      !options
        ?.map((opt) => {
          return opt.id;
        })
        ?.includes(inputValue) &&
      (!!inputValue || typeof inputValue === "number")
    ) {
      setInputValue(initialValue);
      onInput(
        id,
        initialValue,
        true,
        label.charAt(0).toUpperCase() + label.slice(1)
      );
    }
  }, [options?.[0], options?.[options?.length - 1], resetOnOptionsChange]);

  const touchHandler = () => {
    setIsTouched(true);
  };

  return (
    <FormControl variant={variant} disabled={disabled} required={false}>
      <InputLabel
        variant={variant}
        // error={forceError}
        style={
          variant === "outlined"
            ? {
                backgroundColor: "#fff",
                paddingLeft: "5px",
                paddingRight: "5px",
                marginLeft: "-5px",
              }
            : {}
        }
        id={`input-label-${id}`}
      >
        {label.charAt(0).toUpperCase() + label.slice(1)}
      </InputLabel>
      <Select
        variant={variant}
        // error={forceError}
        labelId={`label-${id}`}
        id={id}
        value={inputValue}
        onBlur={touchHandler}
        onFocus={trackFocus && focusHandler}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setInputValue(event.target.value);
          if (onChangeHandler) {
            onChangeHandler();
          }
        }}
      >
        {options.map((option, i) => {
          return (
            <MenuItem
              style={{
                color: !option?.highlighted ? "inherit" : "#189ab4",
                fontWeight: !option?.highlighted ? "inherit" : 700,
              }}
              key={option.id + "-option-" + i}
              value={option.id}
            >
              {option.txt}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

import {
  AuthContextProps,
  FinancingData,
  SettingsContextData,
  ExpiredFinsData,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// GET PAGINATED FINANCINGS ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
interface GetPaginatedFinancingsProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  setExpiredFinData?: React.Dispatch<React.SetStateAction<ExpiredFinsData>>;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  hidration: boolean;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
  order?: "LAST_UPDATED" | "REGISTRY";
  preApprovedOnly?: "true" | "false";
  settingsCtx?: SettingsContextData;
  fetchId?: string;
}

export const getPaginatedFinancings = async (
  props: GetPaginatedFinancingsProps
) => {
  const {
    sendRequest,
    auth,
    setLoadedData,
    setExpiredFinData,
    setShowLoadMore,
    multiplier,
    hidration,
    setFirstLoad,
    order,
    preApprovedOnly,
    settingsCtx,
    fetchId,
  } = props;

  const teamsIds = settingsCtx?.teams?.map((team) => team._id);

  const fetchType: "MANAGER" | "USER" | "TEAM" =
    fetchId === auth.managerId
      ? "MANAGER"
      : teamsIds?.includes(fetchId)
      ? "TEAM"
      : "USER";

  try {
    const apiUrl = !fetchId
      ? `${
          process.env.REACT_APP_BACKEND_URL
        }/financings/paginated/${multiplier}/${order || "REGISTRY"}/${
          preApprovedOnly || "false"
        }`
      : `${
          process.env.REACT_APP_BACKEND_URL
        }/financings/paginated/${multiplier}/${order || "REGISTRY"}/${
          preApprovedOnly || "false"
        }/${fetchType}/${fetchId}`;

    const responseData: {
      financings: FinancingData[];
      hasMoreItems: boolean;
      expiredFinancings: number;
      expiredFinancingsLimit: number;
    } = await sendRequest(apiUrl, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });

    setShowLoadMore(responseData.hasMoreItems);
    setLoadedData((prevValues) => {
      if (!!prevValues && prevValues.length > 0 && !hidration) {
        return [...prevValues, ...responseData.financings];
      } else {
        return responseData.financings;
      }
    });
    if (setExpiredFinData) {
      setExpiredFinData({
        expiredFinancings: responseData?.expiredFinancings,
        expiredFinancingsLimit: responseData?.expiredFinancingsLimit,
        hasReachedLimit:
          responseData?.expiredFinancings >
          responseData?.expiredFinancingsLimit,
      });
    }
    if (setFirstLoad) {
      setFirstLoad(true);
    }
  } catch (err) {}
};

// GET FILTERED FINANCINGS /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
interface GetFilteredFinancingsProps extends FetchProps {
  setLoadedItems: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  setExpiredFinData?: React.Dispatch<React.SetStateAction<ExpiredFinsData>>;
  searchValue?: string;
  timePeriod?: {
    timePeriodEarlierSelected: string;
    timePeriodOlderSelected: string;
  };
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  setMultiplier?: React.Dispatch<React.SetStateAction<number>>;
}

export const getFilteredFinancings = async (
  props: GetFilteredFinancingsProps
) => {
  const {
    auth,
    sendRequest,
    setLoadedItems,
    setFiltered,
    searchValue,
    timePeriod,
    setShowLoadMore,
    multiplier,
    setMultiplier,
    setExpiredFinData,
  } = props;

  try {
    const responseData: {
      financings: FinancingData[];
      hasMoreItems: boolean;
      expiredFinancings: number;
      expiredFinancingsLimit: number;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/financings/filtered/${multiplier}`,
      "POST",
      JSON.stringify({
        searchValue,
        timePeriod,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      false,
      "financings"
    );

    if (responseData && responseData.financings?.length > 0) {
      setLoadedItems((prevValues) => {
        if (!!prevValues && prevValues.length > 0 && multiplier > 0) {
          return [...prevValues, ...responseData.financings];
        } else {
          return responseData.financings;
        }
      });
    }
    setFiltered(true);
    setShowLoadMore(responseData.hasMoreItems);
    if (setMultiplier) {
      setMultiplier(0);
    }
    if (setExpiredFinData) {
      setExpiredFinData({
        expiredFinancings: responseData?.expiredFinancings,
        expiredFinancingsLimit: responseData?.expiredFinancingsLimit,
        hasReachedLimit:
          responseData?.expiredFinancings >
          responseData?.expiredFinancingsLimit,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// CHOOSE FIN PROPOSAL /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface ChooseFinProposalProps extends FetchProps {
  fid: string;
  pid: string;
  setLoadedFinancings: React.Dispatch<React.SetStateAction<FinancingData[]>>;
}

export const chooseFinProposal = async (props: ChooseFinProposalProps) => {
  const { fid, sendRequest, auth, setLoadedFinancings, pid } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/financings/single/proposal/chosen/${fid}/${pid}`;
    const responseData: { financing: FinancingData } = await sendRequest(
      apiUrl,
      "PUT",
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedFinancings((prevValues) => {
      prevValues = prevValues?.map((item) => {
        if (item?._id === fid) {
          item = responseData.financing;
        }

        return item;
      });

      return prevValues;
    });
  } catch (err) {}
};

import { makeStyles } from "@material-ui/core/styles";

export const ModalErrorStyles = makeStyles((theme) => ({
  errorModalTitle: {
    color: "#f50057",
  },
  titlePos: {
    color: "#189ab4",
    paddingBottom: 0,
    paddingTop: 0,
  },
  errorModalContent: {
    color: "#424242",
  },
  criticalWarningImgCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    objectFit: "cover",
    width: "200px",
    heigth: "200px",
    margin: "0 auto",
  },
  inputContainer: {
    "& > *": {
      width: "100%",
    },
    padding: "0 2rem",
  },
  criticalWarningImg: {
    width: "100%",
    height: "auto",
  },
  modalFinContainer: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px",
    },
    "& .MuiDialog-paper": {
      margin: "0",
    },
  },
  modalFin: {
    width: "800px",
    [theme.breakpoints.down("sm")]: {
      width: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "340px",
    },
  },
  modalCommunicationContainer: {
    borderRadius: "10px",
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "10px",
      maxWidth: "1800px",
      width: "95%",
    },
    "& .MuiDialog-paper": {
      borderRadius: "10px",
      margin: "0",
    },
  },
  modalCommunication: {
    width: "100%",
  },
}));

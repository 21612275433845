import { Fragment, useContext, useEffect, useState } from "react";

import { HomeModals } from "../../components/authenticated/HomeModals";
import { HomeCarousel } from "../../components/authenticated/HomeCarousel";
import { HomeDashboard } from "../../components/authenticated/HomeDashboard";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { AuthContext } from "../../../shared/context/authContext";
import { SettingsContext } from "../../../shared/context/settingsContext";
import { useHttpClient } from "../../../shared/hooks/httpHook";
import { LoadingSpinnerFullScreenGraph } from "../../../shared/components/UIElements/LoadingSpinnerFullScreenGraph";
import { useIdbStorage } from "../../../shared/util/idbStorage";
import { getContent } from "../../api/contentAPI";
import { authZendeskUser } from "../../api/generalAPI";
import {
  fetchGeneralUserData,
  fetchGeneralUserDataFromIDB,
  getManagerAddons,
  getUserSettings,
  getUserSettingsFromIDB,
} from "../../api/homePageUserAPI";
import {
  GeneralUserData,
  ContentData,
  AddonOmnichannelStatus,
} from "../../../shared/data/types";

import "./HomeUser.scss";
import { getAddonStatus } from "../../api/signupPagesAPI";

interface HomeProps {
  needCriticalUpdate: boolean;
  needUpdate: boolean;
  setZendeskJWT: React.Dispatch<React.SetStateAction<string>>;
  zendeskJWT: string;
}

export default function HomeUser(props: HomeProps) {
  const { needCriticalUpdate, needUpdate, setZendeskJWT, zendeskJWT } = props;
  const auth = useContext(AuthContext);
  const settingsCtx = useContext(SettingsContext);
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const { openIndexedDBHandlerComp, readAllDataInIdb } = useIdbStorage();
  const [loadedContent, setLoadedContent] = useState<ContentData>(null);
  const [loadedData, setLoadedData] = useState<GeneralUserData>(null);
  const [fetchId, setFetchId] = useState<string>(auth?.userId || "");
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  const [networkData1Received, setNetworkData1Received] =
    useState<boolean>(false);

  useEffect(() => {
    if (auth.isLoggedIn) {
      getUserSettings({ sendRequest, settingsCtx, auth, setSettingsLoaded });
    }
  }, []);

  // useEffect(() => {
  //   if (settingsCtx.addons?.length) {
  //     settingsCtx.addons.forEach((addon) => {
  //       if (
  //         addon.item.status === AddonOmnichannelStatus.ACTIVE &&
  //         addon.item.validityTimestamp < Date.now()
  //       ) {
  //         getAddonStatus({
  //           sendRequest,
  //           auth,
  //           addonType: addon.type,
  //           addonId: addon.item.uuid,
  //         }).then((response) => {
  //           getManagerAddons({
  //             sendRequest,
  //             auth,
  //             settingsCtx,
  //           });
  //         });
  //       }
  //     });
  //   }
  // }, [settingsCtx.addons]);

  // useEffect(() => {
  //   if (auth.isLoggedIn) {
  //     getContent({ sendRequest, setLoadedContent });
  //   }
  // }, [auth.isLoggedIn]);

  useEffect(() => {
    if ("indexedDB" in window) {
      getUserSettingsFromIDB({
        networkDataReceived: settingsLoaded,
        openIndexedDBHandlerComp,
        readAllDataInIdb,
        setLoadedData: setSettingsLoaded,
      });
    }
  }, [settingsLoaded, openIndexedDBHandlerComp, readAllDataInIdb]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      fetchGeneralUserData({
        sendRequest,
        auth,
        setNetworkDataReceived: setNetworkData1Received,
        setLoadedData,
      });
      setFetchId(auth.userId);
    }
  }, [sendRequest, auth.isLoggedIn]);

  useEffect(() => {
    if (!!auth?.managerUserId && auth.status === "ACTIVE" && !zendeskJWT) {
      authZendeskUser({ sendRequest, auth, setZendeskJWT });
    }
    // return () => {
    //   setZendeskJWT("");
    // };
    // setZendeskJWT("a");
  }, [auth?.managerUserId]);

  useEffect(() => {
    if ("indexedDB" in window) {
      fetchGeneralUserDataFromIDB({
        networkDataReceived: networkData1Received,
        openIndexedDBHandlerComp,
        readAllDataInIdb,
        setLoadedData,
      });
    }
  }, [networkData1Received, openIndexedDBHandlerComp, readAllDataInIdb]);

  if (!loadedData || !settingsLoaded) {
    return (
      <Fragment>
        <ModalError error={error} onClear={clearError} />
        <LoadingSpinnerFullScreenGraph />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <HomeModals
        needCriticalUpdate={needCriticalUpdate}
        needUpdate={needUpdate}
        error={error}
        clearError={clearError}
        auth={auth}
      />
      <div className="home-dashboard">
        {loadedContent && auth.whitelabel === "SERRANA" && (
          <HomeCarousel carouselData={loadedContent.homeCarousel} auth={auth} />
        )}
        {loadedData && settingsLoaded && (
          <HomeDashboard auth={auth} settingsCtx={settingsCtx} />
        )}
      </div>
    </Fragment>
  );
}

import React, { useReducer, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";

import { InputDispState, InputDispAction } from "./Input";
import {
  NumberFormatCustomProps,
  InputWithNumberFormatProps,
} from "./InputCardNumber";
import { validate } from "../../util/validators";

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, name, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=""
      decimalSeparator=","
      isNumericString
      decimalScale={0}
      format="##.###.###/####-##"
      mask="_"
    />
  );
}

const inputReducer = (state: InputDispState, action: InputDispAction) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: /^[0-9]*$/.test(action.val)
          ? action.val
          : action.val.replace(/\D/g, ""),
        isValid: validate(
          /^[0-9]*$/.test(action.val)
            ? action.val
            : action.val.replace(/\D/g, ""),
          action.validators
        ),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    case "UNTOUCH":
      return {
        ...state,
        isTouched: false,
      };
    default:
      return state;
  }
};

export const InputCnpj = (props: InputWithNumberFormatProps) => {
  const {
    id,
    label,
    onInput,
    forceError,
    helperText,
    type,
    initialValue,
    initialValid,
    variant,
    placeholder,
    trackFocus,
    disabled,
    validators,
    reinitialize,
    reset,
    focusHandler,
  } = props;
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || "",
    isTouched: false,
    isValid: initialValid || false,
  });
  const { value, isValid, isTouched } = inputState;

  useEffect(() => {
    onInput(id, value, isValid, label.charAt(0).toUpperCase() + label.slice(1));
  }, [id, value, isValid, label, onInput]);

  useEffect(() => {
    if (reset) {
      dispatch({
        type: "CHANGE",
        val: "",
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
    if (reinitialize) {
      dispatch({
        type: "CHANGE",
        val: initialValue,
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
  }, [reset, reinitialize, initialValue]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: validators,
    });
  };

  const touchHandler = () => {
    dispatch({ type: "TOUCH" });
  };

  return (
    <TextField
      disabled={disabled}
      error={(!isValid && isTouched) || forceError}
      id={id}
      name={id}
      value={value}
      variant={variant}
      onFocus={trackFocus && focusHandler}
      onChange={changeHandler}
      onBlur={touchHandler}
      type={type}
      label={label.charAt(0).toUpperCase() + label.slice(1)}
      helperText={forceError ? helperText : !isValid && isTouched && helperText}
      placeholder={placeholder}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
    />
  );
};

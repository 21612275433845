import {
  UserData,
  AuthContextProps,
  FormHookDispState,
  UserDataProps,
} from "../../shared/data/types";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  auth: AuthContextProps;
}

interface FetchUserDataProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<UserData>>;
}

export const fetchUserData = async (props: FetchUserDataProps) => {
  const { sendRequest, auth, setLoadedData } = props;

  try {
    const responseData: { user: UserData } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/users/user/${auth.userId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedData(responseData.user);
  } catch (err) {}
};

interface CancelHandlerProps extends FetchProps {
  setShowCustomConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  formState: FormHookDispState;
}

export const cancelHandler = async (props: CancelHandlerProps) => {
  const { sendRequest, auth, setShowCustomConfirmModal, formState } = props;

  setShowCustomConfirmModal(false);
  try {
    const responseData: {
      newStatus: {
        statusOk: false;
        status: "CANCELLED";
        signupPlan: "NOT_ADHERED";
      };
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/signup/user-status/${auth.userId}`,
      "PATCH",
      JSON.stringify({
        mainReason: formState.inputs.mainReason.value,
        secondaryReason: formState.inputs.secondaryReason.value,
        reasonToComeBack: formState.inputs.reasonToComeBack.value,
        otherReasonToComeBack: formState.inputs.otherReasonToComeBack.value,
        message: formState.inputs.message.value,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
    const storedData: UserDataProps = JSON.parse(
      localStorage.getItem("userData")
    );
    auth.login(
      storedData.userId,
      storedData.email,
      storedData.personalData,
      storedData.managerId,
      storedData.managerUserId,
      storedData.vendors,
      storedData.uToken,
      storedData.uType,
      storedData.isAdmin,
      responseData.newStatus.statusOk,
      responseData.newStatus.status,
      responseData.newStatus.signupPlan,
      storedData.activeModules || [],
      storedData.whitelabel || "",
      new Date(storedData.expiration)
    );
  } catch (err) {
    console.log(err);
  }
};

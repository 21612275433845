import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { formatPhoneNumber } from "../../shared/util/formatPhoneNumber";
import { sum } from "../../shared/util/sum";
import { getFinancingCashFlow } from "../../proposal/util/getFinancingCashFlow";
import { getDaysFromDate } from "../../shared/util/getDaysFromDate";
import {
  ProposalData,
  SalePopCustData,
  UserData,
} from "../../shared/data/types";

export const getContractTagsValues = (
  sale: SalePopCustData,
  contractNumber: number,
  proposal: ProposalData,
  user: UserData
) => {
  const avgtarif =
    proposal.tariffModality === "B"
      ? proposal?.energyBillData
          ?.map((data) => {
            return data.kwhPrice;
          })
          ?.reduce((a, b) => {
            return a + b;
          }) / proposal?.energyBillData?.length
      : proposal?.energyBillData
          ?.map((data) => {
            return data.kwhPrice + data.tusd;
          })
          ?.reduce((a, b) => {
            return a + b;
          }) / proposal?.energyBillData?.length;

  let tagsValues: { [key: string]: any } = {
    nome_cliente:
      sale?.customer?.name || `*** DADO NÃO CADASTRADO: {nome_cliente} ***`,
    uf_cliente:
      sale?.customer?.uf || `*** DADO NÃO CADASTRADO: {uf_cliente} ***`,
    cidade_cliente:
      sale?.customer?.city || `*** DADO NÃO CADASTRADO: {cidade_cliente} ***`,
    email_cliente:
      sale?.customer?.email || `*** DADO NÃO CADASTRADO: {email_cliente} ***`,
    telefone_cliente:
      formatPhoneNumber(sale?.customer?.phone || "") ||
      `*** DADO NÃO CADASTRADO: {telefone_cliente} ***`,
    whatsapp_cliente:
      formatPhoneNumber(sale?.customer?.whatsapp || "") ||
      `*** DADO NÃO CADASTRADO: {whatsapp_cliente} ***`,
    documento_cliente:
      sale?.customer?.document ||
      `*** DADO NÃO CADASTRADO: {documento_cliente} ***`,
    endereco_cliente:
      sale?.customer?.address ||
      `*** DADO NÃO CADASTRADO: {endereco_cliente} ***`,
    rg_cliente:
      sale?.customer?.rg || `*** DADO NÃO CADASTRADO: {rg_cliente} ***`,
    nac_cliente:
      sale?.customer?.nationality ||
      `*** DADO NÃO CADASTRADO: {nac_cliente} ***`,
    prof_cliente:
      sale?.customer?.profession ||
      `*** DADO NÃO CADASTRADO: {prof_cliente} ***`,
    nome_resp_cliente:
      sale?.customer?.personInCharge?.name ||
      `*** DADO NÃO CADASTRADO: {nome_resp_cliente} ***`,
    cpf_resp_cliente:
      sale?.customer?.personInCharge?.cpf ||
      `*** DADO NÃO CADASTRADO: {cpf_resp_cliente} ***`,
    observacoes_cliente:
      sale?.customer?.observations ||
      `*** DADO NÃO CADASTRADO: {observacoes_cliente} ***`,
    nome_empresa: user?.name || `*** DADO NÃO CADASTRADO: {nome_empresa} ***`,
    cnpj_empresa: user?.cnpj || `*** DADO NÃO CADASTRADO: {cnpj_empresa} ***`,
    endereco_empresa:
      user?.address || `*** DADO NÃO CADASTRADO: {endereco_empresa} ***`,
    telefone_empresa:
      formatPhoneNumber(user?.phone || "") ||
      `*** DADO NÃO CADASTRADO: {telefone_empresa} ***`,
    whatsapp_empresa:
      formatPhoneNumber(user?.whatsapp || "") ||
      `*** DADO NÃO CADASTRADO: {whatsapp_empresa} ***`,
    nome_resp_empresa:
      user?.personInCharge?.name ||
      `*** DADO NÃO CADASTRADO: {nome_resp_empresa} ***`,
    cpf_resp_empresa:
      user?.personInCharge?.cpf ||
      `*** DADO NÃO CADASTRADO: {cpf_resp_empresa} ***`,
    rg_resp_empresa:
      user?.personInCharge?.rg ||
      `*** DADO NÃO CADASTRADO: {rg_resp_empresa} ***`,
    prof_resp_empresa:
      user?.personInCharge?.profession ||
      `*** DADO NÃO CADASTRADO: {prof_resp_empresa} ***`,
    nac_resp_empresa:
      user?.personInCharge?.nationality ||
      `*** DADO NÃO CADASTRADO: {nac_resp_empresa} ***`,
    valor_venda:
      `R$ ${addThousandSeparator(sale?.amount || 0)}` ||
      `*** DADO NÃO CADASTRADO: {valor_venda} ***`,
    potencia_sistema:
      `${addThousandSeparator(proposal?.realSystemData?.power || 0)} kWp` ||
      `*** DADO NÃO CADASTRADO: {potencia_sistema} ***`,
    condicoes_de_pagamento:
      proposal?.revenueData?.paymentConditions ||
      `*** DADO NÃO CADASTRADO: {condicoes_de_pagamento} ***`,
    validade_proposta:
      proposal?.proposalCustomizationData?.validityDate ||
      `*** DADO NÃO CADASTRADO: {validade_proposta} ***`,
    n_contrato: `#${contractNumber?.toLocaleString("pt-BR", {
      minimumIntegerDigits: 5,
      useGrouping: false,
    })}`,
    data_do_dia: new Date().toLocaleDateString("pt-BR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }),
    modulos: [],
    inversores: [],
    adicionais: [],
    numero_da_proposta: `#${proposal.count?.toLocaleString("pt-BR", {
      minimumIntegerDigits: 5,
      useGrouping: false,
    })}`,
    cs_m:
      addThousandSeparator(
        proposal.estimateSystemData.averageMontlhyConsumption
      ) || "",
    cs_m_fp:
      addThousandSeparator(
        proposal.estimateSystemData.averageMontlhyConsumption
      ) || "",
    cs_m_p:
      addThousandSeparator(
        Math.round(
          proposal.estimateSystemData.averageYearlyConsumptionPeak / 12
        )
      ) || "",
    cs_a: addThousandSeparator(sum(proposal.totalMonthlyConsumption)) || "",
    cs_a_fp: addThousandSeparator(sum(proposal.totalMonthlyConsumption)) || "",
    cs_a_p:
      addThousandSeparator(
        proposal.estimateSystemData.averageYearlyConsumptionPeak
      ) || "",
    ger_m:
      addThousandSeparator(proposal.realSystemData.averageMontlhyGeneration) ||
      "",
    ger_a: addThousandSeparator(
      proposal.realSystemData.averageYearlyGeneration
    ),
    co2: addThousandSeparator(
      proposal.realSystemData.averageYearlyGeneration * 0.28
    ),
    arvores: addThousandSeparator(
      proposal.realSystemData.averageMontlhyGeneration * 0.33
    ),
    carros: addThousandSeparator(
      proposal.realSystemData.averageMontlhyGeneration * 0.02
    ),
    ucs_dist: [],
    potencia: addThousandSeparator(proposal.realSystemData.power) || "",
    potencia_calc:
      addThousandSeparator(proposal.estimateSystemData.idealPower) || "",
    area: addThousandSeparator(proposal.realSystemData.minArea) || "",
    peso_dist:
      addThousandSeparator(proposal.realSystemData.distributedWeight) || "",
    irr: addThousandSeparator(proposal.sundata.irrAvg) || "",
    cust_tot_ss:
      addThousandSeparator(
        sum(proposal.revenueData.finantialViability.firstYearNoSystem) / 12
      ) || "",
    cust_c_fp_ss:
      addThousandSeparator(
        Math.round(proposal.revenueData.finantialViability.nonPeakMonthyCost)
      ) || "",
    cust_d_fp_ss:
      addThousandSeparator(
        Math.round(proposal.revenueData.finantialViability.nonPeakDemandCost)
      ) || "",
    cust_c_p_ss:
      addThousandSeparator(
        Math.round(proposal.revenueData.finantialViability.peakMonthyCost)
      ) || "",
    cust_d_p_ss:
      addThousandSeparator(
        Math.round(proposal.revenueData.finantialViability.peakDemandCost)
      ) || "",
    cust_d_ger_ss:
      addThousandSeparator(
        Math.round(proposal.revenueData.finantialViability.genDemandCost || 0)
      ) || "0",
    cust_tot_cs:
      addThousandSeparator(
        proposal.revenueData.finantialViability.firstYearWithSystem
      ) || "",
    cust_c_fp_abt:
      addThousandSeparator(
        Math.round(proposal.revenueData.finantialViability.nonPeakMonthyEconomy)
      ) || "",
    cust_c_p_abt:
      addThousandSeparator(
        Math.round(proposal.revenueData.finantialViability.peakMonthyEconomy)
      ) || "",
    cust_a1_ss:
      addThousandSeparator(
        sum(proposal.revenueData.finantialViability.firstYearNoSystem)
      ) || "",
    cust_a1_cs:
      addThousandSeparator(
        proposal.revenueData.finantialViability.firstYearWithSystem * 12
      ) || "",
    ecn_a1_am:
      addThousandSeparator(
        (sum(proposal.revenueData.finantialViability.firstYearNoSystem) -
          proposal.revenueData.finantialViability.firstYearWithSystem * 12) /
          12
      ) || "",
    ecn_a1_aa:
      addThousandSeparator(
        sum(proposal.revenueData.finantialViability.firstYearNoSystem) -
          proposal.revenueData.finantialViability.firstYearWithSystem * 12
      ) || "",
    preco_sist: addThousandSeparator(proposal.revenueData.finalPrice) || "",
    cond_pag: proposal.revenueData?.paymentConditions || "",
    fornecedor: proposal.realSystemData?.supplier || "",
    est_fix: proposal.realSystemData?.kitFixation || "",
    preco_servico:
      addThousandSeparator(
        proposal.revenueData.totalSvc || proposal.revenueData.liquidServices
      ) || "",
    preco_equipamentos:
      addThousandSeparator(
        proposal.revenueData.totalEqp ||
          proposal.revenueData.pvKitOriginalCost ||
          proposal.revenueData.pvKitCost
      ) || "",
    infl_en: proposal.revenueData.inflation || "",
    payback_aa: proposal.revenueData.finantialViability.paybackYears || "",
    payback_mm: proposal.revenueData.finantialViability.paybackMonths || "",
    payback_comp_aa:
      proposal.revenueData.oldFinantialViability.paybackYears || "",
    payback_comp_mm:
      proposal.revenueData.oldFinantialViability.paybackYears || "",
    roi:
      addThousandSeparator(proposal.revenueData.finantialViability.roi) || "",
    roi_comp:
      addThousandSeparator(proposal.revenueData.oldFinantialViability.roi) ||
      "",
    tir:
      addThousandSeparator(proposal?.revenueData.finantialViability?.tir) || "",
    vl_sist_fv:
      addThousandSeparator(proposal?.revenueData.finantialViability.lcoe) || "",
    vl_ecn_sist_fv:
      addThousandSeparator(
        avgtarif - proposal?.revenueData.finantialViability.lcoe
      ) || "",
    ecn_tot:
      addThousandSeparator(
        proposal.revenueData.finantialViability.totalDiscountedEconomy
      ) || "",
    ecn_tot_comp: addThousandSeparator(
      proposal.revenueData.oldFinantialViability.totalDiscountedEconomy
    ),
    fin: [],
    fin_viab: [],
    data_validade: proposal?.proposalCustomizationData?.validityDate || "",
    dias_validade:
      getDaysFromDate(
        proposal?.proposalCustomizationData?.validityDate || ""
      ) || "",
    cons_finais_t: "",
    cons_finais_p1: "",
    cons_finais_p2: "",
    cons_finais_p3: "",
    cons_finais_p4: "",
    cons_finais_p5: "",
    cons_finais_p6: "",
    data_registro: proposal?.registryDate?.substring(0, 10) || "",
    proj_conta: [],
    viab_d: [],
    sobre_eqps: proposal?.aboutTheEquipament || "",
  };

  proposal.energyBillData.forEach((uc, i) => {
    const perc =
      !uc?.classification || !uc?.monthlyConsumptionPeak?.length
        ? uc?.monthlyConsumption?.reduce((a, b) => {
            return a + b;
          }) / proposal?.estimateSystemData?.averageYearlyConsumption
        : (uc?.monthlyConsumption?.reduce((a, b) => {
            return a + b;
          }) +
            uc?.monthlyConsumptionPeak?.reduce((a, b) => {
              return a + b;
            })) /
          (proposal?.estimateSystemData?.averageYearlyConsumption +
            proposal?.estimateSystemData?.averageYearlyConsumptionPeak);

    const energyPerc = perc * proposal.realSystemData.averageMontlhyGeneration;

    tagsValues.ucs_dist?.push({
      nome_uc: `${uc.ucName?.trim() || `Unidade Consumidora ${i + 1}`} ${
        i === 0 ? "(Gerador)" : ""
      }`,
      ger_uc: addThousandSeparator(energyPerc) || "",
      ger_perc_uc: addThousandSeparator(perc * 100),
      c_ss_uc: !!uc?.finViabilityData?.noSystemMonthlyBill
        ? addThousandSeparator(uc?.finViabilityData?.noSystemMonthlyBill)
        : "-",
      c_cs_uc: !!uc?.finViabilityData?.systemMonthlyBill
        ? addThousandSeparator(uc?.finViabilityData?.systemMonthlyBill)
        : "-",
      ec_uc: !!(
        uc?.finViabilityData?.noSystemMonthlyBill -
        uc?.finViabilityData?.systemMonthlyBill
      )
        ? addThousandSeparator(
            uc?.finViabilityData?.noSystemMonthlyBill -
              uc?.finViabilityData?.systemMonthlyBill
          )
        : "-",
    });
  });

  proposal.pvModuleData?.forEach((mod) => {
    tagsValues.modulos?.push({
      mod_fab: mod.manufacturer || "",
      mod_pot: addThousandSeparator(mod.power || 0, false, false) || "",
      mod_gar_def: mod.warrantyDefect || "",
      mod_gar_ef: mod.warrantyEfficiency || "",
      mod_qtd: mod.quantity || "",
    });
  });

  proposal.inverterData?.forEach((inv) => {
    tagsValues.inversores?.push({
      inv_mod: inv.iModel || "",
      inv_fab: inv.iManufacturer || "",
      inv_pot:
        inv.iPower > 99
          ? addThousandSeparator(inv.iPower, false, false)
          : addThousandSeparator(inv.iPower * 1000, false, false) || "",
      inv_gar: inv.iWarranty || "",
      inv_monit: inv.iMonitoring || "Wi-Fi",
      inv_qtd: inv.iQuantity || "",
    });
  });

  proposal.additionalItems?.forEach((addItem) => {
    tagsValues.adicionais?.push({
      item_adc_nome: addItem.item || "",
      item_adc_fab: addItem.manufacter || "",
      item_adc_qtd: addItem.quantity || "",
      item_adc_det: addItem.detail || "",
    });
  });

  return tagsValues;
};

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { teal } from "@material-ui/core/colors";

export const ButtonTealContained = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: teal[700],
    "&:hover": {
      backgroundColor: teal[800],
    },
  },
}))(Button);

export const ButtonTealLightContained = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: teal[500],
    "&:hover": {
      backgroundColor: teal[700],
    },
  },
}))(Button);

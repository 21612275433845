import { CashFlowData, ProposalData } from "../../shared/data/types";

export const getFinancingCashFlow = (
  cashFlow: CashFlowData[],
  systemPrice: number,
  option: ProposalData["revenueData"]["financing"][0]["options"][0],
  entry: number
) => {
  let yearlyEconomy: number[] = [];
  yearlyEconomy[0] = systemPrice - Math.abs(cashFlow?.[0]?.flow);
  for (let i = 1; i < 25; i++) {
    if (
      (cashFlow?.[i - 1]?.flow < 0 && cashFlow?.[i]?.flow < 0) ||
      (cashFlow?.[i - 1]?.flow > 0 && cashFlow?.[i]?.flow > 0)
    ) {
      yearlyEconomy.push(
        Math.abs(cashFlow?.[i]?.flow - cashFlow?.[i - 1]?.flow)
      );
    } else {
      yearlyEconomy.push(
        Math.abs(cashFlow?.[i - 1]?.flow) + Math.abs(cashFlow?.[i]?.flow)
      );
    }
  }
  let yearlyPaymentIntallments: number[] = [];

  const totalPrice = option.installments * option.value + entry;
  const intYearlyInstallments = Math.floor(option.installments / 12);
  const remainderMonths = option.installments % 12;
  const loopTotal = intYearlyInstallments + (remainderMonths !== 0 ? 1 : 0);
  for (let i = 0; i < loopTotal; i++) {
    if (remainderMonths > 0) {
      if (i < loopTotal - 1) {
        yearlyPaymentIntallments.push(12 * option.value);
      } else {
        yearlyPaymentIntallments.push(remainderMonths * option.value);
      }
    } else {
      yearlyPaymentIntallments.push(12 * option.value);
    }
  }
  yearlyPaymentIntallments[0] += entry;
  let finCashFlow: number[] = [];
  let paybackCashFlow: number[] = [];
  let breakEvenYears = 0;
  let breakEvenMonths = 0;
  let paybackYears = 0;
  let paybackMonths = 0;
  finCashFlow[0] = -yearlyPaymentIntallments[0] + yearlyEconomy[0];
  paybackCashFlow[0] = -totalPrice + yearlyEconomy[0];

  for (let i = 1; i < 25; i++) {
    paybackCashFlow[i] = paybackCashFlow[i - 1] + yearlyEconomy[i];
    if (yearlyPaymentIntallments?.[i] > 0) {
      finCashFlow[i] =
        -yearlyPaymentIntallments[i] + finCashFlow[i - 1] + yearlyEconomy[i];
    } else {
      finCashFlow[i] = finCashFlow[i - 1] + yearlyEconomy[i];
    }
    if (paybackCashFlow[i - 1] < 0 && paybackCashFlow[i] > 0) {
      paybackYears = i;
      let interval =
        (Math.abs(paybackCashFlow[i - 1]) + paybackCashFlow[i]) / 12;
      let startValue = paybackCashFlow[i - 1];
      // Determining payback months
      while (startValue < 0) {
        startValue = startValue + interval;
        startValue < 0 && paybackMonths++;
      }
    } else if (paybackCashFlow[0] > 0 && i === 1) {
      // Payback for less than 1 year
      paybackYears = 0;
      let interval = (Math.abs(systemPrice) + paybackCashFlow[0]) / 12;
      let startValue = -systemPrice;
      while (startValue < 0) {
        startValue = startValue + interval;
        startValue < 0 && paybackMonths++;
      }
    }
    if (finCashFlow[i - 1] < 0 && finCashFlow[i] > 0) {
      breakEvenYears = i;
      let interval = (Math.abs(finCashFlow[i - 1]) + finCashFlow[i]) / 12;
      let startValue = finCashFlow[i - 1];
      // Determining payback months
      while (startValue < 0) {
        startValue = startValue + interval;
        startValue < 0 && breakEvenMonths++;
      }
    } else if (finCashFlow[0] > 0 && i === 1) {
      // Payback for less than 1 year
      breakEvenYears = 0;
      let interval =
        (Math.abs(yearlyPaymentIntallments[0]) + finCashFlow[0]) / 12;
      let startValue = -yearlyPaymentIntallments[0];
      while (startValue < 0) {
        startValue = startValue + interval;
        startValue < 0 && breakEvenMonths++;
      }
    }
  }
  const totalEconomy = finCashFlow[24];
  const roi = totalEconomy / totalPrice;
  const processedFinCashFlow = finCashFlow.map((data, i) => {
    return { year: `${i + 1}`, flow: data };
  });
  const processedCashFlowMobile = processedFinCashFlow.filter((data, i) => {
    return i === 0 || i % 2 !== 0;
  });

  return {
    processedFinCashFlow,
    processedCashFlowMobile,
    totalPrice,
    breakEvenYears,
    breakEvenMonths,
    paybackYears,
    paybackMonths,
    totalEconomy,
    roi,
  };
};

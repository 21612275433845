import Popover from "@material-ui/core/Popover";
import React, { Fragment, useContext } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";

import { ModalError } from "../../shared/components/UIElements/ModalError";
import { FormAddFinToProposal } from "../../financings/components/FormAddFinToProposal";
import { InputSelectWithId } from "../../shared/components/FormElements/InputSelectWithId";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { useForm } from "../../shared/hooks/formHook";
import { SettingsContext } from "../../shared/context/settingsContext";
import { addPreApprovedFinToProposal } from "../api/financingsAPI";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { AuthContextProps, FinancingData } from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface PopoverAddFinToProposalProps {
  anchorEl: HTMLButtonElement | HTMLLIElement | HTMLDivElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement | HTMLDivElement>
  >;
  auth: AuthContextProps;
  financing: FinancingData;
  uniqueFinProps: FinancingData["proposals"];
  setLoadedFinancings: React.Dispatch<React.SetStateAction<FinancingData[]>>;
}

export const PopoverAddFinToProposal = (
  props: PopoverAddFinToProposalProps
) => {
  const {
    anchorEl,
    setAnchorEl,
    auth,
    financing,
    uniqueFinProps,
    setLoadedFinancings,
  } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const settingsCtx = useContext(SettingsContext);
  const { formState, inputHandler, reportInvalid } = useForm({}, false);
  const history = useHistory();

  const options = uniqueFinProps?.map((p, i) => {
    return {
      txt: `${addThousandSeparator(p?.fee * 100)}% - x${
        p?.installments
      } de R$ ${addThousandSeparator(p?.installmentValue)} - ${
        p?.gracePeriod
      } ${p?.gracePeriod === 1 ? "Mês" : "Meses"}`,
      id: p?._id,
    };
  });

  const selOpts = [
    formState?.inputs?.option1?.value?.toString(),
    formState?.inputs?.option2?.value?.toString(),
    formState?.inputs?.option3?.value?.toString(),
    formState?.inputs?.option4?.value?.toString(),
  ];

  const hasSelOpt = !!selOpts?.find((o) => !!o);

  const addFinToPropHandler = () => {
    addPreApprovedFinToProposal({
      sendRequest,
      auth,
      fid: financing?._id,
      options: selOpts?.filter((o) => !!o),
      setLoadedFinancings,
    })
      .then(() => {
        setAnchorEl(null);

        if (
          !!settingsCtx?.proposalSlug &&
          typeof financing?.proposal?.count === "number" &&
          financing?.proposal?.count > 0
        ) {
          history.push(
            `/proposta/final/${settingsCtx.proposalSlug}/${
              financing?.proposal?.count
            }/${financing?.proposal?.frontId?.substring(0, 5)}`
          );
        } else {
          history.push(`/proposta/final/${financing?.proposal?.frontId}`);
        }
      })
      .catch(() => {
        setAnchorEl(null);
      });
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        style={{ zIndex: 501 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <FormAddFinToProposal
          auth={auth}
          financing={financing}
          setLoadedData={setLoadedFinancings}
          sendRequest={sendRequest}
          isLoading={isLoading}
          uniqueFinProps={uniqueFinProps}
          isPopover
        />
      </Popover>
    </Fragment>
  );
};

import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import SystemUpdateAltRoundedIcon from "@material-ui/icons/SystemUpdateAltRounded";

import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { deleteSaleContract } from "../../contract/api/salesAPI";
import { AuthContextProps, KanbanFilledData } from "../../shared/data/types";

interface MenuSaleContractOptionsProps {
  auth: AuthContextProps;
  anchorEl: HTMLButtonElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement>>;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  saleId: string;
  loadedContract: KanbanFilledData["items"][0]["contracts"][0];
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const MenuSaleContractOptions = (
  props: MenuSaleContractOptionsProps
) => {
  const {
    auth,
    saleId,
    anchorEl,
    setAnchorEl,
    sendRequest,
    loadedContract,
    setKanbanData,
  } = props;
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const confirmHandler = async () => {
    deleteSaleContract({
      sendRequest,
      auth,
      saleId: saleId,
      saleContractId: loadedContract._id,
      setKanbanData,
    });
    setShowConfirmModal(false);
    setAnchorEl(null);
  };

  const confirmModalCloseHandler = () => {
    setShowConfirmModal(false);
  };

  return (
    <div>
      <ModalConfirm
        open={showConfirmModal}
        closeHandler={confirmModalCloseHandler}
        confirmHandler={confirmHandler}
        message="Deseja excluir permanentemente este contrato?"
      />
      <Menu
        style={{ zIndex: 10001 }}
        id="opcoes-item-funil"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={(e) => {
            window.open(
              `${process.env.REACT_APP_ASSET_URL}/${loadedContract.fileUrl}`
            );
            e.stopPropagation();
          }}
        >
          <SystemUpdateAltRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
          Download (s/ assinatura)
        </MenuItem>
        {auth.type === "MANAGER" && (
          <MenuItem
            onClick={() => {
              setShowConfirmModal(true);
            }}
          >
            <DeleteForeverRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
            Excluir Contrato
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

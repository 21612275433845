import { useReducer, useEffect } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { SwitchCustomAlwaysBlue } from "../MUIThemed/SwitchCustomAlwaysBlue";
import { InputDispState } from "./Input";
import { InputDispActionBoolean } from "./InputSwitch";

const inputReducer = (
  state: InputDispState,
  action: InputDispActionBoolean
) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: true,
      };
    default:
      return state;
  }
};

interface InputSwitchProps {
  id: string;
  label: string;
  onInput: (
    id: string,
    value: boolean,
    isValid: boolean,
    label: string
  ) => void;
  title: string;
  initialValue?: boolean;
  reinitialize?: boolean;
  color: "primary" | "secondary" | "default";
}

export const InputSwitchCustom = (props: InputSwitchProps) => {
  const { id, label, onInput, title, initialValue, reinitialize, color } =
    props;
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || false,
    isTouched: false,
    isValid: true,
  });

  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(
      id,
      value as boolean,
      isValid,
      label.charAt(0).toUpperCase() + label.slice(1)
    );
  }, [id, value, isValid, label, onInput]);

  useEffect(() => {
    if (reinitialize) {
      dispatch({
        type: "CHANGE",
        val: initialValue,
      });
    }
  }, [reinitialize, initialValue]);

  const changeHandler = () => {
    dispatch({
      type: "CHANGE",
      val: !value,
    });
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <SwitchCustomAlwaysBlue
            title={title}
            checked={value as boolean}
            color={color}
            id={id}
            onChange={changeHandler}
            name={id}
          />
        }
        label={label.charAt(0).toUpperCase() + label.slice(1)}
      />
    </FormGroup>
  );
};

import { useHistory } from "react-router-dom";

import { useHttpClient } from "../../../shared/hooks/httpHook";
import { LoadingSpinnerOverlayRegular } from "../../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { addThousandSeparator } from "../../../shared/util/addThousandSeparator";
import { generateFormattedDateFromTimestamp } from "../../../shared/util/generateFormattedDateFromTimestamp";
import { BTG_STATUS_MAP } from "../../../shared/data/static";
import { getFinancingStage } from "../../../financings/util/getFinancingStage";
import { AuthContextProps, FinancingData } from "../../../shared/data/types";

import "./HomeDashboard.scss";

interface HomeDashboardFinancingItemProps {
  auth: AuthContextProps;
  financing: FinancingData;
  setLoadedData: React.Dispatch<React.SetStateAction<FinancingData[]>>;
}

export const HomeDashboardFinancingItem = (
  props: HomeDashboardFinancingItemProps
) => {
  const { auth, financing, setLoadedData } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const history = useHistory();

  const { foundStatus, approvedAllDocs, hasChosenProp } =
    getFinancingStage(financing);

  return (
    <div className="dashboard-item__list-item-container">
      {isLoading && <LoadingSpinnerOverlayRegular size={25} />}
      <div
        onClick={() => {
          history.push("/financiamentos");
        }}
        className="dashboard-item__list-item dashboard-item__list-item--5-col-fin"
      >
        <p className="dashboard-item__list-item-name">
          {financing?.customer?.name}
        </p>
        <p className="dashboard-item__list-item-amount">
          R$ {addThousandSeparator(financing?.proposals?.[0]?.totalFinanced)}
        </p>
        <p className="dashboard-item__list-item-name dashboard-item__list-item-name--vanish">
          {`#${
            financing?.proposal?.count?.toLocaleString("pt-BR", {
              minimumIntegerDigits: 5,
              useGrouping: false,
            }) || "000000"
          } - ${addThousandSeparator(
            financing?.proposal?.realSystemData?.power || 0
          )} kWp - R$ ${addThousandSeparator(
            financing?.proposal?.revenueData?.finalPrice || 0
          )}`}
        </p>
        <span
          title={foundStatus?.txt?.toUpperCase()}
          className={`dashboard-item__list-item-status dashboard-item__list-item-status--fin-${foundStatus?.color}`}
        >
          {foundStatus?.txt?.toUpperCase()}
        </span>
        <p className="dashboard-item__list-item-date-fin">
          {generateFormattedDateFromTimestamp(financing?.lastUpdatedTimestamp)}
        </p>
      </div>
    </div>
  );
};

import { useState, Fragment, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import { ModalZeroResults } from "../../shared/components/UIElements/ModalZeroResults";
import { ButtonLoadMore } from "../../shared/components/UIElements/ButtonLoadMore";
import { SaleDashNotesFilters } from "./SaleDashNotesFilters";
import { SaleDashNoteItem } from "./SaleDashNoteItem";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { PopoverFormAddOrEditNote } from "./PopoverFormAddOrEditNote";
import { getSaleTypeClientData } from "../util/getSaleTypeClientData";
import { useHttpClient } from "../../shared/hooks/httpHook";
import {
  getFilteredSaleNotes,
  getPaginatedSaleNotes,
} from "../api/salesFunnelAPI";
import {
  AuthContextProps,
  NoteData,
  KanbanFilledData,
  NotePopCustData,
  SettingsContextProps,
  TimePeriodData,
} from "../../shared/data/types";

import "./SaleDash.scss";

interface SaleDashNotesProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  itemId: string;
  customerId: string;
  activeNote: string;
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  setScheduleData?: React.Dispatch<React.SetStateAction<NotePopCustData[]>>;
}

export const SaleDashNotes = (props: SaleDashNotesProps) => {
  const {
    settingsCtx,
    auth,
    itemId,
    customerId,
    type,
    activeNote,
    setScheduleData,
  } = props;
  const {
    error,
    sendRequest,
    clearError,
    isLoading,
    zeroResults,
    clearZeroResults,
  } = useHttpClient();
  const [loadedNotes, setLoadedNotes] = useState<NotePopCustData[]>(null);
  const [anchorElForm, setAnchorElForm] = useState<null | HTMLButtonElement>(
    null
  );
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [multiplier, setMultiplier] = useState<number>(0);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<boolean>(false);
  const [noteType, setNoteType] = useState<string>("TODOS");
  const [noteCalendar, setNoteCalendar] = useState<
    "TODAS AS NOTAS" | "NÃO AGENDADAS" | "AGENDADAS"
  >("TODAS AS NOTAS");
  const [filter1Active, setFilter1Active] = useState<boolean>(false);
  const [filter1Value, setFilter1Value] = useState<TimePeriodData>({
    timePeriodEarlierSelected: new Date().toISOString(),
    timePeriodOlderSelected: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 30
    ).toISOString(),
  });
  const { typeName } = getSaleTypeClientData(type, auth.userId);

  useEffect(() => {
    setLoadedNotes(null);

    if (!!itemId) {
      getPaginatedSaleNotes({
        saleId: itemId,
        sendRequest,
        auth,
        hidration: true,
        setLoadedData: setLoadedNotes,
        setShowLoadMore,
        multiplier: 0,
        setFirstLoad,
      });
    }

    setMultiplier(0);
    setShowLoadMore(false);
    setFiltered(false);
  }, [itemId]);

  const getPaginatedSaleNotesHandler = async (mult: number) => {
    await getPaginatedSaleNotes({
      saleId: itemId,
      sendRequest,
      auth,
      hidration: false,
      setLoadedData: setLoadedNotes,
      setShowLoadMore,
      multiplier: mult,
    });
  };

  const getFilteredSaleNotesHandler = async (mult: number) => {
    await getFilteredSaleNotes({
      saleId: itemId,
      sendRequest,
      auth,
      setLoadedData: setLoadedNotes,
      setShowLoadMore,
      setFiltered,
      multiplier: mult,
      noteType,
      noteCalendar,
      filter1Active,
      filter1Value,
      setMultiplier,
      type,
    });
  };

  return (
    <Fragment>
      <PopoverFormAddOrEditNote
        id={itemId}
        anchorEl={anchorElForm}
        setAnchorEl={setAnchorElForm}
        auth={auth}
        sendRequest={sendRequest}
        setLoadedData={setLoadedNotes}
        setScheduleData={setScheduleData}
        apiCall="create"
        anchorLeft={loadedNotes?.length > 0}
      />
      <ModalZeroResults
        show={zeroResults}
        closePopupHandler={clearZeroResults}
      />
      <ModalError error={error} onClear={clearError} />
      {!loadedNotes && (
        <div className="sale-dash__col-right-content-spinner">
          <CircularProgress color="primary" />
        </div>
      )}
      {loadedNotes && loadedNotes?.length === 0 && (
        <p className="sale-dash__col-right-content-empty">
          {`${
            type === "PROJECT" ? "Este" : "Esta"
          } ${typeName?.toLowerCase()} não possui notas`}
        </p>
      )}
      <div className="sale-dash__history">
        {loadedNotes?.length > 0 && (
          <div className="sale-dash__filters-container">
            <SaleDashNotesFilters
              saleId={itemId}
              settingsCtx={settingsCtx}
              auth={auth}
              isLoading={isLoading}
              setLoadedData={setLoadedNotes}
              setShowLoadMore={setShowLoadMore}
              setFiltered={setFiltered}
              setMultiplier={setMultiplier}
              sendRequest={sendRequest}
              noteType={noteType}
              setNoteType={setNoteType}
              noteCalendar={noteCalendar}
              setNoteCalendar={setNoteCalendar}
              filter1Active={filter1Active}
              setFilter1Active={setFilter1Active}
              filter1Value={filter1Value}
              setFilter1Value={setFilter1Value}
              firstLoad={firstLoad}
              type={type}
            />
            <IconButton
              title="Adicionar nota"
              onClick={(e) => {
                setAnchorElForm(e.currentTarget);
              }}
            >
              <AddRoundedIcon style={{ color: "#043a50" }} />
            </IconButton>
          </div>
        )}
        {loadedNotes?.length > 0 &&
          loadedNotes
            ?.sort((a, b) => {
              return (b?.registryTimestamp || 0) - (a?.registryTimestamp || 0);
            })
            ?.sort((a, b) => {
              return a?._id === activeNote ? -1 : b?._id === activeNote ? 1 : 0;
            })
            ?.map((item, i) => {
              return (
                <SaleDashNoteItem
                  auth={auth}
                  saleId={itemId}
                  customerId={customerId}
                  setLoadedData={setLoadedNotes}
                  setScheduleData={setScheduleData}
                  activeNote={activeNote}
                  loadedNote={item}
                  key={`note-${i}`}
                />
              );
            })}
        {!isLoading && <div className="sale-dash__add-btn-fixed"></div>}
      </div>
      {loadedNotes && loadedNotes?.length === 0 && (
        <div className="sale-dash__col-right-add-item-btn">
          {!isLoading && (
            <IconButton
              title="Adicionar nota"
              onClick={(e) => {
                setAnchorElForm(e.currentTarget);
              }}
            >
              <AddRoundedIcon style={{ color: "#043a50" }} />
            </IconButton>
          )}
          {isLoading && <CircularProgress color="primary" />}
        </div>
      )}
      <div className="sale-dash__col-right-content-load-more">
        <ButtonLoadMore
          setMultiplier={setMultiplier}
          showLoadMore={showLoadMore}
          isLoading={isLoading}
          fetchItems={
            !filtered
              ? getPaginatedSaleNotesHandler
              : getFilteredSaleNotesHandler
          }
          noMargin
        />
      </div>
    </Fragment>
  );
};

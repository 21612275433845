import { AuthContextProps, SettingsContextProps } from "../../../shared/data/types";
import { HomeDashboardFinancings } from "./HomeDashboardFinancings";
import "./HomeDashboardTabFinancing.scss";

interface HomeDashboardTabFinancingProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  fetchId: string;
}

export const HomeDashboardTabFinancing = (props: HomeDashboardTabFinancingProps) => {
  const {settingsCtx, auth, fetchId} = props;
  return auth.whitelabel !== "SERRANA" && (
    <HomeDashboardFinancings
      settingsCtx={settingsCtx}
      auth={auth}
      fetchId={fetchId}
    />
  )
}
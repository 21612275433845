import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { orange } from "@material-ui/core/colors";

export const ButtonOrangeDefault = withStyles((theme) => ({
  root: {
    color: orange[700],
    "&:hover": {
      backgroundColor: orange[50],
    },
  },
}))(Button);

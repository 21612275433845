import { Fragment, useEffect, useState } from "react";
import PeopleRoundedIcon from "@material-ui/icons/PeopleRounded";
import ViewListRoundedIcon from "@material-ui/icons/ViewListRounded";
import Button from "@material-ui/core/Button";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useHttpClient } from "../../../shared/hooks/httpHook";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { PopoverSelectUser } from "./PopoverSelectUser";
import { getCustsAndPropsTotals } from "../../api/analyticsAPI";
import {
  AuthContextProps,
  FormHookDispState,
  SettingsContextProps,
} from "../../../shared/data/types";

import "./HomeDashboard.scss";

interface HomeDashboardUserProps {
  inputHandler: (
    id: string,
    value: string | number | boolean | string[] | File,
    isValid: boolean,
    label: string
  ) => void;
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  formState: FormHookDispState;
  fetchId: string;
}

export const HomeDashboardUser = (props: HomeDashboardUserProps) => {
  const { inputHandler, settingsCtx, auth, formState, fetchId } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const [loadedData, setLoadedData] = useState<{
    totalCustomers: number;
    totalProposals: number;
  }>(null);
  const isSalesManager =
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip?.user?.toString() === auth?.userId;
    })?.salesManager || false;
  const [selectedOpt, setSelectedOpt] = useState<{
    txt: string;
    id: string;
  }>(
    auth.type !== "VENDOR" || isSalesManager
      ? { txt: "EMPRESA", id: auth?.managerId }
      : auth.vendors
          ?.filter((item) => {
            return item._id === auth.userId;
          })
          ?.map((item) => {
            return { txt: item?.personalData?.name, id: item?._id };
          })?.[0]
  );

  const userTeamsMembers =
    settingsCtx.teams
      ?.filter((t) => {
        return t.leader === auth.userId && t?.type === "SALES";
      })
      ?.map((t) => {
        return t.members;
      })
      ?.flat(1) || [];

  const usersOpts =
    auth.type !== "VENDOR" || isSalesManager
      ? auth.type === "MANAGER" || isSalesManager
        ? [
            { txt: "EMPRESA", id: auth?.managerId },
            { txt: "GERENTE", id: auth?.managerUserId },
            ...(auth.vendors
              ?.filter((item) => {
                return settingsCtx.individualPermissions
                  ?.filter((ip) => {
                    return ip.salesKanban;
                  })
                  ?.map((ip) => {
                    return ip.user;
                  })
                  ?.includes(item._id);
              })
              ?.map((item) => {
                return { txt: item?.personalData?.name, id: item?._id };
              }) || []),
          ]
        : [{ txt: "EMPRESA", id: auth?.managerId }]
      : [
          ...(auth.vendors
            ?.filter((item) => {
              return (
                item._id === auth.userId || userTeamsMembers?.includes(item._id)
              );
            })
            ?.map((item) => {
              return { txt: item?.personalData?.name, id: item?._id };
            }) || []),
        ];

  useEffect(() => {
    if (!!formState?.inputs?.user?.value) {
      const foundOpt = usersOpts.find((opt) => {
        return opt.id === formState?.inputs?.user?.value;
      });

      if (foundOpt) {
        setSelectedOpt(foundOpt);
      } else {
        const foundTeam = settingsCtx?.teams?.find(
          (t) => t._id === formState?.inputs?.user?.value
        );

        if (foundTeam) {
          setSelectedOpt({ txt: foundTeam.name, id: foundTeam.id });
        }
      }
    }
  }, [formState?.inputs?.user?.value]);

  useEffect(() => {
    if (!!auth.token) {
      getCustsAndPropsTotals({
        sendRequest,
        auth,
        fetchId,
        setLoadedData,
        settingsCtx,
      });
    }
  }, [fetchId, auth?.token]);

  return (
    <div className="dashboard-item dashboard-item--user">
      <PopoverSelectUser
        auth={auth}
        inputHandler={inputHandler}
        usersOpts={usersOpts}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        formState={formState}
        isLoading={isLoading}
        settingsCtx={settingsCtx}
      />
      {/* <ModalError error={error} onClear={clearError} /> */}
      <div className="dashboard-item__avatar">
        <img
          className="dashboard-item__avatar-img"
          src={
            selectedOpt?.id === auth.managerId
              ? `${process.env.PUBLIC_URL}/avatar-enterprise.png`
              : selectedOpt?.id === auth.managerUserId
              ? `${process.env.PUBLIC_URL}/avatar-manager.png`
              : settingsCtx?.teams
                  ?.map((t) => t?._id)
                  ?.includes(selectedOpt?.id)
              ? `${process.env.PUBLIC_URL}/avatar-team.png`
              : `${process.env.PUBLIC_URL}/avatar-vendor.png`
          }
          alt=""
        />
      </div>
      <p className="dashboard-item__user-title">{selectedOpt?.txt || ""}</p>
      {auth.whitelabel !== "SERRANA" && (
        <p className="dashboard-item__user-subtitle" title={auth.email}>
          {auth.email || ""}
        </p>
      )}
      <div className="dashboard-item__data-grid dashboard-item__data-grid--higher">
        <PeopleRoundedIcon />
        <span className="dashboard-item__data-grid-item dashboard-item__data-grid-item--label">
          Clientes:
        </span>
        {!isLoading && (
          <span className="dashboard-item__data-grid-item">
            {loadedData?.totalCustomers || 0}
          </span>
        )}
        {isLoading && <CircularProgress size={20} />}
        <ViewListRoundedIcon />
        <span className="dashboard-item__data-grid-item dashboard-item__data-grid-item--label">
          Propostas:
        </span>
        {!isLoading && (
          <span className="dashboard-item__data-grid-item">
            {loadedData?.totalProposals || 0}
          </span>
        )}
        {isLoading && <CircularProgress size={20} />}
      </div>
      <div className="dashboard-item__input">
        <Button
          variant="contained"
          className="dashboard-item__filter-btn"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <FilterListRoundedIcon /> FILTROS
        </Button>
      </div>
    </div>
  );
};

import { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { FormAddFinToProposal } from "./FormAddFinToProposal";
import { FormSelectFinOpt } from "./FormSelectFinOpt";
import { FormUploadDocs } from "./FormUploadDocs";
import { FormUploadDocsCNPJ } from "./FormUploadDocsCNPJ";
import { FormUploadNFs } from "./FormUploadNFs";
import { FormUploadSvcDocs } from "./FormUploadSvcDocs";
import { ModalErrorStyles } from "../../shared/components/UIElements/ModalErrorStyles";
import {
  FinancingData,
  AuthContextProps,
  ArchiveData,
} from "../../shared/data/types";

interface ModalFinancingProps {
  auth: AuthContextProps;
  financing: FinancingData;
  setLoadedData: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  isLoading: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  uniqueFinProps: FinancingData["proposals"];
  isSmallWidth: boolean;
}

export const ModalFinancing = (props: ModalFinancingProps) => {
  const {
    financing,
    auth,
    setLoadedData,
    isLoading,
    sendRequest,
    open,
    setOpen,
    uniqueFinProps,
    isSmallWidth,
  } = props;
  const classes = ModalErrorStyles();
  const isCNPJ = financing?.isCNPJ || false;
  const associates = financing?.customer?.associates || 1;
  const propOpts = financing.proposals?.filter((p) => p.addedToProp)?.length;
  const selOpt = financing.proposals?.find((p) => p.chosen);
  const custArchive = financing?.customer?.archive as ArchiveData;
  const approvedAllDocs = !isCNPJ
    ? custArchive?.files?.filter(
        (f) =>
          (f?.semantic === "CNH" && f?.status === "APPROVED") ||
          (f?.semantic === "Comprovante de Renda" &&
            f?.status === "APPROVED") ||
          (f?.semantic === "Conta de Energia" && f?.status === "APPROVED") ||
          (f?.semantic === "RG" && f?.status === "APPROVED")
      )?.length >= 4
    : custArchive?.files?.filter(
        (f) =>
          (f?.semantic === "DRE" ||
            f?.semantic === "Balanço" ||
            f?.semantic === "Conta de Energia" ||
            f?.semantic?.includes("RG Sócio") ||
            f?.semantic?.includes("CNH Sócio") ||
            f?.semantic?.includes("IR Sócio")) &&
          f?.status === "APPROVED"
      )?.length ===
      3 + 3 * associates;
  const approvedAllNFs =
    custArchive?.files?.filter(
      (f) =>
        (f?.semantic === "NFe de Serviço" && f?.status === "APPROVED") ||
        (f?.semantic === "NFe dos Equipamentos" && f?.status === "APPROVED")
    )?.length >= 2;
  // const approvedAllSvcDocs =
  //   custArchive?.files?.filter(
  //     (f) =>
  //       (f?.semantic === "ART de Execução" && f?.status === "APPROVED") ||
  //       (f?.semantic === "Parecer de Acesso" && f?.status === "APPROVED") ||
  //       (f?.semantic === "Foto dos Módulos" && f?.status === "APPROVED") ||
  //       (f?.semantic === "Foto dos Inversores" && f?.status === "APPROVED") ||
  //       (f?.semantic === "Foto do Medidor" && f?.status === "APPROVED")
  //   )?.length >= 5;
  const [tab, setTab] = useState<number>(
    !propOpts && !selOpt
      ? 0
      : !selOpt
      ? 1
      : !approvedAllDocs
      ? 2
      : !financing?.contractSigned
      ? 3
      : 4
  );

  const closeHandler = () => {
    setOpen(false);
  };

  const changeTabHandler = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Dialog
      className={classes.modalFinContainer}
      style={{ zIndex: 502 }}
      open={open}
      onClose={closeHandler}
    >
      <DialogActions style={{ paddingBottom: 0 }}>
        <Button style={{ height: "25px" }} size="small" onClick={closeHandler}>
          X
        </Button>
      </DialogActions>
      <DialogContent
        className={classes.modalFin}
        style={{ padding: 0, boxSizing: "border-box" }}
      >
        <Tabs
          value={tab}
          onChange={changeTabHandler}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          style={{ padding: "0 1rem" }}
        >
          <Tab
            label="Add à Proposta"
            title="Adicionar Opções de Financiamento à Proposta"
            style={{ display: !!selOpt ? "none" : "inherit" }}
          />
          <Tab
            label="Opção Fin."
            title="Seleção da Opção de Financiamento"
            style={{
              display: approvedAllDocs && selOpt ? "none" : "inherit",
            }}
          />
          <Tab
            label="Documentação"
            title="Documentação"
            disabled={!selOpt || !selOpt}
            style={{
              display:
                !selOpt || (financing?.contractSigned && approvedAllDocs)
                  ? "none"
                  : "inherit",
            }}
          />
          <Tab
            label="Notas Fiscais"
            title="Notas Fiscais"
            disabled={!selOpt}
            style={{
              display: !selOpt || !approvedAllDocs ? "none" : "inherit",
            }}
          />
          <Tab
            label="Comprovantes"
            title="Comproventes de Entrega da Usina"
            disabled={!selOpt}
            style={{
              display:
                !selOpt ||
                !approvedAllDocs ||
                !financing?.contractSigned ||
                !approvedAllNFs
                  ? "none"
                  : "inherit",
            }}
          />
        </Tabs>
        {tab === 0 && (
          <FormAddFinToProposal
            auth={auth}
            financing={financing}
            setLoadedData={setLoadedData}
            sendRequest={sendRequest}
            isLoading={isLoading}
            uniqueFinProps={uniqueFinProps}
          />
        )}
        {tab === 1 && (
          <FormSelectFinOpt
            auth={auth}
            financing={financing}
            setLoadedData={setLoadedData}
            sendRequest={sendRequest}
            isLoading={isLoading}
            uniqueFinProps={uniqueFinProps}
            setTab={setTab}
          />
        )}
        {tab === 2 && !financing.isCNPJ && (
          <FormUploadDocs
            auth={auth}
            financing={financing}
            setLoadedData={setLoadedData}
            sendRequest={sendRequest}
            isLoading={isLoading}
            uniqueFinProps={uniqueFinProps}
            isSmallWidth={isSmallWidth}
            setTab={setTab}
          />
        )}
        {tab === 2 && financing.isCNPJ && (
          <FormUploadDocsCNPJ
            auth={auth}
            financing={financing}
            setLoadedData={setLoadedData}
            sendRequest={sendRequest}
            isLoading={isLoading}
            uniqueFinProps={uniqueFinProps}
            isSmallWidth={isSmallWidth}
            setTab={setTab}
          />
        )}
        {tab === 3 && (
          <FormUploadNFs
            auth={auth}
            financing={financing}
            setLoadedData={setLoadedData}
            sendRequest={sendRequest}
            isLoading={isLoading}
            uniqueFinProps={uniqueFinProps}
            isSmallWidth={isSmallWidth}
            setTab={setTab}
          />
        )}
        {tab === 4 && (
          <FormUploadSvcDocs
            auth={auth}
            financing={financing}
            setLoadedData={setLoadedData}
            sendRequest={sendRequest}
            isLoading={isLoading}
            uniqueFinProps={uniqueFinProps}
            isSmallWidth={isSmallWidth}
            setTab={setTab}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

import { useContext, useRef, useState, useEffect } from "react";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

import { ModalError } from "../../components/UIElements/ModalError";
import { addSemanticFile, editFile } from "../../../archive/api/archiveAPI";
import { useHttpClient } from "../../hooks/httpHook";
import { AuthContext } from "../../context/authContext";
import { useForm } from "../../hooks/formHook";
import {
  FileSemantics,
  FolderSemantics,
  ArchiveData,
  FinancingData,
} from "../../data/types";

import "./FileUploadStyles.scss";

interface FileUploadSemanticProps {
  fid?: string;
  cid: string;
  label?: string;
  initFile?: ArchiveData["files"][0];
  initialFileName?: string;
  initialImage?: string;
  showImgPreview?: boolean;
  accept?: string;
  fileSemantic: FileSemantics;
  folderSemantic: FolderSemantics;
  setLoadedArchive?: React.Dispatch<React.SetStateAction<ArchiveData>>;
  setLoadedFinancings?: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  useStatus?: boolean;
  isSmallWidth?: boolean;
  style?: {};
  helperText?: string;
}

export const FileUploadSemantic = (props: FileUploadSemanticProps) => {
  const {
    fid,
    initFile,
    initialFileName,
    initialImage,
    label = "",
    showImgPreview = false,
    accept,
    fileSemantic,
    folderSemantic,
    setLoadedArchive,
    setLoadedFinancings,
    cid,
    useStatus = false,
    isSmallWidth,
    style = {},
    helperText = "Nenhum arquivo",
  } = props;
  const auth = useContext(AuthContext);
  const { formState, inputHandler } = useForm({}, false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const filePickerRef = useRef<HTMLInputElement>(null);
  const imgRef1 = useRef<HTMLImageElement>(null);
  const imgRef2 = useRef<HTMLImageElement>(null);
  const [file, setFile] = useState<File>(null);
  const [fileName, setFileName] = useState<string>(null);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>(null);
  const [initImage, setinitImage] = useState<string>(initialImage);
  const [initFileName, setinitFileName] = useState<string>(
    initialFileName || ""
  );
  const [uploaded, setUploaded] = useState<boolean>(false);
  const initFileStatus = useStatus ? initFile?.status || "PENDING" : null;

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (
        typeof fileReader.result === "string" &&
        fileReader.result?.includes("data:image")
      ) {
        setPreviewUrl(fileReader.result);
        setinitImage(null);
      } else {
        setinitImage(null);
        setPreviewUrl(null);
      }
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const loadImg1Handler = () => {
    if (imgRef1?.current) {
      if (imgRef1?.current?.clientHeight < imgRef1?.current?.clientWidth) {
        imgRef1?.current?.classList?.add("file-up-img--wide");
      } else {
        imgRef1?.current?.classList?.remove("file-up-img--wide");
      }
    }
  };

  const loadImg2Handler = () => {
    if (imgRef2?.current) {
      if (imgRef2?.current?.clientHeight < imgRef2?.current?.clientWidth) {
        imgRef2?.current?.classList?.add("file-up-img--wide");
      } else {
        imgRef2?.current?.classList?.remove("file-up-img--wide");
      }
    }
  };

  useEffect(() => {
    if (file) {
      setFileName(file.name);
    }
  }, [file]);

  const pickedFileHandler = (event: React.FormEvent<HTMLInputElement>) => {
    let pickedFile: File = null;
    let fileIsValid = isValid;
    if (event.currentTarget.files && event.currentTarget.files.length === 1) {
      pickedFile = event.currentTarget.files[0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
      inputHandler("file", pickedFile, fileIsValid, "Arquivo");
      setinitFileName(null);
    }
  };

  const pickFileHandler = () => {
    filePickerRef.current.click();
  };

  const downloadFileHandler = () => {
    if (initFile?.url) {
      window.open(
        `${process.env.REACT_APP_ASSET_URL}/${initFile?.url}`,
        "_blank"
      );
    }
  };

  const uploadFileHandler = () => {
    if (!initFile) {
      addSemanticFile({
        sendRequest,
        auth,
        setLoadedArchive,
        fileSemantic,
        folderSemantic,
        formState,
        cid,
        fid,
        setLoadedFinancings,
        setUploaded,
      });
    } else {
      editFile({
        name: initialFileName || fileSemantic,
        sendRequest,
        auth,
        setLoadedArchive,
        fileId: initFile._id,
        formState,
        cid,
        fid,
        setLoadedFinancings,
        setUploaded,
      });
    }
  };

  return (
    <div className="file-upload" style={style || {}}>
      <ModalError error={error} onClear={clearError} />
      {!accept && (
        <input
          id="file"
          ref={filePickerRef}
          className="file-upload__input"
          type="file"
          onChange={pickedFileHandler}
        />
      )}
      {!!accept && (
        <input
          id="file"
          ref={filePickerRef}
          className="file-upload__input"
          type="file"
          accept={accept}
          onChange={pickedFileHandler}
        />
      )}
      <div className="file-upload__preview-container">
        <div className="file-upload__label">{label || fileSemantic}</div>
        <div
          className={`file-upload__preview ${
            initFile?.url ? "file-upload__preview--clickable" : ""
          }`}
          onClick={downloadFileHandler}
        >
          {!isLoading ? (
            <span>
              {(!!initialFileName && !formState?.isValid) || uploaded ? (
                <span
                  className={`file-upload__preview-uploaded ${
                    initFileStatus === "APPROVED" || !initFileStatus
                      ? "file-upload__preview-uploaded--approved"
                      : initFileStatus === "REJECTED"
                      ? "file-upload__preview-uploaded--rejected"
                      : "file-upload__preview-uploaded--pending"
                  }`}
                  title={
                    !initFileStatus
                      ? ""
                      : initFileStatus === "APPROVED"
                      ? "Aprovado"
                      : initFileStatus === "REJECTED"
                      ? "Reprovado"
                      : "Em Análise"
                  }
                >
                  {initFileStatus === "APPROVED" || !initFileStatus ? (
                    <span>
                      <CheckCircleRoundedIcon /> (Aprovado)
                    </span>
                  ) : initFileStatus === "REJECTED" ? (
                    <span>
                      <HighlightOffRoundedIcon /> (Reprovado)
                    </span>
                  ) : (
                    <span>
                      <WarningRoundedIcon /> (Em Análise)
                    </span>
                  )}{" "}
                  {!isSmallWidth && <span>{initialFileName}</span>}
                </span>
              ) : (
                fileName || initFileName || helperText
              )}
            </span>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={20} color="primary" />
            </div>
          )}
        </div>
        {!formState?.isValid && initFileStatus !== "APPROVED" && (
          <button
            className="file-upload__btn file-upload__btn--dark-blue"
            title="Escolher Arquivo"
            disabled={isLoading}
            onClick={(e) => {
              e.preventDefault();
              pickFileHandler();
            }}
          >
            {!isLoading ? <FindInPageRoundedIcon /> : <SyncRoundedIcon />}
          </button>
        )}
        {formState?.isValid && !uploaded && initFileStatus !== "APPROVED" && (
          <button
            className="file-upload__btn file-upload__btn--cyan"
            title="Fazer Upload"
            disabled={isLoading}
            onClick={(e) => {
              e.preventDefault();
              uploadFileHandler();
            }}
          >
            {!isLoading ? <PublishRoundedIcon /> : <SyncRoundedIcon />}
          </button>
        )}
        {((formState?.isValid && uploaded) ||
          initFileStatus === "APPROVED") && (
          <button
            className="file-upload__btn file-upload__btn--teal"
            title="Upload Feito com Sucesso!"
            disabled
            onClick={(e) => {
              e.preventDefault();
              uploadFileHandler();
            }}
          >
            <DoneOutlineRoundedIcon />
          </button>
        )}
      </div>
      {showImgPreview && !previewUrl && !initImage && (
        <div className="image-upload center">
          <div className={`file-upload__img-preview file`}>
            <img
              src={`${process.env.PUBLIC_URL}/file-placeholder.png`}
              className={`file-up-img`}
              alt="imagem"
              title="imagem"
            />
          </div>
        </div>
      )}
      {showImgPreview && previewUrl && (
        <div className="image-upload center">
          <div className={`file-upload__img-preview file`}>
            <img
              ref={imgRef1}
              src={previewUrl}
              className={`file-up-img`}
              alt="imagem"
              title="imagem"
              onLoad={loadImg1Handler}
            />
          </div>
        </div>
      )}
      {showImgPreview && !!initImage && !previewUrl && (
        <div className="image-upload center">
          <div className={`file-upload__img-preview file`}>
            <img
              ref={imgRef2}
              src={`${process.env.REACT_APP_ASSET_URL}/${initImage}`}
              className={`file-up-img`}
              alt="imagem"
              title="imagem"
              onLoad={loadImg2Handler}
            />
          </div>
        </div>
      )}
    </div>
  );
};

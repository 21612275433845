import { createContext } from "react";

import { NavContextProps } from "../data/types";

export const NavContext = createContext<NavContextProps>({
  activeView: 100,
  activeUrl: "",
  expand: false,
  hide: false,
  navigateHandler: (url) => {},
  expandHandler: (expand) => {},
  hideHandler: (hide) => {},
});

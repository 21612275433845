import {
  ArchiveData,
  AuthContextProps,
  FormHookDispState,
  FileSemantics,
  FolderSemantics,
  FinancingData,
} from "../../shared/data/types";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// GET CUSTOMER FILES /////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface GetCustomerFilesProps extends FetchProps {
  cid: string;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
  setLoadedCustomerName: React.Dispatch<React.SetStateAction<string>>;
}

export const getCustomerFiles = async (props: GetCustomerFilesProps) => {
  const { sendRequest, auth, setLoadedArchive, cid, setLoadedCustomerName } =
    props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/archives/customer/${auth.userId}/${cid}`;
    const responseData: { archive: ArchiveData; customerName: string } =
      await sendRequest(apiUrl, "GET", null, {
        Authorization: "Bearer " + auth.token,
      });
    setLoadedArchive(responseData.archive);
    setLoadedCustomerName(responseData.customerName || "");
  } catch (err) {}
};

// ADD FOLDER /////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface AddFolderProps extends FetchProps {
  cid: string;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
  formState: FormHookDispState;
}

export const addFolder = async (props: AddFolderProps) => {
  const { sendRequest, auth, setLoadedArchive, cid, formState } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/archives/customer/folder/${cid}`;
    const responseData: { archive: ArchiveData } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({ name: formState.inputs?.name?.value || "" }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );
    setLoadedArchive(responseData.archive);
    return;
  } catch (err) {}
};

// ADD FILE ///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface AddFileProps extends FetchProps {
  cid: string;
  folderId: string;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
  formState: FormHookDispState;
}

export const addFile = async (props: AddFileProps) => {
  const { sendRequest, auth, setLoadedArchive, cid, folderId, formState } =
    props;

  const formData = new FormData();
  formData.append("file", formState.inputs?.file?.value as File);
  formData.append("name", formState.inputs?.name?.value?.toString());

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/archives/customer/file/${cid}/${folderId}`;
    const responseData: { archive: ArchiveData } = await sendRequest(
      apiUrl,
      "POST",
      formData,
      { Authorization: "Bearer " + auth.token }
    );

    setLoadedArchive(responseData.archive);
  } catch (err) {}
};

// ADD SEMANTIC FILE //////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface AddSemanticFileProps extends FetchProps {
  cid: string;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
  formState: FormHookDispState;
  fileSemantic: FileSemantics;
  folderSemantic: FolderSemantics;
  setUploaded?: React.Dispatch<React.SetStateAction<boolean>>;
  fid?: string;
  setLoadedFinancings?: React.Dispatch<React.SetStateAction<FinancingData[]>>;
}

export const addSemanticFile = async (props: AddSemanticFileProps) => {
  const {
    sendRequest,
    auth,
    setLoadedArchive,
    cid,
    fid,
    fileSemantic,
    folderSemantic,
    formState,
    setUploaded,
    setLoadedFinancings,
  } = props;

  const formData = new FormData();
  formData.append("file", formState.inputs?.file?.value as File);
  formData.append("name", formState.inputs?.name?.value?.toString() || "");
  formData.append("fileSemantic", fileSemantic);
  formData.append("folderSemantic", folderSemantic);

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/archives/customer/file-semantic/${cid}`;
    const responseData: { archive: ArchiveData } = await sendRequest(
      apiUrl,
      "POST",
      formData,
      { Authorization: "Bearer " + auth.token }
    );

    if (setLoadedArchive) {
      setLoadedArchive(responseData.archive);
    }

    if (setUploaded) {
      setUploaded(true);
    }

    if (setLoadedFinancings && fid) {
      setLoadedFinancings((prevValues) => {
        prevValues = prevValues.map((item) => {
          if (item._id === fid) {
            item.customer.archive = responseData.archive;
          }

          return item;
        });

        return prevValues;
      });
    }
  } catch (err) {}
};

// EDIT FOLDER ////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface EditFolderProps extends FetchProps {
  cid: string;
  folderId: string;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
  formState: FormHookDispState;
}

export const editFolder = async (props: EditFolderProps) => {
  const { sendRequest, auth, setLoadedArchive, cid, formState, folderId } =
    props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/archives/customer/folder/name/${cid}/${folderId}`;
    const responseData: { archive: ArchiveData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ name: formState.inputs?.name?.value || "" }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );
    setLoadedArchive(responseData.archive);
    return;
  } catch (err) {}
};

// EDIT FILE //////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface EditFileProps extends FetchProps {
  name?: string;
  cid: string;
  fileId: string;
  setLoadedArchive?: React.Dispatch<React.SetStateAction<ArchiveData>>;
  formState: FormHookDispState;
  setUploaded?: React.Dispatch<React.SetStateAction<boolean>>;
  fid?: string;
  setLoadedFinancings?: React.Dispatch<React.SetStateAction<FinancingData[]>>;
}

export const editFile = async (props: EditFileProps) => {
  const {
    name = "",
    sendRequest,
    auth,
    setLoadedArchive,
    cid,
    fileId,
    formState,
    setUploaded,
    fid,
    setLoadedFinancings,
  } = props;

  console.log(name, name || formState.inputs?.name?.value?.toString());

  const formData = new FormData();
  formData.append("file", formState.inputs?.file?.value as File);
  formData.append("name", name || formState.inputs?.name?.value?.toString());

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/archives/customer/file/name/${cid}/${fileId}`;
    const responseData: { archive: ArchiveData } = await sendRequest(
      apiUrl,
      "PUT",
      formData,
      { Authorization: "Bearer " + auth.token }
    );

    if (setLoadedArchive) {
      setLoadedArchive(responseData.archive);
    }

    if (setUploaded) {
      setUploaded(true);
    }

    if (setLoadedFinancings && fid) {
      setLoadedFinancings((prevValues) => {
        prevValues = prevValues.map((item) => {
          if (item._id === fid) {
            item.customer.archive = responseData.archive;
          }

          return item;
        });

        return prevValues;
      });
    }
  } catch (err) {}
};

// MOVE FOLDER ////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface MoveFolderProps extends FetchProps {
  cid: string;
  folderId: string;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
  order: number;
}

export const moveFolder = async (props: MoveFolderProps) => {
  const { sendRequest, auth, setLoadedArchive, cid, order, folderId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/archives/customer/folder/order/${cid}/${folderId}`;
    const responseData: { archive: ArchiveData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ order }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );
    setLoadedArchive(responseData.archive);
  } catch (err) {}
};

// MOVE FILE //////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface MoveFileProps extends FetchProps {
  cid: string;
  fileId: string;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
  order: number;
  folder: string;
}

export const moveFile = async (props: MoveFileProps) => {
  const { sendRequest, auth, setLoadedArchive, cid, order, fileId, folder } =
    props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/archives/customer/file/order/${cid}/${fileId}`;
    const responseData: { archive: ArchiveData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ order, folder }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );
    setLoadedArchive(responseData.archive);
  } catch (err) {}
};

// DELETE FOLDER //////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface DeleteFolderProps extends FetchProps {
  cid: string;
  folderId: string;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
}

export const deleteFolder = async (props: DeleteFolderProps) => {
  const { sendRequest, auth, setLoadedArchive, cid, folderId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/archives/customer/folder/${cid}/${folderId}`;
    const responseData: { archive: ArchiveData } = await sendRequest(
      apiUrl,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );
    setLoadedArchive(responseData.archive);
  } catch (err) {}
};

// DELETE FILE ////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface DeleteFileProps extends FetchProps {
  cid: string;
  fileId: string;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
}

export const deleteFile = async (props: DeleteFileProps) => {
  const { sendRequest, auth, setLoadedArchive, cid, fileId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/archives/customer/file/${cid}/${fileId}`;
    const responseData: { archive: ArchiveData } = await sendRequest(
      apiUrl,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );
    setLoadedArchive(responseData.archive);
  } catch (err) {}
};

import { useState, Fragment } from "react";
import { PopoverAddBTGRegistrationRequest } from "./PopoverAddBTGRegistrationRequest";
import { getPastDaysFromDate } from "../../shared/util/getDaysFromDate";
import {
  RegistrationData,
  AuthContextProps,
  ExpiredFinsData,
} from "../../shared/data/types";

import "./FinancingBTGWarning.scss";

interface FinancingBTGWarningProps {
  loadedRegistration: RegistrationData;
  auth: AuthContextProps;
  setLoadedRegistration: React.Dispatch<React.SetStateAction<RegistrationData>>;
  canFinanceInBTG: boolean;
  expiredFinData: ExpiredFinsData;
}

export const FinancingBTGWarning = (props: FinancingBTGWarningProps) => {
  const {
    loadedRegistration,
    auth,
    setLoadedRegistration,
    canFinanceInBTG,
    expiredFinData,
  } = props;
  const [anchorEl, setAnchorEl] = useState<
    null | HTMLButtonElement | HTMLDivElement
  >(null);

  const hasReg = !!loadedRegistration;
  const regStatus = loadedRegistration?.status;
  const daysSinceLastSubmition = hasReg
    ? getPastDaysFromDate(loadedRegistration?.lastSubmitionDate)
    : 0;
  const daysLeft = 60 - daysSinceLastSubmition;

  const text =
    expiredFinData?.expiredFinancings > 0
      ? expiredFinData?.hasReachedLimit
        ? "Você atingiu o limite de infrações por atraso. Por favor, regularize a documentação dos financiamentos com infrações para contratar novos financiamentos."
        : `Você possui ${expiredFinData?.expiredFinancings} infração(ões) por atraso. Por favor, regularize a documentação do(s) financiamento(s) com infração(ões) para não perder o benefício de novas contratações.`
      : !hasReg || regStatus === "Nova Análise Liberada" || daysLeft <= 0
      ? "Seu CNPJ não encontra-se cadastrado no BTG. Por favor, efetue o cadastro da sua empresa no BTG para solicitar análises de crédito."
      : (regStatus === "Solicitação Gerada" ||
          regStatus === "Cadastro em Análise") &&
        daysSinceLastSubmition <= 7
      ? `Seu cadastro no BTG encontra-se pendente - STATUS: ${regStatus}.`
      : regStatus === "Cadastro Reprovado" || daysSinceLastSubmition > 7
      ? `Seu cadastro no BTG não foi aprovado. Você poderá solicitar uma nova análise em ${daysLeft} dias.`
      : "Seu cadastro no BTG foi Aprovado. Caso não esteja conseguindo solicitar análises de crédito, entre em contato com o nosso suporte.";

  const color =
    expiredFinData?.expiredFinancings > 0
      ? `red`
      : !hasReg || regStatus === "Nova Análise Liberada" || daysLeft <= 0
      ? "dark-blue"
      : (regStatus === "Solicitação Gerada" ||
          regStatus === "Cadastro em Análise") &&
        daysSinceLastSubmition <= 7
      ? `cyan`
      : regStatus === "Cadastro Reprovado" || daysSinceLastSubmition > 7
      ? `grey`
      : "cyan";

  return (
    <Fragment>
      <PopoverAddBTGRegistrationRequest
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        auth={auth}
        setLoadedRegistration={setLoadedRegistration}
      />
      {(!canFinanceInBTG || expiredFinData?.expiredFinancings > 0) && (
        <div className={`financing-warning financing-warning--${color}`}>
          <p className="financing-warning__txt">{text}</p>
          {(!hasReg ||
            regStatus === "Nova Análise Liberada" ||
            daysLeft <= 0) && (
            <div
              className={`financing-warning__cta financing-warning__cta--${color}`}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              SOLICITAR CADASTRO
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

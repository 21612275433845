import React, { Fragment, useContext } from "react";
import { ImportMapping } from "../../shared/components/ImportMapping/ImportMapping";
import { uploadKits } from "../api/kitsAPI";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { AuthContext } from "../../shared/context/authContext";
import { ModalError } from "../../shared/components/UIElements/ModalError";

const generateModuleProperties = (count: number) => {
  return Array.from({ length: count }, (_, i) => i + 1).flatMap((index) => {
    const isRequired = (mappedFields: string[]) => {
      if (index === 1) return true;
      const moduleFields = [
        `modulo_${index}_modelo`,
        `modulo_${index}_fabricante`,
        `modulo_${index}_potencia_em_watts`,
        `modulo_${index}_quantidade`,
      ];
      return (
        moduleFields.some((field) => mappedFields.includes(field)) ||
        mappedFields.some((field) => field.startsWith(`modulo_${index}_`))
      );
    };

    return [
      {
        value: `modulo_${index}_modelo`,
        aliases: [
          `modulo_${index}_modelo`,
          `modelo_modulo_${index}`,
          `modulo_${index}_model`,
          `module_${index}_model`,
        ],
        required: isRequired,
      },
      {
        value: `modulo_${index}_fabricante`,
        aliases: [
          `modulo_${index}_fabricante`,
          `fabricante_modulo_${index}`,
          `modulo_${index}_manufacturer`,
          `module_${index}_manufacturer`,
        ],
        required: isRequired,
      },
      {
        value: `modulo_${index}_potencia_em_watts`,
        aliases: [
          `modulo_${index}_potencia_em_watts`,
          `potencia_modulo_${index}`,
          `modulo_${index}_power`,
          `module_${index}_power_watts`,
        ],
        required: isRequired,
      },
      {
        value: `modulo_${index}_quantidade`,
        aliases: [
          `modulo_${index}_quantidade`,
          `quantidade_modulo_${index}`,
          `modulo_${index}_quantity`,
          `module_${index}_quantity`,
        ],
        required: isRequired,
      },
      {
        value: `modulo_${index}_garantia_defeitos`,
        aliases: [
          `modulo_${index}_garantia_defeitos`,
          `garantia_defeitos_modulo_${index}`,
          `modulo_${index}_defect_warranty`,
          `module_${index}_defect_warranty`,
        ],
        required: false,
      },
      {
        value: `modulo_${index}_garantia_eficiencia`,
        aliases: [
          `modulo_${index}_garantia_eficiencia`,
          `garantia_eficiencia_modulo_${index}`,
          `modulo_${index}_efficiency_warranty`,
          `module_${index}_efficiency_warranty`,
        ],
        required: false,
      },
      {
        value: `modulo_${index}_comprimento_em_milimetros`,
        aliases: [
          `modulo_${index}_comprimento_em_milimetros`,
          `comprimento_modulo_${index}`,
          `modulo_${index}_length_mm`,
          `module_${index}_length_mm`,
        ],
        required: false,
      },
      {
        value: `modulo_${index}_largura_em_milimetros`,
        aliases: [
          `modulo_${index}_largura_em_milimetros`,
          `largura_modulo_${index}`,
          `modulo_${index}_width_mm`,
          `module_${index}_width_mm`,
        ],
        required: false,
      },
      {
        value: `modulo_${index}_peso_em_kg`,
        aliases: [
          `modulo_${index}_peso_em_kg`,
          `peso_modulo_${index}`,
          `modulo_${index}_weight_kg`,
          `module_${index}_weight_kg`,
        ],
        required: false,
      },
    ];
  });
};

const generateInverterProperties = (count: number) => {
  return Array.from({ length: count }, (_, i) => i + 1).flatMap((index) => {
    const isRequired = (mappedFields: string[]) => {
      if (index === 1) return true;
      const inverterFields = [
        `inversor_${index}_modelo`,
        `inversor_${index}_fabricante`,
        `inversor_${index}_potencia_em_watts`,
        `inversor_${index}_quantidade`,
      ];
      return (
        inverterFields.some((field) => mappedFields.includes(field)) ||
        mappedFields.some((field) => field.startsWith(`inversor_${index}_`))
      );
    };

    return [
      {
        value: `inversor_${index}_modelo`,
        aliases: [
          `inversor_${index}_modelo`,
          `modelo_inversor_${index}`,
          `inversor_${index}_model`,
          `inverter_${index}_model`,
        ],
        required: isRequired,
      },
      {
        value: `inversor_${index}_fabricante`,
        aliases: [
          `inversor_${index}_fabricante`,
          `fabricante_inversor_${index}`,
          `inversor_${index}_manufacturer`,
          `inverter_${index}_manufacturer`,
        ],
        required: isRequired,
      },
      {
        value: `inversor_${index}_potencia_em_watts`,
        aliases: [
          `inversor_${index}_potencia_em_watts`,
          `potencia_inversor_${index}`,
          `inversor_${index}_power`,
          `inverter_${index}_power_watts`,
        ],
        required: isRequired,
      },
      {
        value: `inversor_${index}_garantia`,
        aliases: [
          `inversor_${index}_garantia`,
          `garantia_inversor_${index}`,
          `inversor_${index}_warranty`,
          `inverter_${index}_warranty`,
        ],
        required: false,
      },
      {
        value: `inversor_${index}_quantidade`,
        aliases: [
          `inversor_${index}_quantidade`,
          `quantidade_inversor_${index}`,
          `inversor_${index}_quantity`,
          `inverter_${index}_quantity`,
        ],
        required: isRequired,
      },
      {
        value: `inversor_${index}_microinversor`,
        aliases: [
          `inversor_${index}_microinversor`,
          `microinversor_${index}`,
          `inversor_${index}_microinverter`,
          `inverter_${index}_microinverter`,
        ],
        required: false,
      },
    ];
  });
};

const generateAdditionalItemProperties = (count: number) => {
  return Array.from({ length: count }, (_, i) => i + 1).flatMap((index) => {
    const isRequired = (mappedFields: string[]) => {
      const itemFields = [
        `item_adicional_${index}_nome`,
        `item_adicional_${index}_quantidade`,
      ];
      return itemFields.some((field) => mappedFields.includes(field));
    };

    return [
      {
        value: `item_adicional_${index}_nome`,
        aliases: [
          `item_adicional_${index}_nome`,
          `nome_item_adicional_${index}`,
          `additional_item_${index}_name`,
          `extra_item_${index}_name`,
        ],
        required: isRequired,
      },
      {
        value: `item_adicional_${index}_quantidade`,
        aliases: [
          `item_adicional_${index}_quantidade`,
          `quantidade_item_adicional_${index}`,
          `additional_item_${index}_quantity`,
          `extra_item_${index}_quantity`,
        ],
        required: isRequired,
      },
    ];
  });
};

const availableProperties = [
  {
    value: "fornecedor",
    aliases: ["fornecedor", "supplier", "vendor", "provider"],
    required: true,
  },
  {
    value: "valor",
    aliases: ["valor", "price", "cost", "value"],
    required: true,
  },
  {
    value: "tensao_de_saida",
    aliases: [
      "tensao_de_saida",
      "output_voltage",
      "voltage_output",
      "tensao_saida",
    ],
    required: false,
  },
  {
    value: "estrutura_de_fixacao",
    aliases: [
      "estrutura_de_fixacao",
      "mounting_structure",
      "fixation_structure",
      "estrutura_fixacao",
    ],
    required: false,
  },
  ...generateModuleProperties(5),
  ...generateInverterProperties(5),
  ...generateAdditionalItemProperties(25),
];

export const KitImportMapping: React.FC = () => {
  const { sendRequest, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const handleSubmit = async (
    mapping: Record<string, string>,
    fileContent: string
  ) => {
    try {
      await uploadKits({ sendRequest, auth, mapping, fileContent });
      return true;
    } catch (error) {
      console.error("Erro ao fazer upload:", error);
      return false;
    }
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ImportMapping
        availableProperties={availableProperties}
        onSubmit={handleSubmit}
        importType="kit"
        title="Importação - Mapeamento de Kits"
      />
    </Fragment>
  );
};

import { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import MoneyOffRoundedIcon from "@material-ui/icons/MoneyOffRounded";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import ViewListRoundedIcon from "@material-ui/icons/ViewListRounded";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import WatchLaterRoundedIcon from "@material-ui/icons/WatchLaterRounded";
import EventBusyRoundedIcon from "@material-ui/icons/EventBusyRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFire,
  faSnowflake,
  faMugHot,
} from "@fortawesome/free-solid-svg-icons";

import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { MenuSalesItemOptions } from "./MenuSalesItemOptions";
import { PopoverFormClosedSalePropChoice } from "./PopoverFormClosedSalePropChoice";
import { PopoverFormLostSaleReasonChoice } from "./PopoverFormLostSaleReasonChoice";
import { PopoverFormClosedLeadVendorChoice } from "./PopoverFormClosedLeadVendorChoice";
import { MoreItems } from "../../shared/components/UIElements/MoreItems";
import { sliceLongString } from "../../shared/util/sliceLongString";
import { openWhatsapp } from "../../shared/util/openWhatsapp";
import { editSaleStatus } from "../api/salesFunnelAPI";
import { getSaleStatusNameFromId } from "../util/getSaleStatusNameFromId";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { validDateChecker } from "../../shared/util/validDateChecker";
import { getDateFromTimestamp } from "../../shared/util/getDateFromTimestamp";
import { getSaleIsInactive } from "../util/getSaleIsInactive";
import { formatPostalCode } from "../../shared/util/formatPostalCode";
import {
  AuthContextProps,
  KanbanFilledData,
  SettingsContextProps,
  ProposalData,
} from "../../shared/data/types";

import "./SaleDash.scss";
import "./SalesItem.scss";

interface SaleDashGenDataProps {
  memory: KanbanFilledData["items"][0];
  auth: AuthContextProps;
  settingsCtx: SettingsContextProps;
  kanbanData: KanbanFilledData;
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  fetchId: string;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  setUpdateColumnsTotals?: React.Dispatch<React.SetStateAction<string[]>>;
  isManagerView: boolean;
  isTeamLoaded: boolean;
}

export const SaleDashGenData = (props: SaleDashGenDataProps) => {
  const {
    memory,
    auth,
    setLoadedData,
    sendRequest,
    setActiveItem,
    fetchId,
    kanbanData,
    type,
    settingsCtx,
    setUpdateColumnsTotals,
    isManagerView,
    isTeamLoaded,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const [anchorElFormCloseSale, setAnchorElFormCloseSale] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [anchorElFormLostSale, setAnchorElFormLostSale] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [open, setOpen] = useState<boolean>(true);
  const itemOwner = auth.vendors.find((vendor) => {
    return vendor?._id === memory?.user?.toString();
  });
  const [showConfirmFinishModal, setShowConfirmFinishModal] =
    useState<boolean>(false);
  const proposal = memory?.approvedProposal as ProposalData;
  const custProps = memory?.customer?.proposals;
  const totalViews =
    custProps?.length > 0
      ? custProps
          ?.map((prop) => {
            return prop?.views || 0;
          })
          ?.reduce((a, b) => {
            return a + b;
          })
      : 0;
  const saleMultOwners = memory?.owners || [];
  const saleMultOwnersNames = saleMultOwners
    ?.map((data) => {
      if (data === auth.managerUserId) {
        return "GERENTE";
      } else {
        return auth.vendors?.find((v) => {
          return v._id === data;
        })?.personalData?.name;
      }
    })
    ?.join(" | ");
  const preApprovedFins =
    memory?.customer?.financings?.filter((f) => f?.btg?.preApproved) || [];
  const column = kanbanData?.kanban?.columns?.find(
    (c) => c?._id === memory?.kanbanData?.column
  );

  const finishTaskHandler = () => {
    setShowConfirmFinishModal(false);
    editSaleStatus({
      sendRequest,
      auth,
      status: "CLOSED",
      approvedProposal: "",
      itemId: memory?._id,
      setKanbanData: setLoadedData,
      toBeRemovedItem: memory,
    }).then(() => {
      if (setActiveItem) {
        setActiveItem(null);
      }
      if (setUpdateColumnsTotals) {
        setUpdateColumnsTotals([memory?.kanbanData?.column, "0"]);
      }
    });
    setAnchorEl(null);
  };

  return (
    <div className="sale-dash__cust-data">
      {memory?.status === "IN_PROGRESS" && type === "AFTER_SALES" && (
        <ModalConfirm
          open={showConfirmFinishModal}
          closeHandler={() => {
            setShowConfirmFinishModal(false);
          }}
          confirmHandler={() => {
            finishTaskHandler();
          }}
          message="Deseja marcar este item como finalizado?"
        />
      )}
      <MenuSalesItemOptions
        item={memory}
        customerId={memory?.customer?.id}
        columnId={memory?.kanbanData?.column}
        anchorEl={anchorEl}
        settingsCtx={settingsCtx}
        setAnchorEl={setAnchorEl}
        id={memory?._id}
        sendRequest={sendRequest}
        auth={auth}
        setKanbanData={setLoadedData}
        fetchId={fetchId}
        kanbanData={kanbanData}
        setActiveItem={setActiveItem}
        type={type}
        setUpdateColumnsTotals={setUpdateColumnsTotals}
        isManagerView={isManagerView}
        isTeamLoaded={isTeamLoaded}
      />
      {type === "SALES" && (
        <PopoverFormClosedSalePropChoice
          itemId={memory?._id}
          item={memory}
          anchorEl={anchorElFormCloseSale}
          settingsCtx={settingsCtx}
          setAnchorEl={setAnchorElFormCloseSale}
          auth={auth}
          sendRequest={sendRequest}
          setKanbanData={setLoadedData}
          setActiveItem={setActiveItem}
          type={type}
          setUpdateColumnsTotals={setUpdateColumnsTotals}
        />
      )}
      {type === "PRE_SALES" && (
        <PopoverFormClosedLeadVendorChoice
          itemId={memory?._id}
          item={memory}
          anchorEl={anchorElFormCloseSale}
          setAnchorEl={setAnchorElFormCloseSale}
          auth={auth}
          sendRequestParent={sendRequest}
          setKanbanData={setLoadedData}
          setActiveItem={setActiveItem}
          settingsCtx={settingsCtx}
          type={type}
          setUpdateColumnsTotals={setUpdateColumnsTotals}
        />
      )}
      {(type === "SALES" || type === "PRE_SALES") && (
        <PopoverFormLostSaleReasonChoice
          itemId={memory?._id}
          item={memory}
          anchorEl={anchorElFormLostSale}
          setAnchorEl={setAnchorElFormLostSale}
          auth={auth}
          sendRequest={sendRequest}
          setKanbanData={setLoadedData}
          setActiveItem={setActiveItem}
          setUpdateColumnsTotals={setUpdateColumnsTotals}
        />
      )}
      <div className="sale-dash__title-container">
        <p className="sale-dash__title">{memory?.name}</p>
        <div
          className={`sale-dash__cust-data-actions ${
            type === "AFTER_SALES" ? "sale-dash__cust-data-actions--2" : ""
          }`}
        >
          {type === "SALES" && (
            <IconButton
              size="small"
              style={{
                color: memory?.status === "IN_PROGRESS" ? "#189ab4" : "#aaa",
              }}
              title="Dar Ganho"
              className={`table__item-action`}
              disabled={memory?.status !== "IN_PROGRESS"}
              onClick={(e) => {
                setAnchorElFormCloseSale(e.currentTarget);
              }}
            >
              <AttachMoneyRoundedIcon style={{ fontSize: "22px" }} />
            </IconButton>
          )}
          {type === "SALES" && (
            <IconButton
              size="small"
              style={{
                color: memory?.status === "IN_PROGRESS" ? "#f50057" : "#aaa",
              }}
              title="Dar Perdido"
              className={`table__item-action`}
              disabled={memory?.status !== "IN_PROGRESS"}
              onClick={(e) => {
                setAnchorElFormLostSale(e.currentTarget);
              }}
            >
              <MoneyOffRoundedIcon style={{ fontSize: "22px" }} />
            </IconButton>
          )}
          {type === "PRE_SALES" && (
            <IconButton
              size="small"
              style={{
                color: memory?.status === "IN_PROGRESS" ? "#189ab4" : "#aaa",
              }}
              title="Qualificar"
              className={`table__item-action`}
              disabled={memory?.status !== "IN_PROGRESS"}
              onClick={(e) => {
                setAnchorElFormCloseSale(e.currentTarget);
              }}
            >
              <DoneOutlineRoundedIcon style={{ fontSize: "22px" }} />
            </IconButton>
          )}
          {type === "PRE_SALES" && (
            <IconButton
              size="small"
              style={{
                color: memory?.status === "IN_PROGRESS" ? "#f50057" : "#aaa",
              }}
              title="Desqualificar"
              className={`table__item-action`}
              disabled={memory?.status !== "IN_PROGRESS"}
              onClick={(e) => {
                setAnchorElFormLostSale(e.currentTarget);
              }}
            >
              <CloseRoundedIcon style={{ fontSize: "22px" }} />
            </IconButton>
          )}
          {type === "AFTER_SALES" && (
            <IconButton
              size="small"
              style={{
                color: memory?.status === "IN_PROGRESS" ? "#189ab4" : "#aaa",
              }}
              title="Finalizar"
              className={`table__item-action`}
              disabled={memory?.status !== "IN_PROGRESS"}
              onClick={(e) => {
                setShowConfirmFinishModal(true);
              }}
            >
              <DoneOutlineRoundedIcon style={{ fontSize: "22px" }} />
            </IconButton>
          )}
          <IconButton
            size="small"
            style={{ color: "#043a50" }}
            title="Opções"
            className={`table__item-action`}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreHorizRoundedIcon style={{ fontSize: "24px" }} />
          </IconButton>
        </div>
      </div>
      <div
        className={`sale-dash__checklist sale-dash__checklist--first ${
          open ? "sale-dash__checklist--first-extra-padding-bottom" : ""
        }`}
      >
        <div
          onClick={(e) => {
            setOpen(!open);
          }}
          className="sale-dash__title-container sale-dash__title-container--clickable sale-dash__title-container--secondary"
        >
          <p className={`sale-dash__title sale-dash__title--secondary`}>
            Informações
          </p>
          <div className="sale-dash__cust-data-actions sale-dash__cust-data-actions--1">
            <IconButton
              style={{
                color: "#aaa",
              }}
              size="small"
              title={!open ? "Abrir" : "Fechar"}
              className={`sale-dash__cust-data-action sale-dash__cust-data-action--blue ${
                open ? "sale-dash__cust-data-action--rotated-icon" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              <KeyboardArrowDownRoundedIcon style={{ fontSize: "30px" }} />
            </IconButton>
          </div>
        </div>
        {open && (
          <div className="sale-dash__cust-data-item">
            {type === "AFTER_SALES" && memory?.afterSalesData?.serviceOrder && (
              <Fragment>
                <p className="sale-dash__cust-data-item-label">OS:</p>
                <p className="sale-dash__cust-data-item-content">
                  {`#${memory?.afterSalesData?.serviceOrder?.toLocaleString(
                    "pt-BR",
                    {
                      minimumIntegerDigits: 5,
                      useGrouping: false,
                    }
                  )}`}
                </p>
              </Fragment>
            )}
            {memory?.status !== "IN_PROGRESS" && (
              <Fragment>
                <p className="sale-dash__cust-data-item-label">Status:</p>
                <p
                  className={`sale-dash__cust-data-item-content ${
                    memory?.status === "CLOSED"
                      ? "sale-dash__cust-data-item-content--blue"
                      : "sale-dash__cust-data-item-content--red"
                  }`}
                >
                  {getSaleStatusNameFromId(
                    memory?.status || "IN_PROGRESS",
                    (memory?.type as "SALES") || "SALES"
                  )}
                </p>
              </Fragment>
            )}
            <p className="sale-dash__cust-data-item-label">Valor:</p>
            <p className="sale-dash__cust-data-item-content">
              {`R$ ${addThousandSeparator(memory?.amount) || "-"}`}{" "}
            </p>
            {memory?.status === "IN_PROGRESS" && memory?.type === "SALES" && (
              <Fragment>
                <p className="sale-dash__cust-data-item-label">Temperatura:</p>
                <p
                  className={`sale-dash__cust-data-item-content ${
                    memory?.leadTemperature === 2
                      ? "sale-dash__cust-data-item-content--red"
                      : memory?.leadTemperature === 1
                      ? "sale-dash__cust-data-item-content--orange"
                      : memory?.leadTemperature === 0
                      ? "sale-dash__cust-data-item-content--blue"
                      : ""
                  }`}
                >
                  {memory?.leadTemperature === 2
                    ? "Quente"
                    : memory?.leadTemperature === 1
                    ? "Morno"
                    : memory?.leadTemperature === 0
                    ? "Frio"
                    : "-"}
                </p>
              </Fragment>
            )}
            {type === "AFTER_SALES" && (
              <Fragment>
                <p className="sale-dash__cust-data-item-label">Prazo</p>
                <p
                  className={`sale-dash__cust-data-item-content ${
                    !validDateChecker(memory?.validityDate) &&
                    !!memory?.validityDate
                      ? "sale-dash__cust-data-item-content--red"
                      : !!memory?.validityDate
                      ? "sale-dash__cust-data-item-content--blue"
                      : ""
                  }`}
                >
                  {memory?.validityDate?.substring(0, 10) || "-"}
                </p>
              </Fragment>
            )}
            <p className="sale-dash__cust-data-item-label">Cliente:</p>
            <p className="sale-dash__cust-data-item-content">
              {memory?.customer?.name}
            </p>
            <p className="sale-dash__cust-data-item-label">E-mail</p>
            <p className="sale-dash__cust-data-item-content">
              {memory?.customer?.email || "-"}
            </p>
            <p className="sale-dash__cust-data-item-label">Telefone</p>
            <p className="sale-dash__cust-data-item-content">
              {memory?.customer?.whatsapp && (
                <span
                  onClick={() => {
                    openWhatsapp(
                      memory?.customer?.whatsapp?.replace(/\D/g, "")
                    );
                  }}
                  className="sale-dash__cust-data-item-subcontent sale-dash__cust-data-item-subcontent--clickable"
                >
                  {memory?.customer?.whatsapp}
                </span>
              )}
              {memory?.customer?.phone && (
                <span className="sale-dash__cust-data-item-subcontent">
                  {memory?.customer?.phone}
                </span>
              )}
              {!memory?.customer?.phone && !memory?.customer?.whatsapp && (
                <span className="sale-dash__cust-data-item-subcontent">-</span>
              )}
            </p>
            <p className="sale-dash__cust-data-item-label">
              {memory?.customer?.document?.replace(/\D/g, "")?.length === 14
                ? "CNPJ"
                : "CPF"}
            </p>
            <p className="sale-dash__cust-data-item-content">
              {memory?.customer?.document || "-"}
            </p>
            <p className="sale-dash__cust-data-item-label">Cidade</p>
            <p className="sale-dash__cust-data-item-content">
              {memory?.customer?.city || "-"}
            </p>
            <p className="sale-dash__cust-data-item-label">Endereço</p>
            {((!memory?.customer?.addressDetail?.postalCode &&
              !memory?.customer?.postalCode) ||
              !memory?.customer?.addressDetail?.street) && (
              <p className="sale-dash__cust-data-item-content">
                {memory?.customer?.address || "-"}
              </p>
            )}
            {!!(
              memory?.customer?.addressDetail?.postalCode ||
              memory?.customer?.postalCode
            ) &&
              !!memory?.customer?.addressDetail?.street && (
                <p className="sale-dash__cust-data-item-content">
                  {!!memory?.customer?.addressDetail?.postalCode && (
                    <span className="cust-dash__cust-data-item-subcontent">
                      <span className="cust-dash__cust-data-item-label">
                        CEP
                      </span>{" "}
                      -{" "}
                      {formatPostalCode(
                        memory?.customer?.addressDetail?.postalCode ||
                          memory?.customer?.postalCode
                      ) || "-"}
                    </span>
                  )}
                  {!!memory?.customer?.addressDetail?.street && (
                    <span className="cust-dash__cust-data-item-subcontent">
                      <span className="cust-dash__cust-data-item-label">
                        Log
                      </span>{" "}
                      - {memory?.customer?.addressDetail?.street || "-"}
                    </span>
                  )}
                  {!!memory?.customer?.addressDetail?.number && (
                    <span className="cust-dash__cust-data-item-subcontent">
                      <span className="cust-dash__cust-data-item-label">
                        Num
                      </span>{" "}
                      - {memory?.customer?.addressDetail?.number}
                    </span>
                  )}
                  {!!memory?.customer?.addressDetail?.district && (
                    <span className="cust-dash__cust-data-item-subcontent">
                      <span className="cust-dash__cust-data-item-label">
                        Bairro
                      </span>{" "}
                      - {memory?.customer?.addressDetail?.district}
                    </span>
                  )}
                  {!!memory?.customer?.addressDetail?.complement && (
                    <span className="cust-dash__cust-data-item-subcontent">
                      <span className="cust-dash__cust-data-item-label">
                        Comp
                      </span>{" "}
                      - {memory?.customer?.addressDetail?.complement}
                    </span>
                  )}
                </p>
              )}
            <p className="cust-dash__cust-data-item-label">Renda</p>
            <p className="cust-dash__cust-data-item-content">
              {!!memory?.customer?.income
                ? `R$ ${addThousandSeparator(memory?.customer?.income)}`
                : "-"}
            </p>
            {(type === "SALES" || type === "PRE_SALES") && (
              <Fragment>
                <p className="sale-dash__cust-data-item-label">Origem</p>
                <p className="sale-dash__cust-data-item-content">
                  {memory?.customer?.origin || "-"}
                </p>
              </Fragment>
            )}
            {type === "AFTER_SALES" && (
              <Fragment>
                <p className="sale-dash__cust-data-item-label">Potência</p>
                <p className="sale-dash__cust-data-item-content">
                  {`${addThousandSeparator(
                    proposal?.realSystemData?.power ||
                      memory?.afterSalesData?.power ||
                      0
                  )} kWp` || "-"}
                </p>
                <p className="sale-dash__cust-data-item-label">Módulos</p>
                <p className="sale-dash__cust-data-item-content">
                  {`${addThousandSeparator(
                    proposal
                      ? proposal?.pvModuleData?.length > 0
                        ? proposal?.pvModuleData
                            ?.map((m) => m.quantity)
                            ?.reduce((a, b) => a + b)
                        : 0
                      : memory?.afterSalesData?.modsQtd || 0,
                    false,
                    false
                  )} Módulo(s)` || "-"}
                </p>
                <p className="sale-dash__cust-data-item-label">Inversores</p>
                <p className="sale-dash__cust-data-item-content">
                  {`${addThousandSeparator(
                    proposal
                      ? proposal?.inverterData?.length > 0
                        ? proposal?.inverterData
                            ?.map((i) => i.iQuantity)
                            ?.reduce((a, b) => a + b)
                        : 0
                      : memory?.afterSalesData?.invQtd || 0,
                    false,
                    false
                  )} Inversor(es)` || "-"}
                </p>
                <p className="sale-dash__cust-data-item-label">
                  Topologia Inv.
                </p>
                <p className="sale-dash__cust-data-item-content">
                  {proposal
                    ? proposal?.inverterData?.[0]?.type !== "microinverter"
                      ? "String"
                      : "Micro"
                    : memory?.afterSalesData?.inverterType || "String"}
                </p>
                <p className="sale-dash__cust-data-item-label">Distância</p>
                <p className="sale-dash__cust-data-item-content">
                  {`${addThousandSeparator(
                    proposal?.realSystemData?.distance ||
                      memory?.afterSalesData?.distance ||
                      0
                  )} km` || "-"}
                </p>
                <p className="sale-dash__cust-data-item-label">Alt. Telhado</p>
                <p className="sale-dash__cust-data-item-content">
                  {`${addThousandSeparator(
                    memory?.afterSalesData?.roofHight || 0
                  )} m` || "-"}
                </p>
              </Fragment>
            )}
            {type === "SALES" && (
              <Fragment>
                <p className="sale-dash__cust-data-item-label">Propostas</p>
                <p className="sale-dash__cust-data-item-content">
                  {memory?.customer?.proposals?.length || "0"}
                </p>
              </Fragment>
            )}
            {type === "SALES" && (
              <Fragment>
                <p className="sale-dash__cust-data-item-label">Visualizações</p>
                <p className="sale-dash__cust-data-item-content">
                  {totalViews || "0"}
                </p>
              </Fragment>
            )}
            {!!memory?.lastViewTimestamp && (
              <Fragment>
                <p className="cust-dash__cust-data-item-label">
                  Última Visual.
                </p>
                <p className="cust-dash__cust-data-item-content">
                  {getDateFromTimestamp(memory?.lastViewTimestamp) || "-"}
                </p>
              </Fragment>
            )}
            <p className="sale-dash__cust-data-item-label">Notas</p>
            <p className="sale-dash__cust-data-item-content">
              {memory?.notes?.length || "0"}
            </p>
            <p className="sale-dash__cust-data-item-label">Proprietários</p>
            {(type === "SALES" || type === "PRE_SALES") && (
              <p className="sale-dash__cust-data-item-content">
                {itemOwner?.personalData?.name || "GERENTE"}
              </p>
            )}
            {type === "AFTER_SALES" && (
              <p className="sale-dash__cust-data-item-content">
                {saleMultOwnersNames?.split(" | ")?.length > 1
                  ? sliceLongString(saleMultOwnersNames?.split(" | ")?.[0], 15)
                  : saleMultOwnersNames || "GERENTE"}
                {saleMultOwnersNames.split(" | ")?.length > 1 && (
                  <MoreItems
                    content={saleMultOwnersNames.split(" | ")}
                    leftLength={
                      saleMultOwnersNames.split(" | ").slice(1)?.length
                    }
                  />
                )}
              </p>
            )}
            {type === "SALES" && (
              <Fragment>
                <p className="sale-dash__cust-data-item-label">Validade</p>
                <p className="sale-dash__cust-data-item-content">
                  {memory?.validityDate || "-"}
                </p>
              </Fragment>
            )}
            <p className="sale-dash__cust-data-item-label">Registro</p>
            <p className="sale-dash__cust-data-item-content">
              {memory?.registryDate || "-"}
            </p>
            <p className="sale-dash__cust-data-item-label">ID</p>
            <p className="sale-dash__cust-data-item-content">{memory?.id}</p>
            <div className="sale-item__legend">
              {memory?.type === "SALES" && memory?.leadTemperature >= 0 && (
                <div className="sale-item__legend-item">
                  <div
                    title={
                      memory?.leadTemperature === 2
                        ? "Lead Quente"
                        : memory?.leadTemperature === 1
                        ? "Lead Morno"
                        : "Lead Frio"
                    }
                    className={`sale-item__indicator sale-item__indicator-legend ${
                      memory?.leadTemperature === 2
                        ? "sale-item__indicator-hot"
                        : memory?.leadTemperature === 1
                        ? "sale-item__indicator-warm"
                        : "sale-item__indicator-cold"
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={
                        memory?.leadTemperature === 2
                          ? faFire
                          : memory?.leadTemperature === 1
                          ? faMugHot
                          : faSnowflake
                      }
                      style={{
                        fontSize: "1.1rem",
                      }}
                    />
                  </div>
                  <p className="sale-item__legend-txt">
                    {memory?.leadTemperature === 2
                      ? "Lead Quente"
                      : memory?.leadTemperature === 1
                      ? "Lead Morno"
                      : "Lead Frio"}
                  </p>
                </div>
              )}
              {!!preApprovedFins?.length && memory?.type === "SALES" && (
                <div className="sale-item__legend-item">
                  <div
                    title="Financiamento Pré-Aprovado"
                    className={`sale-item__indicator sale-item__indicator-legend sale-item__indicator-fin`}
                  >
                    <AttachMoneyRoundedIcon />
                  </div>
                  <p className="sale-item__legend-txt">
                    Financiamento Pré-Aprovado
                  </p>
                </div>
              )}
              {!!memory?.customer?.proposals?.length &&
                memory?.type === "SALES" && (
                  <div className="sale-item__legend-item">
                    <div
                      title="Proposta Gerada"
                      className={`sale-item__indicator sale-item__indicator-legend sale-item__indicator-props`}
                    >
                      <ViewListRoundedIcon />
                    </div>
                    <p className="sale-item__legend-txt">Proposta Gerada</p>
                  </div>
                )}
              {!!memory?.customer?.proposals?.filter((prop) => {
                return prop?.views > 0;
              })?.length &&
                memory?.type === "SALES" && (
                  <div className="sale-item__legend-item">
                    <div
                      title="Proposta Visualizada"
                      className={`sale-item__indicator sale-item__indicator-legend sale-item__indicator-views `}
                    >
                      <VisibilityRoundedIcon />
                    </div>
                    <p className="sale-item__legend-txt">
                      Proposta Visualizada
                    </p>
                  </div>
                )}
              {!validDateChecker(memory?.validityDate) &&
                !!memory?.validityDate &&
                memory?.type === "SALES" && (
                  <div className="sale-item__legend-item">
                    <div
                      title="Validade Expirou"
                      className={`sale-item__indicator sale-item__indicator-legend sale-item__indicator-expired`}
                    >
                      <EventBusyRoundedIcon />
                    </div>
                    <p className="sale-item__legend-txt">Validade Expirou</p>
                  </div>
                )}
              {!!column &&
                getSaleIsInactive(
                  column?.inactivityWindow || 24,
                  memory?.lastActivityTimestamp ||
                    memory?.registryTimestamp ||
                    0
                ) && (
                  <div className="sale-item__legend-item">
                    <div
                      title={`Inativo Há Mais de ${
                        Math.ceil(
                          column?.inactivityWindow / 24 || 1
                        )?.toString() || "1"
                      } Dia(s)`}
                      className={`sale-item__indicator sale-item__indicator-legend sale-item__indicator-inactive `}
                    >
                      <WatchLaterRoundedIcon />
                    </div>
                    <p className="sale-item__legend-txt">{`Inativo Há Mais de ${
                      Math.ceil(
                        column?.inactivityWindow / 24 || 1
                      )?.toString() || "1"
                    } Dia(s)`}</p>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

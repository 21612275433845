import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { teal } from "@material-ui/core/colors";

export const ButtonCyanContained = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: "#189ab4",
    "&:hover": {
      backgroundColor: "#13768a",
    },
  },
}))(Button);

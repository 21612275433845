import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { teal } from "@material-ui/core/colors";

export const ButtonDarkBlueContained = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: "#05445e",
    "&:hover": {
      backgroundColor: "#042e40",
    },
  },
}))(Button);

import { makeStyles } from "@material-ui/core/styles";

export const PopoverFormStyles = makeStyles((theme) => ({
  formRoot: {
    position: "relative",
    width: "380px",
    padding: "20px 10px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
    "& > *": {
      margin: theme.spacing(1),
      width: "365px",
      [theme.breakpoints.down("xs")]: {
        width: "280px",
      },
    },
  },
  formRoot2: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "1rem",
    boxSizing: "border-box",
    padding: "20px",
  },
  formRoot3: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "0",
    boxSizing: "border-box",
    padding: "30px 20px 20px",
  },
  containerNoOverflow: {
    overflowX: "visible!important" as any,
    overflowY: "visible!important" as any,
    "& > *": {
      overflowX: "visible!important" as any,
      overflowY: "visible!important" as any,
    },
    "& > .MuiPopover-paper": {
      overflowX: "visible!important" as any,
      overflowY: "visible!important" as any,
    },
  },
  gridCoordinates: {
    display: "grid",
    gridTemplateColumns: "40% 54%",
    gridGap: "6%",
  },
  centeredContainer: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "center",
  },
  inputContainer: {
    width: "100%",
    "& > *": {
      width: "100%",
    },
    marginBottom: "1.5rem",
  },
  groupContainer: {
    position: "relative",
    padding: "1.9rem 1rem 1.2rem",
    margin: "1rem 0",
    borderRadius: "10px",
    border: "1px solid #05445e",
  },
  groupContainerLast: {
    margin: "0.85rem 0 2rem",
  },
  groupContainerLabel: {
    backgroundColor: "#fff",
    display: "block",
    padding: "0 0.5rem",
    fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
    fontWeight: 700,
    position: "absolute",
    top: "-0.7rem",
    left: "1rem",
    color: "#05445e",
  },
}));

import { makeStyles } from "@material-ui/core/styles";

const desktopWidth = "400px";
const desktopMinHeight = "300px";
const mobileWidth = "95%";
const mobileMaxWidth = "380px";
const mobileMinHeight = "280px";

export const CardStyles = makeStyles((theme) => ({
  title: {
    color: "#08a9c9",
  },
  root: {
    margin: "0",
    padding: "0",
    display: "flex",
    justifyContent: "center",
    "& .simpleCard": {
      margin: "10% auto",
      width: desktopWidth,
      padding: "1rem 0",
      borderRadius: "5px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
        maxWidth: mobileMaxWidth,
        minHeight: mobileMinHeight,
        margin: "30% auto",
      },
    },
    "& .aboutCard": {
      margin: "3rem auto",
      width: desktopWidth,
      minHeight: desktopMinHeight,
      padding: "1rem 0",
      borderRadius: "5px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      [theme.breakpoints.down("xs")]: {
        width: mobileWidth,
        maxWidth: mobileMaxWidth,
        minHeight: mobileMinHeight,
        margin: "2rem auto 5rem auto",
      },
    },
    "& .mediumCard": {
      padding: "1rem 0 1.5rem",
      margin: "2.5rem 0 4rem",
      borderRadius: "5px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      boxSizing: "border-box",
      width: "calc(100% - 80px)",
      maxWidth: "1080px",
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 60px)",
      },
      [theme.breakpoints.down("xs")]: {
        width: "calc(100% - 30px)",
        margin: "2rem 0 3rem",
      },
    },
    "& .websiteCard": {
      padding: "1rem 0 1.5rem",
      borderRadius: "5px",
      // boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)",
      border: "1px solid #cdcdcd",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      boxSizing: "border-box",
      width: "1080px",
      [theme.breakpoints.down("md")]: {
        width: "900px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "500px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "330px",
      },
    },
    "& .infoCard": {
      width: "100%",
      minHeight: "100px",
      padding: "1rem 0.8rem",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)",
      position: "relative",
      marginTop: "20px",
      backgroundColor: "#fff",
      [theme.breakpoints.down("xs")]: {
        width: mobileWidth,
        maxWidth: mobileMaxWidth,
      },
    },
    "& .infoCardSimple": {
      width: "100%",
      minHeight: "100px",
      padding: "1rem 0.8rem",
      borderRadius: "5px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)",
      position: "relative",
      margin: "7px 0",
      backgroundColor: "#fff",
      [theme.breakpoints.down("xs")]: {
        width: mobileWidth,
        maxWidth: mobileMaxWidth,
      },
    },
    "& .searchCard": {
      boxSizing: "border-box",
      width: "1240px",
      padding: "1rem 2rem 1.5rem",
      borderRadius: "5px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)",
      position: "relative",
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "4rem",
      [theme.breakpoints.down("lg")]: {
        width: "920px",
      },
      [theme.breakpoints.down("md")]: {
        width: "650px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "450px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "320px",
      },
      "& button": {
        margin: "1rem 0 0",
      },
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
    },
    "& .searchCardNoMarginBottom": {
      boxSizing: "border-box",
      width: "1240px",
      padding: "1rem 2rem 1.5rem",
      borderRadius: "5px",
      boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.25)",
      position: "relative",
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      [theme.breakpoints.down("lg")]: {
        width: "920px",
      },
      [theme.breakpoints.down("md")]: {
        width: "650px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "450px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "320px",
      },
      "& button": {
        margin: "1rem 0 0",
      },
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
    },
    "& .funnelSearchCard": {
      width: "300px",
      minHeight: "100px",
      padding: "1.5rem 1.2rem",
      borderRadius: "5px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)",
      position: "relative",
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        minWidth: "280px",
        width: mobileWidth,
        maxWidth: mobileMaxWidth,
      },
      "& button": {
        marginTop: "1rem",
      },
    },
    "& .tinyCard": {
      margin: "10% auto",
      width: "360px",
      minHeight: "200px",
      padding: "1rem 0",
      borderRadius: "5px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      [theme.breakpoints.down("xs")]: {
        width: "95%",
        maxWidth: "360px",
        minHeight: "200px",
      },
    },
    "& .extraTinyCard": {
      boxSizing: "border-box",
      // margin: "2rem auto",
      width: "360px",
      padding: "2rem 0",
      borderRadius: "5px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      [theme.breakpoints.down("xs")]: {
        width: "320px",
      },
    },
    "& .simpleForm": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      width: "calc(100% - 60px)",
      [theme.breakpoints.down("xs")]: {
        width: "calc(100% - 40px)",
      },
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
    },
    "& .signupCard": {
      borderRadius: "5px",
      padding: "1rem 0",
      margin: "2rem 0 0",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      width: "calc(100% - 80px)",
      maxWidth: "1080px",
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 60px)",
      },
      [theme.breakpoints.down("xs")]: {
        width: "calc(100% - 30px)",
      },
    },
    "& .signupCard100": {
      borderRadius: "5px",
      padding: "1rem 0",
      margin: "2rem 0 0",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      width: "100%",
    },
    "& .signupForm": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      "& > *": {
        margin: theme.spacing(1),
        width: "90%",
      },
    },
  },
}));

import { Fragment } from "react";
import { AuthContextProps, SettingsContextProps } from "../../../shared/data/types";
import { HomeDashboardGenSales } from "./HomeDashboardGenSales";
import { HomeDashboardClosedSales } from "./HomeDashboardClosedSales";
import { HomeDashboardConvRates } from "./HomeDashboardConvRates";
import { HomeDashboardSalesInProgress } from "./HomeDashboardSalesInProgress";
import { HomeDashboardSalesActivity } from "./HomeDashboardSalesActivity";
import { HomeDashboardSalesAmounts } from "./HomeDashboardSalesAmounts";
import { HomeDashboardSoldPower } from "./HomeDashboardSoldPower";
import "./HomeDashboardTabSales.scss";


interface HomeDashboardTabSalesProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  fetchId: string;
  loadedGenSales: {
    label: string;
    val: number;
    sales: number;
    delta: number;
    deltaIsPositive: boolean;
  }[];
  setLoadedGenSales: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        val: number;
        sales: number;
        delta: number;
        deltaIsPositive: boolean;
      }[]
    >
  >;
  setIsLoadingGenSales: React.Dispatch<React.SetStateAction<boolean>>;
  loadedClosedSales: {
    label: string;
    val: number;
    sales: number;
    power: number;
    delta: number;
    deltaIsPositive: boolean;
  }[];
  setLoadedClosedSales: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        val: number;
        sales: number;
        power: number;
        delta: number;
        deltaIsPositive: boolean;
      }[]
    >
  >;
  setIsLoadingClosedSales: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingGenSales: boolean;
  isLoadingClosedSales: boolean;
  loadedSalesInProgress: {
    totalSales: number;
    totalAmount: number;
    totalPotProfit: number;
  };
  setLoadedSalesInProgress: React.Dispatch<
    React.SetStateAction<{
      totalSales: number;
      totalAmount: number;
      totalPotProfit: number;
    }>
  >;
}

export const HomeDashboardTabSales = (props: HomeDashboardTabSalesProps) => {
  const {
    settingsCtx, 
    auth, 
    fetchId, 
    loadedGenSales, 
    setLoadedGenSales, 
    setIsLoadingGenSales, 
    loadedClosedSales, 
    setIsLoadingClosedSales, 
    setLoadedClosedSales, 
    isLoadingGenSales, 
    isLoadingClosedSales,
    loadedSalesInProgress,
    setLoadedSalesInProgress
  } = props;
  return (
    <Fragment>
      <HomeDashboardGenSales
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        loadedData={loadedGenSales}
        setLoadedData={setLoadedGenSales}
        setIsLoadingGenSales={setIsLoadingGenSales}
      />
      <HomeDashboardClosedSales
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        loadedData={loadedClosedSales}
        setLoadedData={setLoadedClosedSales}
        setIsLoadingClosedSales={setIsLoadingClosedSales}
      />
      <HomeDashboardConvRates
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        genSales={
          loadedGenSales?.map((gs) => {
            return gs?.sales || 0;
          }) || [0, 0, 0]
        }
        closedSales={
          loadedClosedSales?.map((cs) => {
            return cs?.sales || 0;
          }) || [0, 0, 0]
        }
        isLoadingGenSales={isLoadingGenSales}
        isLoadingClosedSales={isLoadingClosedSales}
      />
      <HomeDashboardSalesInProgress
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        loadedData={loadedSalesInProgress}
        setLoadedData={setLoadedSalesInProgress}
        setIsLoadingClosedSales={setIsLoadingClosedSales}
      />
      <HomeDashboardSalesActivity
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        genSales={
          loadedGenSales?.map((gs) => {
            return gs?.sales || 0;
          }) || [0, 0, 0]
        }
        closedSales={
          loadedClosedSales?.map((cs) => {
            return cs?.sales || 0;
          }) || [0, 0, 0]
        }
        isLoadingGenSales={isLoadingGenSales}
        isLoadingClosedSales={isLoadingClosedSales}
      />
      <HomeDashboardSalesAmounts
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        closedSalesAmounts={
          loadedClosedSales?.map((cs) => {
            return cs?.val || 0;
          }) || [0, 0, 0]
        }
        isLoadingClosedSales={isLoadingClosedSales}
      />
      <HomeDashboardSoldPower
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        loadedData={loadedClosedSales}
        isLoadingClosedSales={isLoadingClosedSales}
      />
    </Fragment>
  )
}
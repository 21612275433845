import React, { useContext, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import { Input } from "../../shared/components/FormElements/Input";
import { InputPassword } from "../../shared/components/FormElements/InputPassword";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { Card } from "../../shared/components/UIElements/Card";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { useForm } from "../../shared/hooks/formHook";
import { AuthContext } from "../../shared/context/authContext";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { useQuery } from "../../shared/util/useQuery";
import { logUser } from "../api/userAPI";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_PASSWORD,
} from "../../shared/util/validators";
import { History } from "../../shared/data/types";

import { UserFormsStyles } from "./UserFormsStyles";

export default function UserLogin() {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const classes = UserFormsStyles();
  const email = useQuery().get("email") || "";
  const psd = useQuery().get("psd") || "";
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const { formState, inputHandler } = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    if (!!email && !!psd) {
      logUser({
        email: email,
        password: psd,
        auth,
        sendRequest,
        history: history as History,
      });
    }
  }, [psd, email]);

  const authHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    logUser({
      email: formState.inputs.email.value as string,
      password: formState.inputs.password.value as string,
      auth,
      sendRequest,
      history: history as History,
    });
  };

  return (
    <React.Fragment>
      <ModalError error={error} onClear={clearError} />
      <Card cardType="simpleCard">
        {isLoading && <LoadingSpinnerOverlayRegular />}
        <form onSubmit={authHandler} className="simpleForm">
          <Typography className={classes.title} variant="h5">
            Login de Usuário
          </Typography>
          <Input
            id="email"
            type="email"
            label="email"
            variant="outlined"
            validators={[VALIDATOR_EMAIL()]}
            helperText="Insira o seu e-mail cadastrado."
            onInput={inputHandler}
            initialValue={localStorage?.getItem("loginEmail") || ""}
            initialValid={!!localStorage?.getItem("loginEmail")}
          />
          <InputPassword
            id="password"
            label="senha"
            validators={[VALIDATOR_PASSWORD()]}
            helperText="Min. 8 caracteres, letras maiúsculas, minúsculas e números."
            onInput={inputHandler}
            labelWidth={50}
            initialValue={localStorage?.getItem("loginPsd") || ""}
            initialValid={!!localStorage?.getItem("loginPsd")}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!formState.isValid}
          >
            FAZER LOGIN
          </Button>
          <NavLink to={`/recuperacao-de-acesso`}>
            <Typography className={classes.subtitle} variant="subtitle1">
              Esqueci minha senha
            </Typography>
          </NavLink>
          <a href="https://politica.azume.com.br" target="_blank">
            <Typography className={classes.subtitle} variant="subtitle1">
              Termos de Uso e Política de Privacidade
            </Typography>
          </a>
        </form>
      </Card>
    </React.Fragment>
  );
}

import { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import { PopoverFormGenerareContract } from "./PopoverFormGenerareContract";
import { SaleDashContractItem } from "./SaleDashContractItem";
import { AuthContextProps, KanbanFilledData } from "../../shared/data/types";

import "./SaleDash.scss";

interface SaleDashContractsProps {
  contracts: KanbanFilledData["items"][0]["contracts"];
  auth: AuthContextProps;
  itemId: string;
  item: KanbanFilledData["items"][0];
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const SaleDashContracts = (props: SaleDashContractsProps) => {
  const { auth, contracts, itemId, item, setKanbanData } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | HTMLLIElement>(
    null
  );

  return (
    <Fragment>
      <PopoverFormGenerareContract
        itemId={itemId}
        item={item}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        auth={auth}
        setKanbanData={setKanbanData}
      />
      {contracts?.length === 0 && (
        <p className="sale-dash__col-right-content-empty">
          Este cliente não possui nenhum contrato gerado
        </p>
      )}
      <div className="sale-dash__history">
        {contracts?.length > 0 &&
          contracts
            ?.sort((a, b) => {
              return (b?.registryTimestamp || 0) - (a?.registryTimestamp || 0);
            })
            .map((it, i) => {
              return (
                <SaleDashContractItem
                  auth={auth}
                  loadedContract={it}
                  saleId={itemId}
                  loadedSale={item}
                  setKanbanData={setKanbanData}
                  key={`contract-${i}`}
                />
              );
            })}
      </div>
      <div className="sale-dash__col-right-add-item-btn">
        <IconButton
          title="Gerar Contrato"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <AddRoundedIcon style={{ color: "#043a50" }} />
        </IconButton>
      </div>
    </Fragment>
  );
};

import { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";

import { WarningBar } from "../../../shared/components/Navigation/WarningBar";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { ModalNotification } from "../../../shared/components/UIElements/ModalNotification";
import { ModalCommunication } from "../../../shared/components/UIElements/ModalCommunication";
import {
  AddonOmnichannelStatus,
  AddonOmnichannelType,
  AddonType,
  AuthContextProps,
  WppAccountStatus,
} from "../../../shared/data/types";
import { SettingsContext } from "../../../shared/context/settingsContext";

interface HomeModalsProps {
  needCriticalUpdate: boolean;
  needUpdate: boolean;
  error: string;
  clearError: (needPush?: boolean) => void;
  auth: AuthContextProps;
}

export const HomeModals = (props: HomeModalsProps) => {
  const { needCriticalUpdate, needUpdate, error, clearError, auth } = props;
  const history = useHistory();
  const settingsCtx = useContext(SettingsContext);

  // const hasPermissionToUseWppAccounts = settingsCtx.addons.some(
  //   (addon) =>
  //     addon.type === AddonType.OMNICHANNEL &&
  //     addon.item.type === AddonOmnichannelType.WHATSAPP &&
  //     addon.item.status === AddonOmnichannelStatus.ACTIVE
  // );

  // const isVendorWppAccountDisconnected = settingsCtx.wppAccounts?.some(
  //   (account) =>
  //     "vendor" in account &&
  //     account.vendor &&
  //     typeof account?.vendor === "object" &&
  //     account?.vendor?._id === auth?.userId &&
  //     account?.status === WppAccountStatus.DISCONNECTED
  // );
  // const hasWppAccountsDisconnected =
  //   auth.type === "MANAGER" &&
  //   settingsCtx.wppAccounts?.filter(
  //     (account) => account?.status === WppAccountStatus.DISCONNECTED
  //   );

  // const getWppAccountDisconnectedMessage = (): string | JSX.Element => {
  //   if (isVendorWppAccountDisconnected)
  //     return (
  //       <span>
  //         Conta WhatsApp <b>NÃO CONECTADA</b>, por favor, conecte-a para que as
  //         mensagens sejam sincronizadas.
  //       </span>
  //     );

  //   if (hasWppAccountsDisconnected.length)
  //     return (
  //       <span>
  //         Há {hasWppAccountsDisconnected.length} conta(s) WhatsApp{" "}
  //         <b>NÃO CONECTADA(S)</b>, por favor, conecte-a(s) para que as mensagens
  //         sejam sincronizadas.
  //       </span>
  //     );

  //   return "";
  // };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ModalNotification auth={auth} />
      {/* <ModalCommunication /> */}
      {/* {auth.statusOk &&
        hasPermissionToUseWppAccounts &&
        (isVendorWppAccountDisconnected ||
          !!hasWppAccountsDisconnected.length) && (
          <WarningBar
            text={getWppAccountDisconnectedMessage()}
            clickableText={
              isVendorWppAccountDisconnected ? "VER STATUS" : "VER CONTAS"
            }
            clickAction={() => {
              if (isVendorWppAccountDisconnected) {
                history.push("/conta/whatsapp");
              } else {
                history.push("/configuracoes", { openInSection: "23" });
              }
            }}
            customColor="#005c4b"
          />
        )} */}
      {auth.statusOk && needCriticalUpdate && (
        <WarningBar
          text={
            <span>
              <u>Atualização Importante</u>! <b>FECHE E ABRA</b> o Azume (todas
              abas) até que essa mensagem DESAPAREÇA.
            </span>
          }
          clickableText="DETALHES DA ATUALIZAÇÃO"
          clickAction={() => {
            history.push("/atualizacoes");
          }}
          altColor
        />
      )}
      {auth.statusOk && needUpdate && !needCriticalUpdate && (
        <WarningBar
          text={
            <span>
              <u>Atualização Disponível</u>! <b>FECHE E ABRA</b> o Azume (todas
              abas) até que essa mensagem DESAPAREÇA.
            </span>
          }
          clickableText="DETALHES DA ATUALIZAÇÃO"
          clickAction={() => {
            history.push("/atualizacoes");
          }}
          blueColor
        />
      )}
      {auth.isLoggedIn && !auth.statusOk && (
        <WarningBar
          text="A sua situação de pagamento encontra-se irregular. Devido a isto, foram removidas as funcionalidades do aplicativo até que você possa"
          clickableText="REGULARIZAR A SITUAÇÃO"
          clickAction={() => {
            history.push("/financeiro");
          }}
        />
      )}
    </Fragment>
  );
};

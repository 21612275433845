import { useEffect, useState } from "react";
import { Chart, PieSeries } from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import { Palette } from "@devexpress/dx-react-chart";

import { ModalZeroResults } from "../../../shared/components/UIElements/ModalZeroResults";
import { AuthContext } from "../../../shared/context/authContext";
import { LoadingSpinnerFullScreenGear } from "../../../shared/components/UIElements/LoadingSpinnerFullScreenGear";
import { ButtonLoadMore } from "../../../shared/components/UIElements/ButtonLoadMore";
import { getPastDaysFromDate } from "../../../shared/util/getDaysFromDate";
import { PopoverAddBTGRegistrationRequest } from "../../../sales/components/PopoverAddBTGRegistrationRequest";
import { FinancingBTGWarning } from "../../../sales/components/FinancingBTGWarning";
import { useHttpClient } from "../../../shared/hooks/httpHook";
import { SaleDash } from "../../../sales/components/SaleDash";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { HomeDashboardFinancingItem } from "./HomeDashboardFinancingItem";
import { addThousandSeparator } from "../../../shared/util/addThousandSeparator";
import { getMonthNameAndYear } from "../../../shared/util/getMonthNameAndYear";
import { getPaginatedFinancings } from "../../../financings/api/financingsAPI";
import {
  checkIfIntegratorCanReqBTGFin,
  getUserRegistration,
} from "../../../sales/api/financingsAPI";
import {
  AuthContextProps,
  SettingsContextProps,
  FinancingData,
  RegistrationData,
  ExpiredFinsData,
} from "../../../shared/data/types";

import "./HomeDashboard.scss";

interface HomeDashboardFinancingsProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  fetchId: string;
}

export const HomeDashboardFinancings = (
  props: HomeDashboardFinancingsProps
) => {
  const { settingsCtx, auth, fetchId } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const [loadedData, setLoadedData] = useState<FinancingData[]>(null);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [expiredFinData, setExpiredFinData] = useState<ExpiredFinsData>(null);
  const [multiplier, setMultiplier] = useState<number>(0);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [loadedRegistration, setLoadedRegistration] =
    useState<RegistrationData>(null);
  const [registrationCheck, setRegistrationCheck] = useState<boolean>(false);
  const [canFinanceInBTG, setCanFinanceInBTG] = useState<boolean>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLButtonElement>(null);

  useEffect(() => {
    checkIfIntegratorCanReqBTGFin({ sendRequest, auth, setCanFinanceInBTG });
    getUserRegistration({
      sendRequest,
      auth,
      setLoadedRegistration,
      setRegistrationCheck,
    });
  }, []);

  useEffect(() => {
    if (!!auth.token) {
      getPaginatedFinancings({
        sendRequest,
        auth,
        hidration: true,
        setLoadedData,
        setShowLoadMore,
        multiplier: 0,
        setFirstLoad,
        order: "LAST_UPDATED",
        preApprovedOnly: "true",
        fetchId,
        settingsCtx,
        setExpiredFinData,
      });
    }
  }, [fetchId, auth?.token]);

  const getPaginatedFinancingsHandler = async (mult: number) => {
    await getPaginatedFinancings({
      sendRequest,
      auth,
      hidration: false,
      setLoadedData,
      setShowLoadMore,
      multiplier: mult,
      order: "LAST_UPDATED",
      preApprovedOnly: "true",
      fetchId,
      settingsCtx,
      setExpiredFinData,
    });
  };

  const canFinance =
    typeof canFinanceInBTG === "boolean" || !!loadedRegistration?.status
      ? canFinanceInBTG || loadedRegistration?.status === "Cadastro Efetuado"
      : null;

  return (
    <div className="dashboard-item dashboard-item--financings">
      {(isLoading ||
        typeof canFinance !== "boolean" ||
        !registrationCheck ||
        !loadedData) && <LoadingSpinnerOverlayRegular />}
      {/* <ModalError error={error} onClear={clearError} /> */}
      <PopoverAddBTGRegistrationRequest
        anchorEl={anchorEl2}
        setAnchorEl={setAnchorEl2}
        auth={auth}
        setLoadedRegistration={setLoadedRegistration}
      />
      <p className="dashboard-item__title dashboard-item__title--padded">
        Últimas Atualizações de Financiamentos Pré-Aprovados
      </p>
      {((typeof canFinance === "boolean" && !canFinance && registrationCheck) ||
        (!!expiredFinData?.expiredFinancings &&
          expiredFinData?.expiredFinancings > 0)) && (
        <FinancingBTGWarning
          canFinanceInBTG={canFinance}
          loadedRegistration={loadedRegistration}
          auth={auth}
          setLoadedRegistration={setLoadedRegistration}
          expiredFinData={expiredFinData}
        />
      )}
      <div className="dashboard-item__list dashboard-item__list--max-hight-lg">
        {typeof canFinance === "boolean" &&
          canFinance &&
          registrationCheck &&
          loadedData?.length > 0 &&
          loadedData?.map((item, i) => {
            return (
              <HomeDashboardFinancingItem
                auth={auth}
                financing={item}
                setLoadedData={setLoadedData}
                key={`financing-${i}`}
              />
            );
          })}
      </div>
      {loadedData &&
        loadedData?.length === 0 &&
        typeof canFinance === "boolean" &&
        canFinance &&
        registrationCheck && (
          <div className="dashboard-item__no-data">
            <img
              className="dashboard-item__no-data-img"
              src={`${process.env.PUBLIC_URL}/nenhum-dado.png`}
              alt=""
            />
          </div>
        )}
      <ButtonLoadMore
        setMultiplier={setMultiplier}
        showLoadMore={showLoadMore}
        isLoading={isLoading}
        fetchItems={getPaginatedFinancingsHandler}
      />
    </div>
  );
};

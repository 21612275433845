import { AuthContextProps, NotePopCustData, SettingsContextProps } from "../../../shared/data/types";
import { HomeDashboardNextSchedules } from "./HomeDashboardNextSchedules";
import "./HomeDashboardTabSchedule.scss";

interface HomeDashboardTabScheduleProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  fetchId: string;
  loadedNextSchedules: NotePopCustData[];
  setLoadedNextSchedules: React.Dispatch<React.SetStateAction<NotePopCustData[]>>;
}
export const HomeDashboardTabSchedule = (props: HomeDashboardTabScheduleProps) => {
  const {settingsCtx, auth, fetchId, loadedNextSchedules, setLoadedNextSchedules} = props;
  return (
    <HomeDashboardNextSchedules
      settingsCtx={settingsCtx}
      auth={auth}
      fetchId={fetchId}
      loadedData={loadedNextSchedules}
      setLoadedData={setLoadedNextSchedules}
    />
  )
}
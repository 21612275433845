import { useState, useEffect, useCallback, useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";

import { AuthContext } from "../../context/authContext";
import { ModalErrorStyles } from "./ModalErrorStyles";

import "./ModalError.scss";

interface ModalErrorProps {
  error: string;
  needPush?: boolean;
  onClear: (needPush: boolean) => void;
  title?: string;
}

export const ModalError = (props: ModalErrorProps) => {
  const { error, needPush, onClear, title = "ERRO" } = props;
  const { logout } = useContext(AuthContext);
  const history = useHistory();
  const classes = ModalErrorStyles();
  const [open, setOpen] = useState(false);

  const closeHandler = useCallback(() => {
    setOpen(false);
    if (error.includes("Acesso proibido")) {
      logout();
      history.push("/login");
    }

    setTimeout(() => {
      onClear(needPush ? true : false);
    }, 200);
  }, [needPush, onClear]);

  const openHandler = useCallback(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    if (!!error) {
      openHandler();
    }
  }, [error, openHandler]);

  return (
    <Dialog style={{ zIndex: 10002 }} open={open} onClose={closeHandler}>
      <DialogTitle className={classes.errorModalTitle}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          className={classes.errorModalContent + " " + "modal-error__content"}
        >
          {!error?.toLowerCase()?.includes("fetch") &&
          !error?.toLowerCase()?.includes("event")
            ? error
            : "Ocorreu um erro indeterminado. Por favor, tente novamente."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler} color="secondary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { Fragment, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import IconButton from "@material-ui/core/IconButton";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import CloudDownloadRoundedIcon from "@material-ui/icons/CloudDownloadRounded";

import { ModalError } from "../../shared/components/UIElements/ModalError";
import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { PopoverCreateFile } from "./PopoverCreateFile";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { downloadUrl } from "../../shared/util/downloadUrl";
import { deleteFile } from "../api/archiveAPI";
import { ArchiveData, AuthContextProps } from "../../shared/data/types";

interface FileItemProps {
  file: ArchiveData["files"][0];
  auth: AuthContextProps;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
  index: number;
  isLoadingParent: boolean;
  cid: string;
}

export const FileItem = (props: FileItemProps) => {
  const { file, auth, setLoadedArchive, index, isLoadingParent, cid } = props;
  const { sendRequest, error, isLoading, clearError } = useHttpClient();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [anchorElFormFile, setAnchorElFormFile] =
    useState<null | HTMLButtonElement>(null);

  const closeConfirmHandler = () => {
    setConfirmDelete(false);
  };

  const confirmHandler = () => {
    deleteFile({ sendRequest, auth, fileId: file._id, cid, setLoadedArchive });
    setConfirmDelete(false);
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ModalConfirm
        open={confirmDelete}
        closeHandler={closeConfirmHandler}
        confirmHandler={confirmHandler}
        message="Deseja excluir permanentemente este arquivo?"
      />
      <PopoverCreateFile
        id="popover-edit-file"
        anchorEl={anchorElFormFile}
        setAnchorEl={setAnchorElFormFile}
        auth={auth}
        sendRequest={sendRequest}
        setLoadedArchive={setLoadedArchive}
        apiCall="edit"
        folderId={file.folder}
        fileId={file._id}
        file={file}
        cid={cid}
      />
      <Draggable
        draggableId={file._id}
        isDragDisabled={isLoading}
        index={index}
      >
        {(provided, snapshot) => {
          return (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              className={`table__secondary-item ${
                snapshot.isDragging ? "table__secondary-item--is-dragging" : ""
              }`}
            >
              {isLoading && <LoadingSpinnerOverlayRegular infoCard />}
              <div
                style={{ color: "#043a50" }}
                className="table__secondary-item-main-info"
              >
                <p
                  className="table__secondary-item-title"
                  title={file?.name || ""}
                >
                  {file?.name || ""}
                </p>
              </div>
              <div className="table__secondary-item-actions">
                <IconButton
                  style={{ color: "#043a50" }}
                  size="small"
                  title="Download"
                  className={`table__item-action`}
                  onClick={() => {
                    downloadUrl(
                      `${process.env.REACT_APP_ASSET_URL}/${file.url}`
                    );
                  }}
                >
                  <CloudDownloadRoundedIcon style={{ fontSize: "18px" }} />
                </IconButton>
                <IconButton
                  style={{ color: "#043a50" }}
                  size="small"
                  title="Editar"
                  className={`table__item-action`}
                  onClick={(e) => {
                    setAnchorElFormFile(
                      e.currentTarget as unknown as HTMLButtonElement
                    );
                  }}
                >
                  <EditRoundedIcon style={{ fontSize: "18px" }} />
                </IconButton>
                <IconButton
                  style={{ color: "#043a50" }}
                  size="small"
                  title="Excluir"
                  className={`table__item-action`}
                  onClick={() => {
                    setConfirmDelete(true);
                  }}
                >
                  <DeleteForeverRoundedIcon style={{ fontSize: "18px" }} />
                </IconButton>
              </div>
            </div>
          );
        }}
      </Draggable>
    </Fragment>
  );
};

import Popover from "@material-ui/core/Popover";
import React, { Fragment, useContext } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useHistory } from "react-router-dom";

import { ButtonTealContained } from "../../shared/components/MUIThemed/ButtonTealContained";
import { FileUploadSemantic } from "../../shared/components/FormElements/FileUploadSemantic";
import { useForm } from "../../shared/hooks/formHook";
import { SettingsContext } from "../../shared/context/settingsContext";
import { openWhatsapp } from "../../shared/util/openWhatsapp";
import {
  ArchiveData,
  AuthContextProps,
  FinancingData,
} from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface FormUploadDocsProps {
  auth: AuthContextProps;
  financing: FinancingData;
  setLoadedData: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  isLoading: boolean;
  uniqueFinProps: FinancingData["proposals"];
  setTab: React.Dispatch<React.SetStateAction<number>>;
  isSmallWidth: boolean;
}

export const FormUploadDocs = (props: FormUploadDocsProps) => {
  const {
    auth,
    financing,
    sendRequest,
    setLoadedData,
    isLoading,
    uniqueFinProps,
    setTab,
    isSmallWidth,
  } = props;
  const classes = PopoverFormStyles();
  const settingsCtx = useContext(SettingsContext);
  const { formState, inputHandler, reportInvalid } = useForm({}, false);
  const history = useHistory();

  const customerArchive = financing.customer?.archive as ArchiveData;

  const cnhFile = customerArchive?.files?.find((f) => f.semantic === "CNH");
  const rgFile = customerArchive?.files?.find((f) => f.semantic === "RG");
  const energyBillFile = customerArchive?.files?.find(
    (f) => f.semantic === "Conta de Energia"
  );
  const proofOfIncomeFile = customerArchive?.files?.find(
    (f) => f.semantic === "Comprovante de Renda"
  );

  const openWhatsappHandler = () => {
    openWhatsapp(
      "31986281765",
      "Quero contratar um financiamento pré-aprovado."
    );
  };

  return (
    <Fragment>
      <form style={{ zIndex: 502 }} className={classes.formRoot3}>
        <FileUploadSemantic
          fid={financing?._id}
          setLoadedFinancings={setLoadedData}
          cid={financing?.customer?._id}
          initFile={cnhFile}
          initialFileName={cnhFile?.name || cnhFile?.semantic}
          label="CNH do Cliente"
          fileSemantic="CNH"
          folderSemantic="Financiamento"
          helperText="CNH"
          isSmallWidth={isSmallWidth}
          useStatus
        />
        <FileUploadSemantic
          fid={financing?._id}
          setLoadedFinancings={setLoadedData}
          cid={financing?.customer?._id}
          initFile={rgFile}
          initialFileName={rgFile?.name || rgFile?.semantic}
          label="RG do Cliente"
          fileSemantic="RG"
          folderSemantic="Financiamento"
          helperText="RG"
          isSmallWidth={isSmallWidth}
          useStatus
        />
        <FileUploadSemantic
          fid={financing?._id}
          setLoadedFinancings={setLoadedData}
          cid={financing?.customer?._id}
          initFile={energyBillFile}
          initialFileName={energyBillFile?.name || energyBillFile?.semantic}
          label="Conta de Luz (atualizada)"
          fileSemantic="Conta de Energia"
          folderSemantic="Financiamento"
          helperText="Conta do Último Mês"
          isSmallWidth={isSmallWidth}
          useStatus
        />
        <FileUploadSemantic
          fid={financing?._id}
          setLoadedFinancings={setLoadedData}
          cid={financing?.customer?._id}
          initFile={proofOfIncomeFile}
          initialFileName={
            proofOfIncomeFile?.name || proofOfIncomeFile?.semantic
          }
          label={"Comprovante de Renda"}
          fileSemantic="Comprovante de Renda"
          folderSemantic="Financiamento"
          helperText="Holerite, IR ou Extrato dos Últimos 3 Meses"
          isSmallWidth={isSmallWidth}
          useStatus
        />
        {!isLoading && (
          <ButtonTealContained
            variant="contained"
            color="primary"
            onClick={openWhatsappHandler}
          >
            <WhatsAppIcon />
            &nbsp; CHAMAR CONSULTOR
          </ButtonTealContained>
        )}
        {isLoading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "1.5rem",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        )}
      </form>
    </Fragment>
  );
};

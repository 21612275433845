import {
  AuthContextProps,
  FinancingData,
  FormHookDispState,
  ProposalData,
  SettingsContextData,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// EDIT MONITORING LINK ////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface EditMonitoringLinkProps extends FetchProps {
  pid: string;
  setLoadedFinancings?: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  formState: FormHookDispState;
}

export const editMonitoringLink = async (props: EditMonitoringLinkProps) => {
  const { sendRequest, auth, setLoadedFinancings, pid, formState } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/proposals/single/monitoring-link/${pid}`;
    const responseData: { proposal: ProposalData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ link: formState?.inputs?.monitoringLink?.value || "" }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    if (setLoadedFinancings) {
      setLoadedFinancings((prevValues) => {
        prevValues = prevValues?.map((item) => {
          if (item?.proposal?._id === pid) {
            item.proposal = responseData.proposal;
          }

          return item;
        });

        return prevValues;
      });
    }
  } catch (err) {}
};

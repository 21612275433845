import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { sum } from "../../shared/util/sum";
import { getSmallestTotalFinancingOption } from "../util/getFinancingOptions";
import { getFinancingCashFlow } from "../util/getFinancingCashFlow";
import { getDaysFromDate } from "../../shared/util/getDaysFromDate";
import {
  ProposalData,
  CashFlowData,
  CashFlowCompData,
  MonthlyGenerationData,
  TemplateData,
} from "../../shared/data/types";

export const getProposalTagsValues = (
  loadedProposal: ProposalData,
  cashFlow: CashFlowData[],
  cashFlowComp: CashFlowCompData[],
  monthlyGeneration: MonthlyGenerationData[],
  loadedTemplate: TemplateData
) => {
  const isAGroup = loadedProposal?.tariffModality === "A";
  const avgtarif =
    loadedProposal.tariffModality === "B"
      ? loadedProposal?.energyBillData
          ?.map((data) => {
            return data.kwhPrice;
          })
          ?.reduce((a, b) => {
            return a + b;
          }) / loadedProposal?.energyBillData?.length
      : loadedProposal?.energyBillData
          ?.map((data) => {
            return data.kwhPrice + data.tusd;
          })
          ?.reduce((a, b) => {
            return a + b;
          }) / loadedProposal?.energyBillData?.length;

  let tagsValues: { [key: string]: any } = {
    nome_da_empresa: loadedProposal.userData.name || "",
    cnpj_da_empresa: loadedProposal.userData.cnpj || "",
    telefone_da_empresa: loadedProposal.userData.phone || "",
    whatsapp_da_empresa: loadedProposal.userData.whatsapp || "",
    endereco_da_empresa: loadedProposal.userData.address || "",
    website_da_empresa:
      loadedProposal.userData.website.replace("https://", "") || "",
    facebook_da_empresa:
      loadedProposal.userData.facebook.replace(
        "https://www.facebook.com/",
        "@"
      ) || "",
    instagram_da_empresa:
      loadedProposal.userData.instagram.replace(
        "https://www.instagram.com/",
        ""
      ) || "",
    nome_do_cliente: loadedProposal.customerData.name || "",
    documento_do_cliente: loadedProposal.customerData.document || "",
    cidade_do_cliente: loadedProposal.location.city || "",
    endereco_do_cliente: loadedProposal.location.address || "",
    email_do_cliente: loadedProposal.customerData.email || "",
    whatsapp_do_cliente: loadedProposal.customerData.whatsapp || "",
    nome_responsavel: loadedProposal.userData.vendorName || "",
    telefone_responsavel: loadedProposal.userData.vendorPhone || "",
    email_responsavel: loadedProposal.userData.vendorEmail || "",
    numero_da_proposta: `#${loadedProposal.count?.toLocaleString("pt-BR", {
      minimumIntegerDigits: 5,
      useGrouping: false,
    })}`,
    cs_m:
      addThousandSeparator(
        loadedProposal.estimateSystemData.averageMontlhyConsumption
      ) || "",
    cs_m_fp:
      addThousandSeparator(
        loadedProposal.estimateSystemData.averageMontlhyConsumption
      ) || "",
    cs_m_p:
      addThousandSeparator(
        Math.round(
          loadedProposal.estimateSystemData.averageYearlyConsumptionPeak / 12
        )
      ) || "",
    cs_a:
      addThousandSeparator(sum(loadedProposal.totalMonthlyConsumption)) || "",
    cs_a_fp:
      addThousandSeparator(sum(loadedProposal.totalMonthlyConsumption)) || "",
    cs_a_p:
      addThousandSeparator(
        loadedProposal.estimateSystemData.averageYearlyConsumptionPeak
      ) || "",
    ger_m:
      addThousandSeparator(
        loadedProposal.realSystemData.averageMontlhyGeneration
      ) || "",
    ger_a: addThousandSeparator(
      loadedProposal.realSystemData.averageYearlyGeneration
    ),
    co2: addThousandSeparator(
      loadedProposal.realSystemData.averageYearlyGeneration * 0.28
    ),
    arvores: addThousandSeparator(
      loadedProposal.realSystemData.averageMontlhyGeneration * 0.33
    ),
    carros: addThousandSeparator(
      loadedProposal.realSystemData.averageMontlhyGeneration * 0.02
    ),
    ucs_dist: [],
    potencia: addThousandSeparator(loadedProposal.realSystemData.power) || "",
    potencia_calc:
      addThousandSeparator(loadedProposal.estimateSystemData.idealPower) || "",
    area: addThousandSeparator(loadedProposal.realSystemData.minArea) || "",
    peso_dist:
      addThousandSeparator(loadedProposal.realSystemData.distributedWeight) ||
      "",
    irr: addThousandSeparator(loadedProposal.sundata.irrAvg) || "",
    modulos: [],
    inversores: [],
    adicionais: [],
    cust_tot_ss:
      addThousandSeparator(
        sum(loadedProposal.revenueData.finantialViability.firstYearNoSystem) /
          12
      ) || "",
    cust_c_fp_ss:
      addThousandSeparator(
        Math.round(
          loadedProposal.revenueData.finantialViability.nonPeakMonthyCost
        )
      ) || "",
    cust_d_fp_ss:
      addThousandSeparator(
        Math.round(
          loadedProposal.revenueData.finantialViability.nonPeakDemandCost
        )
      ) || "",
    cust_c_p_ss:
      addThousandSeparator(
        Math.round(loadedProposal.revenueData.finantialViability.peakMonthyCost)
      ) || "",
    cust_d_p_ss:
      addThousandSeparator(
        Math.round(loadedProposal.revenueData.finantialViability.peakDemandCost)
      ) || "",
    cust_d_ger_ss:
      addThousandSeparator(
        Math.round(
          loadedProposal.revenueData.finantialViability.genDemandCost || 0
        )
      ) || "0",
    cust_tot_cs:
      addThousandSeparator(
        loadedProposal.revenueData.finantialViability.firstYearWithSystem
      ) || "",
    cust_c_fp_abt:
      addThousandSeparator(
        Math.round(
          loadedProposal.revenueData.finantialViability.nonPeakMonthyEconomy
        )
      ) || "",
    cust_c_p_abt:
      addThousandSeparator(
        Math.round(
          loadedProposal.revenueData.finantialViability.peakMonthyEconomy
        )
      ) || "",
    cust_a1_ss:
      addThousandSeparator(
        sum(loadedProposal.revenueData.finantialViability.firstYearNoSystem)
      ) || "",
    cust_a1_cs:
      addThousandSeparator(
        loadedProposal.revenueData.finantialViability.firstYearWithSystem * 12
      ) || "",
    ecn_a1_am:
      addThousandSeparator(
        (sum(loadedProposal.revenueData.finantialViability.firstYearNoSystem) -
          loadedProposal.revenueData.finantialViability.firstYearWithSystem *
            12) /
          12
      ) || "",
    ecn_a1_aa:
      addThousandSeparator(
        sum(loadedProposal.revenueData.finantialViability.firstYearNoSystem) -
          loadedProposal.revenueData.finantialViability.firstYearWithSystem * 12
      ) || "",
    preco_sist:
      addThousandSeparator(loadedProposal.revenueData.finalPrice) || "",
    cond_pag: loadedProposal.revenueData?.paymentConditions || "",
    fornecedor: loadedProposal.realSystemData?.supplier || "",
    est_fix: loadedProposal.realSystemData?.kitFixation || "",
    preco_servico:
      addThousandSeparator(
        loadedProposal.revenueData.totalSvc ||
          loadedProposal.revenueData.liquidServices
      ) || "",
    preco_equipamentos:
      addThousandSeparator(
        loadedProposal.revenueData.totalEqp ||
          loadedProposal.revenueData.pvKitOriginalCost ||
          loadedProposal.revenueData.pvKitCost
      ) || "",
    infl_en: loadedProposal.revenueData.inflation || "",
    payback_aa:
      loadedProposal.revenueData.finantialViability.paybackYears || "",
    payback_mm:
      loadedProposal.revenueData.finantialViability.paybackMonths || "",
    payback_comp_aa:
      loadedProposal.revenueData.oldFinantialViability.paybackYears || "",
    payback_comp_mm:
      loadedProposal.revenueData.oldFinantialViability.paybackYears || "",
    roi:
      addThousandSeparator(loadedProposal.revenueData.finantialViability.roi) ||
      "",
    roi_comp:
      addThousandSeparator(
        loadedProposal.revenueData.oldFinantialViability.roi
      ) || "",
    tir:
      addThousandSeparator(
        loadedProposal?.revenueData.finantialViability?.tir
      ) || "",
    vl_sist_fv:
      addThousandSeparator(
        loadedProposal?.revenueData.finantialViability.lcoe
      ) || "",
    vl_ecn_sist_fv:
      addThousandSeparator(
        avgtarif - loadedProposal?.revenueData.finantialViability.lcoe
      ) || "",
    ecn_tot:
      addThousandSeparator(
        loadedProposal.revenueData.finantialViability.totalDiscountedEconomy
      ) || "",
    ecn_tot_comp: addThousandSeparator(
      loadedProposal.revenueData.oldFinantialViability.totalDiscountedEconomy
    ),
    fin: [],
    fin_viab: [],
    data_validade:
      loadedProposal?.proposalCustomizationData?.validityDate || "",
    dias_validade:
      getDaysFromDate(
        loadedProposal?.proposalCustomizationData?.validityDate || ""
      ) || "",
    cons_finais_t: loadedTemplate?.section8LastRemarks?.title || "",
    cons_finais_p1: loadedTemplate?.section8LastRemarks?.remark1 || "",
    cons_finais_p2: loadedTemplate?.section8LastRemarks?.remark2 || "",
    cons_finais_p3: loadedTemplate?.section8LastRemarks?.remark3 || "",
    cons_finais_p4: loadedTemplate?.section8LastRemarks?.remark4 || "",
    cons_finais_p5: loadedTemplate?.section8LastRemarks?.remark5 || "",
    cons_finais_p6: loadedTemplate?.section8LastRemarks?.remark6 || "",
    data_registro: loadedProposal?.registryDate?.substring(0, 10) || "",
    proj_conta: [],
    viab_d: [],
    sobre_eqps: loadedProposal?.aboutTheEquipament || "",
  };

  loadedProposal.energyBillData.forEach((uc, i) => {
    const perc =
      !uc?.classification || !uc?.monthlyConsumptionPeak?.length
        ? uc?.monthlyConsumption?.reduce((a, b) => {
            return a + b;
          }) / loadedProposal?.estimateSystemData?.averageYearlyConsumption
        : (uc?.monthlyConsumption?.reduce((a, b) => {
            return a + b;
          }) +
            uc?.monthlyConsumptionPeak?.reduce((a, b) => {
              return a + b;
            })) /
          (loadedProposal?.estimateSystemData?.averageYearlyConsumption +
            loadedProposal?.estimateSystemData?.averageYearlyConsumptionPeak);

    const energyPerc =
      perc * loadedProposal.realSystemData.averageMontlhyGeneration;

    tagsValues.ucs_dist?.push({
      nome_uc: `${uc.ucName?.trim() || `Unidade Consumidora ${i + 1}`} ${
        i === 0 ? "(Gerador)" : ""
      }`,
      ger_uc: addThousandSeparator(energyPerc) || "",
      ger_perc_uc: addThousandSeparator(perc * 100),
      c_ss_uc: !!uc?.finViabilityData?.noSystemMonthlyBill
        ? addThousandSeparator(uc?.finViabilityData?.noSystemMonthlyBill)
        : "-",
      c_cs_uc: !!uc?.finViabilityData?.systemMonthlyBill
        ? addThousandSeparator(uc?.finViabilityData?.systemMonthlyBill)
        : "-",
      ec_uc: !!(
        uc?.finViabilityData?.noSystemMonthlyBill -
        uc?.finViabilityData?.systemMonthlyBill
      )
        ? addThousandSeparator(
            uc?.finViabilityData?.noSystemMonthlyBill -
              uc?.finViabilityData?.systemMonthlyBill
          )
        : "-",
    });
  });

  loadedProposal.pvModuleData?.forEach((mod) => {
    tagsValues.modulos?.push({
      mod_fab: mod.manufacturer || "",
      mod_pot: addThousandSeparator(mod.power || 0, false, false) || "",
      mod_gar_def: mod.warrantyDefect || "",
      mod_gar_ef: mod.warrantyEfficiency || "",
      mod_qtd: mod.quantity || "",
    });
  });

  loadedProposal.inverterData?.forEach((inv) => {
    tagsValues.inversores?.push({
      inv_mod: inv.iModel || "",
      inv_fab: inv.iManufacturer || "",
      inv_pot:
        inv.iPower > 99
          ? addThousandSeparator(inv.iPower, false, false)
          : addThousandSeparator(inv.iPower * 1000, false, false) || "",
      inv_gar: inv.iWarranty || "",
      inv_monit: inv.iMonitoring || "Wi-Fi",
      inv_qtd: inv.iQuantity || "",
    });
  });

  loadedProposal.additionalItems?.forEach((addItem) => {
    tagsValues.adicionais?.push({
      item_adc_nome: addItem.item || "",
      item_adc_fab: addItem.manufacter || "",
      item_adc_qtd: addItem.quantity || "",
      item_adc_det: addItem.detail || "",
    });
  });

  loadedProposal.revenueData?.financing?.forEach((fin, i) => {
    tagsValues.fin?.push({
      fin_banco: fin.bank || "",
      fin_cr: fin.gracePeriod || "",
      fin_ent_parc: fin.entryInstallments || "",
      fin_ent_val:
        addThousandSeparator(fin.entry / fin.entryInstallments) || "",
      fin_seg: fin.insurance ? "Incluso" : "Não Incluso",
      fin_opc_1_parc: fin?.options?.[0]?.installments || "",
      fin_opc_1_val: addThousandSeparator(fin?.options?.[0]?.value) || "",
      fin_opc_2_parc: fin?.options?.[1]?.installments || "",
      fin_opc_2_val: addThousandSeparator(fin?.options?.[1]?.value) || "",
      fin_opc_3_parc: fin?.options?.[2]?.installments || "",
      fin_opc_3_val: addThousandSeparator(fin?.options?.[2]?.value) || "",
      fin_opc_4_parc: fin?.options?.[3]?.installments || "",
      fin_opc_4_val: addThousandSeparator(fin?.options?.[3]?.value) || "",
      fin_opc_5_parc: fin?.options?.[4]?.installments || "",
      fin_opc_5_val: addThousandSeparator(fin?.options?.[4]?.value) || "",
      fin_opc_6_parc: fin?.options?.[5]?.installments || "",
      fin_opc_6_val: addThousandSeparator(fin?.options?.[5]?.value) || "",
      fin_opc_7_parc: fin?.options?.[6]?.installments || "",
      fin_opc_7_val: addThousandSeparator(fin?.options?.[6]?.value) || "",
      fin_opc_8_parc: fin?.options?.[7]?.installments || "",
      fin_opc_8_val: addThousandSeparator(fin?.options?.[7]?.value) || "",
    });

    const smallestOpt = getSmallestTotalFinancingOption(loadedProposal, i);
    const financing = loadedProposal.revenueData?.financing?.[i];
    const finViabilityData = getFinancingCashFlow(
      cashFlow,
      loadedProposal.revenueData.finalPrice,
      smallestOpt,
      financing?.entry || 0
    );

    tagsValues.fin_viab?.push({
      fin_viab_banco: fin.bank || "",
      fin_viab_parc: smallestOpt?.installments || "",
      fin_viab_val: addThousandSeparator(smallestOpt?.value) || "",
      fin_viab_val_tot: addThousandSeparator(finViabilityData.totalPrice) || "",
      fin_viab_breakeven_aa: finViabilityData.breakEvenYears || "",
      fin_viab_breakeven_mm: finViabilityData.breakEvenMonths || "",
      fin_viab_payback_aa: finViabilityData.paybackYears || "",
      fin_viab_payback_mm: finViabilityData.paybackMonths || "",
      fin_viab_roi: addThousandSeparator(finViabilityData.roi) || "",
      fin_viab_ecn_tot:
        addThousandSeparator(finViabilityData.totalEconomy) || "",
    });
  });

  loadedProposal.revenueData?.finantialViability?.yearlyMinEnergyBill?.forEach(
    (proj, i) => {
      tagsValues.proj_conta.push({
        proj_ano: new Date().getFullYear() + i || "",
        proj_valor: addThousandSeparator(proj / 12) || "",
      });
    }
  );

  loadedProposal.revenueData?.finantialViability?.yearlyEconomy?.forEach(
    (yec, i) => {
      tagsValues.viab_d.push({
        viab_d_ano: new Date().getFullYear() + i || "",
        viab_d_ss:
          addThousandSeparator(
            loadedProposal.revenueData?.finantialViability
              ?.yearlyNoSystemBill?.[i]
          ) || "",
        viab_d_cs:
          addThousandSeparator(
            loadedProposal.revenueData?.finantialViability?.yearlySystemBill?.[
              i
            ]
          ) || "",
        viab_d_ec: addThousandSeparator(yec) || "",
        viab_d_fc:
          addThousandSeparator(
            loadedProposal.revenueData?.finantialViability?.systemCashFlow?.[i]
          ) || "",
      });
    }
  );

  const grafico_ger = {
    series: [
      {
        data: monthlyGeneration?.map((data) => {
          return data.consumption;
        }),
      },
      {
        data: monthlyGeneration?.map((data) => {
          return data.generation;
        }),
      },
    ],
  };

  const grafico_fat_a1 = {
    series: [
      {
        data: monthlyGeneration?.map((data) => {
          return data.firstYearWithSystem;
        }),
      },
      {
        data: monthlyGeneration?.map((data) => {
          return data.firstYearNoSystem;
        }),
      },
    ],
  };

  const grafico_fluxo_caixa = {
    series: [
      {
        data: cashFlow?.map((data) => {
          return data.flow;
        }),
      },
    ],
  };

  const grafico_comp_rend = {
    series: [
      {
        data: cashFlowComp?.map((data) => {
          return data.flow1;
        }),
      },
      {
        data: cashFlowComp?.map((data) => {
          return data.flow2;
        }),
      },
      {
        data: cashFlowComp?.map((data) => {
          return data.flow3;
        }),
      },
    ],
  };

  tagsValues = {
    ...tagsValues,
    grafico_ger,
    grafico_fat_a1,
    grafico_fluxo_caixa,
    grafico_comp_rend,
  };

  return tagsValues;
};

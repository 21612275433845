import { makeStyles } from "@material-ui/core/styles";

export const LoadingSpinnerFullScreenGraphStyles = makeStyles((theme) => ({
  linearRoot: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  roundRoot: {
    display: "flex",
    zIndex: 5,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  roundRootCloserToTop: {
    display: "flex",
    zIndex: 5,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  rootContent: {
    display: "flex",
    zIndex: 5,
    width: "100%",
    height: "20rem",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  rootOverlay: {
    background: "rgba(240, 240,240, 0.70)",
    display: "flex",
    zIndex: 5,
    width: "calc(100% - 250px)",
    height: "calc(100% - 80px)",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    right: 0,
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "calc(100% - 64px)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "calc(100% - 56px)",
    },
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  rootOverlay2: {
    background: "rgba(240, 240,240, 0.70)",
    display: "flex",
    zIndex: 5,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    left: 0,
    top: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "calc(100% - 64px)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "calc(100% - 56px)",
    },
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

import React, { Fragment, useContext } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useHistory } from "react-router-dom";

import { ButtonTealContained } from "../../shared/components/MUIThemed/ButtonTealContained";
import { FileUploadSemantic } from "../../shared/components/FormElements/FileUploadSemantic";
import { useForm } from "../../shared/hooks/formHook";
import { openWhatsapp } from "../../shared/util/openWhatsapp";
import { SettingsContext } from "../../shared/context/settingsContext";
import {
  ArchiveData,
  AuthContextProps,
  FinancingData,
} from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface FormUploadNFsProps {
  auth: AuthContextProps;
  financing: FinancingData;
  setLoadedData: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  isLoading: boolean;
  uniqueFinProps: FinancingData["proposals"];
  setTab: React.Dispatch<React.SetStateAction<number>>;
  isSmallWidth: boolean;
}

export const FormUploadNFs = (props: FormUploadNFsProps) => {
  const {
    auth,
    financing,
    sendRequest,
    setLoadedData,
    isLoading,
    uniqueFinProps,
    setTab,
    isSmallWidth,
  } = props;
  const classes = PopoverFormStyles();
  const settingsCtx = useContext(SettingsContext);
  const { formState, inputHandler, reportInvalid } = useForm({}, false);
  const history = useHistory();

  const customerArchive = financing.customer?.archive as ArchiveData;

  const serviceNFeFile = customerArchive?.files?.find(
    (f) => f.semantic === "NFe de Serviço"
  );
  const equipamentNFeFile = customerArchive?.files?.find(
    (f) => f.semantic === "NFe dos Equipamentos"
  );

  const openWhatsappHandler = () => {
    openWhatsapp(
      "31986281765",
      "Quero contratar um financiamento pré-aprovado."
    );
  };

  return (
    <Fragment>
      <form style={{ zIndex: 502 }} className={classes.formRoot3}>
        <FileUploadSemantic
          fid={financing?._id}
          setLoadedFinancings={setLoadedData}
          cid={financing?.customer?._id}
          initFile={serviceNFeFile}
          initialFileName={serviceNFeFile?.name || serviceNFeFile?.semantic}
          label="NFe de Serviço"
          fileSemantic="NFe de Serviço"
          folderSemantic="Financiamento"
          helperText="Nota Fiscal de Serviço"
          isSmallWidth={isSmallWidth}
          useStatus
        />
        <FileUploadSemantic
          fid={financing?._id}
          setLoadedFinancings={setLoadedData}
          cid={financing?.customer?._id}
          initFile={equipamentNFeFile}
          initialFileName={
            equipamentNFeFile?.name || equipamentNFeFile?.semantic
          }
          label="NFe dos Equipamentos"
          fileSemantic="NFe dos Equipamentos"
          folderSemantic="Financiamento"
          helperText="Nota Fiscal do Distribuidor"
          isSmallWidth={isSmallWidth}
          useStatus
        />
        {!isLoading && (
          <ButtonTealContained
            variant="contained"
            color="primary"
            onClick={openWhatsappHandler}
          >
            <WhatsAppIcon />
            &nbsp; CHAMAR CONSULTOR
          </ButtonTealContained>
        )}
        {isLoading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "1.5rem",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        )}
      </form>
    </Fragment>
  );
};

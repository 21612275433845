import { Fragment, useState, useEffect, useContext } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useHttpClient } from "../../shared/hooks/httpHook";
import { AuthContext } from "../../shared/context/authContext";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { FolderItem } from "../../archive/components/FolderItem";
import { PopoverFormCreateFolder } from "../../archive/components/PopoverFormCreateFolder";
import { getCustomerFiles } from "../../archive/api/archiveAPI";
import { processDragEnd } from "../../archive/util/processDragEnd";
import { ArchiveData } from "../../shared/data/types";

import "../../styles/layout/table.scss";
import "../../styles/components/buttons.scss";
import "../../styles/base/_typography.scss";

interface SaleDashFilesProps {
  cid: string;
}

export const SaleDashFiles = (props: SaleDashFilesProps) => {
  const { cid } = props;
  const auth = useContext(AuthContext);
  const { sendRequest, error, clearError, isLoading } = useHttpClient();
  const [loadedArchive, setLoadedArchive] = useState<ArchiveData>(null);
  const [loadedCustomerName, setLoadedCustomerName] = useState<string>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  useEffect(() => {
    if (auth.signupPlan === "ENTERPRISE") {
      getCustomerFiles({
        sendRequest,
        setLoadedArchive,
        cid,
        auth,
        setLoadedCustomerName,
      });
    } else {
      setLoadedArchive({} as ArchiveData);
    }
  }, [cid]);

  const dragEndHandler = (result: DropResult) => {
    const { destination, source, draggableId, type } = result;
    if (type === "FOLDER") {
      processDragEnd({
        sendRequest,
        auth,
        destination,
        source,
        draggableId,
        type: "FOLDER",
        cid,
        loadedArchive,
        setLoadedArchive,
      });
    } else if (type.includes("FILE")) {
      processDragEnd({
        sendRequest,
        auth,
        destination,
        source,
        draggableId,
        type: "FILE",
        cid,
        loadedArchive,
        setLoadedArchive,
      });
    }
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <PopoverFormCreateFolder
        id="popover-create-folder"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        auth={auth}
        sendRequest={sendRequest}
        setLoadedArchive={setLoadedArchive}
        apiCall="create"
        cid={cid}
      />
      {!loadedArchive && (
        <div className="sale-dash__col-right-content-spinner">
          <CircularProgress color="primary" />
        </div>
      )}
      {auth.signupPlan !== "ENTERPRISE" && (
        <p className="sale-dash__col-right-content-empty">
          Seu plano não possui gerenciador de arquivos
        </p>
      )}
      {loadedArchive && (
        <div className="table__container">
          {/* <h2 style={{ margin: 0 }} className="heading-secondary">
            Arquivos do Cliente
          </h2>
          <p className="subheading u-margin-bottom-lg">{loadedCustomerName}</p> */}
          {loadedArchive.folders?.length > 0 &&
            auth.signupPlan === "ENTERPRISE" && (
              <DragDropContext onDragEnd={dragEndHandler}>
                <Droppable
                  droppableId="main-droppable"
                  direction="vertical"
                  type="FOLDER"
                >
                  {(provided) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="table"
                      >
                        {isLoading && <LoadingSpinnerOverlayRegular image />}
                        {loadedArchive.folders
                          ?.sort((a, b) => {
                            return (a?.order || 0) - (b?.order || 0);
                          })
                          .map((folder, i) => {
                            return (
                              <FolderItem
                                folder={folder}
                                folderFiles={
                                  loadedArchive.files?.filter((file) => {
                                    return file.folder === folder._id;
                                  }) || []
                                }
                                auth={auth}
                                setLoadedArchive={setLoadedArchive}
                                index={i}
                                isLoadingDrag={isLoading}
                                cid={cid}
                                key={folder._id}
                              />
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            )}
          {auth.signupPlan === "ENTERPRISE" && (
            <div className="dashboard__add-btn-container">
              <IconButton
                title="Adicionar pasta"
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
              >
                <AddRoundedIcon style={{ color: "#043a50" }} />
              </IconButton>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

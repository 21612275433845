import React, { useReducer, useEffect, useState } from "react";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";

import { InputDispState, InputDispAction } from "./Input";
import { validate } from "../../util/validators";

import "./InputWithSubmitAndStatus.scss";

const inputReducer = (state: InputDispState, action: InputDispAction) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    case "UNTOUCH":
      return {
        ...state,
        isTouched: false,
      };
    default:
      return state;
  }
};

interface InputWithSubmitAndStatusProps {
  id: string;
  label: string;
  onInput: (id: string, value: string, isValid: boolean, label: string) => void;
  onClick: (() => void) | (() => Promise<void>);
  helperText: string;
  initialValue?: string;
  initStatus?: "PENDING" | "APPROVED" | "REJECTED" | "";
  initialValid?: boolean;
  forceError?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  reset?: boolean;
  reinitialize?: boolean;
  labelWidth: number;
  validators: { type: string; val?: number }[];
  useStatus?: boolean;
  isLoading: boolean;
}

export const InputWithSubmitAndStatus = (
  props: InputWithSubmitAndStatusProps
) => {
  const {
    id,
    label,
    onInput,
    onClick,
    forceError,
    helperText,
    initialValue,
    initStatus,
    initialValid,
    placeholder,
    fullWidth,
    disabled,
    validators,
    reinitialize,
    reset,
    labelWidth,
    useStatus,
    isLoading,
  } = props;
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || "",
    isTouched: false,
    isValid: initialValid || false,
  });

  const { value, isValid, isTouched } = inputState;

  useEffect(() => {
    onInput(id, value, isValid, label.charAt(0).toUpperCase() + label.slice(1));
  }, [id, value, isValid, label, onInput]);

  useEffect(() => {
    if (reset) {
      dispatch({
        type: "CHANGE",
        val: "",
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
    if (reinitialize) {
      dispatch({
        type: "CHANGE",
        val: initialValue,
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
  }, [reset, reinitialize, initialValue]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: validators,
    });
  };

  const touchHandler = () => {
    dispatch({ type: "TOUCH" });
  };

  const clickHandler = () => {
    onClick();
  };

  return (
    <FormControl
      error={(!isValid && isTouched) || forceError}
      variant="outlined"
      disabled={(useStatus && initStatus === "APPROVED") || disabled}
    >
      <InputLabel htmlFor={id}>
        {label.charAt(0).toUpperCase() + label.slice(1)}
      </InputLabel>
      <OutlinedInput
        // className={`input-submit-status__input-txt ${
        //   initStatus === "APPROVED"
        //     ? "input-submit-status__input-txt--teal"
        //     : initStatus === "REJECTED"
        //     ? "input-submit-status__input-txt--red"
        //     : initStatus === "PENDING"
        //     ? "input-submit-status__input-txt--ambar"
        //     : ""
        // }`}
        fullWidth={fullWidth}
        value={value}
        onChange={changeHandler}
        onBlur={touchHandler}
        type="text"
        id={id}
        name={id}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            {!isLoading ? (
              <IconButton
                className={`input-submit-status__cta ${
                  initStatus === "APPROVED"
                    ? "input-submit-status__cta--teal"
                    : ""
                }`}
                disabled={
                  (useStatus && initStatus === "APPROVED") ||
                  !isValid ||
                  value === initialValue
                }
                onClick={(e) => {
                  e.preventDefault();
                  clickHandler();
                }}
                edge="end"
              >
                {useStatus && initStatus === "APPROVED" ? (
                  <DoneOutlineRoundedIcon />
                ) : (
                  <PublishRoundedIcon />
                )}
              </IconButton>
            ) : (
              <CircularProgress size={27} color="primary" />
            )}
          </InputAdornment>
        }
        startAdornment={
          !!initStatus && useStatus ? (
            <InputAdornment
              className={`input-submit-status__status ${
                initStatus === "APPROVED"
                  ? "input-submit-status__status--teal"
                  : initStatus === "REJECTED"
                  ? "input-submit-status__status--red"
                  : initStatus === "PENDING"
                  ? "input-submit-status__status--ambar"
                  : ""
              }`}
              title={`${
                initStatus === "APPROVED"
                  ? "Aprovado"
                  : initStatus === "REJECTED"
                  ? "Reprovado"
                  : initStatus === "PENDING"
                  ? "Em Análise"
                  : ""
              }`}
              position="start"
            >
              {initStatus === "APPROVED" ? (
                <CheckCircleRoundedIcon />
              ) : initStatus === "REJECTED" ? (
                <HighlightOffRoundedIcon />
              ) : initStatus === "PENDING" ? (
                <WarningRoundedIcon />
              ) : (
                <WarningRoundedIcon />
              )}
            </InputAdornment>
          ) : null
        }
        labelWidth={labelWidth}
      />
      <FormHelperText id={id} error={(!isValid && isTouched) || forceError}>
        {forceError ? helperText : !isValid && isTouched && helperText}
      </FormHelperText>
    </FormControl>
  );
};

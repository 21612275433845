import React, { useContext, Fragment } from "react";
import { ImportMapping } from "../../shared/components/ImportMapping/ImportMapping";
import { uploadInverters } from "../api/invertersAPI";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { AuthContext } from "../../shared/context/authContext";
import { ModalError } from "../../shared/components/UIElements/ModalError";

const availableProperties = [
  {
    value: "inversor_modelo",
    aliases: ["inversor_modelo", "modelo", "name"],
    required: true,
  },
  {
    value: "inversor_fabricante",
    aliases: ["inversor_fabricante", "fabricante", "manufacturer"],
    required: true,
  },
  {
    value: "inversor_potencia_em_watts",
    aliases: ["inversor_potencia_em_watts", "potencia", "power"],
    required: true,
  },
  {
    value: "inversor_garantia",
    aliases: ["inversor_garantia", "garantia", "warranty"],
    required: false,
  },
  {
    value: "inversor_monitoramento",
    aliases: ["inversor_monitoramento", "monitoramento", "monitoring"],
    required: false,
  },
  {
    value: "inversor_tensao",
    aliases: ["inversor_tensao", "tensao", "voltage"],
    required: false,
  },
  {
    value: "inversor_topologia",
    aliases: ["inversor_topologia", "topologia", "topology"],
    required: false,
  },
  {
    value: "inversor_estoque",
    aliases: ["inversor_estoque", "estoque", "stock"],
    required: false,
  },
  {
    value: "inversor_valor_unitario",
    aliases: ["inversor_valor_unitario", "valor", "price"],
    required: false,
  },
];

export const InverterImportMapping: React.FC = () => {
  const { sendRequest, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const handleSubmit = async (
    mapping: Record<string, string>,
    fileContent: string
  ) => {
    try {
      await uploadInverters({ sendRequest, auth, mapping, fileContent });
      return true;
    } catch (error) {
      console.error("Erro ao fazer upload:", error);
      return false;
    }
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ImportMapping
        availableProperties={availableProperties}
        onSubmit={handleSubmit}
        importType="inverter"
        title="Importação - Mapeamento de Inversores"
      />
    </Fragment>
  );
};

import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

export const MenuItemCustomBlueFocusBackground = withStyles((theme) => ({
  root: {
    "& span, & svg": {
      color: "#043a50",
    },
    "&:focus": {
      // backgroundColor: "#043a50",
      "& svg": {
        color: "#189ab4",
      },
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#189ab4",
      },
    },
  },
}))(MenuItem);

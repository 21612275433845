import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { ModalErrorStyles } from "./ModalErrorStyles";
import { useEffect, useState } from "react";
import { setTimeout } from "timers";

interface ModalWarningProps {
  open: boolean;
  warning: string;
  onClose: () => void;
}

export const ModalWarning = (props: ModalWarningProps) => {
  const { open, warning, onClose } = props;
  const [memmory, setMemort] = useState<string>(warning);
  const classes = ModalErrorStyles();

  useEffect(() => {
    if (!warning) {
      var timer = setTimeout(() => {
        setMemort(null);
      }, 500);
    } else {
      setMemort(warning);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [warning]);

  return (
    <Dialog
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ zIndex: 10002 }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle className={classes.errorModalTitle}>AVISO</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.errorModalContent}>
          {warning || memmory}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const getSaleIsInactive = (
  inactWindow: number = 24,
  timestamp: number
) => {
  if (!!timestamp) {
    const now = new Date().getTime();
    const validity = new Date(
      new Date(timestamp).getTime() + inactWindow * 60 * 60 * 1000
    ).getTime();

    return validity < now;
  } else {
    return false;
  }
};

import { AppBar, Tab, Tabs } from "@material-ui/core";
import {
  AuthContextProps,
  NotePopCustData,
  SettingsContextProps,
} from "../../../shared/data/types";
import { useState } from "react";
import { HomeDashboardTabSchedule } from "./HomeDashboardTabSchedule";
import { HomeDashboardTabSales } from "./HomeDashboardTabSales";
import { HomeDashboardTabFinancing } from "./HomeDashboardTabFinancing";

interface HomeDashboardTabsProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  fetchId: string;
  loadedNextSchedules: NotePopCustData[];
  setLoadedNextSchedules: React.Dispatch<
    React.SetStateAction<NotePopCustData[]>
  >;
  loadedGenSales: {
    label: string;
    val: number;
    sales: number;
    delta: number;
    deltaIsPositive: boolean;
  }[];
  setLoadedGenSales: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        val: number;
        sales: number;
        delta: number;
        deltaIsPositive: boolean;
      }[]
    >
  >;
  setIsLoadingGenSales: React.Dispatch<React.SetStateAction<boolean>>;
  loadedClosedSales: {
    label: string;
    val: number;
    sales: number;
    power: number;
    delta: number;
    deltaIsPositive: boolean;
  }[];
  setLoadedClosedSales: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        val: number;
        sales: number;
        power: number;
        delta: number;
        deltaIsPositive: boolean;
      }[]
    >
  >;
  setIsLoadingClosedSales: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingGenSales: boolean;
  isLoadingClosedSales: boolean;
  loadedSalesInProgress: {
    totalSales: number;
    totalAmount: number;
    totalPotProfit: number;
  };
  setLoadedSalesInProgress: React.Dispatch<
    React.SetStateAction<{
      totalSales: number;
      totalAmount: number;
      totalPotProfit: number;
    }>
  >;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, className, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      className={className}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `dashboard-tab-${index}`,
    "aria-controls": `dashboard-tabpanel-${index}`,
  };
};

export const HomeDashboardTabs = (props: HomeDashboardTabsProps) => {
  const {
    settingsCtx,
    auth,
    fetchId,
    loadedNextSchedules,
    setLoadedNextSchedules,
    loadedGenSales,
    setLoadedGenSales,
    setIsLoadingGenSales,
    loadedClosedSales,
    setIsLoadingClosedSales,
    setLoadedClosedSales,
    isLoadingGenSales,
    isLoadingClosedSales,
    loadedSalesInProgress,
    setLoadedSalesInProgress,
  } = props;
  const [value, setValue] = useState(0);

  return (
    <div className={`dashboard-item dashboard-item--tabs`}>
      {/* <AppBar position="static"> */}
      <Tabs
        className="dashboard-item__tabs-nav"
        value={value}
        onChange={(e, v) => setValue(v)}
        aria-label="dashboard tabs"
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Agenda" {...a11yProps(0)} />
        <Tab label="Vendas" {...a11yProps(1)} />
        {auth.whitelabel !== "SERRANA" && (
          <Tab label="Financiamentos" {...a11yProps(2)} />
        )}
      </Tabs>
      {/* </AppBar> */}
      <TabPanel
        className="dashboard-item--tabs-content"
        value={value}
        index={0}
      >
        <HomeDashboardTabSchedule
          settingsCtx={settingsCtx}
          auth={auth}
          fetchId={fetchId}
          loadedNextSchedules={loadedNextSchedules}
          setLoadedNextSchedules={setLoadedNextSchedules}
        />
      </TabPanel>
      <TabPanel
        className="dashboard-item--tabs-content"
        value={value}
        index={1}
      >
        <HomeDashboardTabSales
          settingsCtx={settingsCtx}
          auth={auth}
          fetchId={fetchId}
          loadedGenSales={loadedGenSales}
          setLoadedGenSales={setLoadedGenSales}
          setIsLoadingGenSales={setIsLoadingGenSales}
          loadedClosedSales={loadedClosedSales}
          setIsLoadingClosedSales={setIsLoadingClosedSales}
          setLoadedClosedSales={setLoadedClosedSales}
          isLoadingGenSales={isLoadingGenSales}
          isLoadingClosedSales={isLoadingClosedSales}
          loadedSalesInProgress={loadedSalesInProgress}
          setLoadedSalesInProgress={setLoadedSalesInProgress}
        />
      </TabPanel>
      <TabPanel
        className="dashboard-item--tabs-content"
        value={value}
        index={2}
      >
        <HomeDashboardTabFinancing
          settingsCtx={settingsCtx}
          auth={auth}
          fetchId={fetchId}
        />
      </TabPanel>
    </div>
  );
};

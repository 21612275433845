import { History } from "history";

import { setPostFormDataRegisterOrEditKit } from "../../shared/data/dynamic";
import {
  AuthContextProps,
  KitData,
  FormHookDispState,
  SettingsContextProps,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

interface FetchKitsProps extends FetchProps {
  setNetworkDataReceived: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadedData: React.Dispatch<React.SetStateAction<KitData[]>>;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  hidration: boolean;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const fetchKits = async (props: FetchKitsProps) => {
  const {
    sendRequest,
    auth,
    setNetworkDataReceived,
    setLoadedData,
    setShowLoadMore,
    multiplier,
    hidration,
    setFirstLoad,
  } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/kits/paginated/${auth.userId}/${multiplier}`;
    const responseData: { kits: KitData[]; hasMoreItems: boolean } =
      await sendRequest(apiUrl, "GET", null, {
        Authorization: "Bearer " + auth.token,
      });

    setShowLoadMore(responseData.hasMoreItems);
    setLoadedData((prevValues) => {
      if (!!prevValues && prevValues.length > 0 && !hidration) {
        return [...prevValues, ...responseData.kits];
      } else {
        return responseData.kits;
      }
    });
    setNetworkDataReceived(true);
    if (setFirstLoad) {
      setFirstLoad(true);
    }
  } catch (err) { }
};

// GET FILTERED KITS //////////////////////////////
///////////////////////////////////////////////////
interface GetFilteredKitsProps extends FetchProps {
  power?: number;
  setLoadedItems: React.Dispatch<React.SetStateAction<KitData[]>>;
  setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue: string;
  filter1Active: boolean;
  filter1Value: string;
  filter2Active: boolean;
  filter2Value: string;
  filter4Active: boolean;
  filter4Value: {
    p1: number;
    p2: number;
  };
  filter5Active: boolean;
  filter5Value: {
    p1: number;
    p2: number;
  };
  filter6Active: boolean;
  filter6Value: "MICRO INVERSOR" | "INVERSOR DE STRING";
  filter7Active: boolean;
  filter7Value: {
    p1: number;
    p2: number;
  };
  filter8Active: boolean;
  filter8Value: {
    p1: number;
    p2: number;
  };
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  fetchId: string;
  setMultiplier?: React.Dispatch<React.SetStateAction<number>>;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
  hidration?: boolean;
}

export const getFilteredKits = async (props: GetFilteredKitsProps) => {
  const {
    power,
    auth,
    sendRequest,
    setLoadedItems,
    setFiltered,
    searchValue,
    filter1Active,
    filter1Value,
    filter2Active,
    filter2Value,
    filter4Active,
    filter4Value,
    filter5Active,
    filter5Value,
    filter6Active,
    filter6Value,
    filter7Active,
    filter7Value,
    filter8Active,
    filter8Value,
    setShowLoadMore,
    multiplier,
    setMultiplier,
    fetchId,
    setFirstLoad,
    hidration,
  } = props;

  const fetchType =
    fetchId === auth.userId || auth.signupPlan !== "ENTERPRISE"
      ? "USER"
      : fetchId === auth.managerId
        ? "MANAGER"
        : "VENDOR";

  let apiUrl = !power
    ? `${process.env.REACT_APP_BACKEND_URL}/kits/filtered/${auth.userId}/${multiplier}`
    : `${process.env.REACT_APP_BACKEND_URL}/kits/target-power/${auth.userId}/${multiplier}`;

  try {
    const responseData: { kits: KitData[]; hasMoreItems: boolean } =
      await sendRequest(
        apiUrl,
        "POST",
        JSON.stringify({
          power: power / 1000,
          fetchType,
          fetchId,
          searchValue,
          filter1Active,
          filter1Value,
          filter2Active,
          filter2Value: +filter2Value?.replace(/\D/g, ""),
          filter4Active,
          filter4Value,
          filter5Active,
          filter5Value,
          filter6Active,
          filter6Value,
          filter7Active,
          filter7Value,
          filter8Active,
          filter8Value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        false,
        "kits"
      );

    if (responseData && responseData.kits?.length > 0) {
      setLoadedItems((prevValues) => {
        if (
          !!prevValues &&
          prevValues.length > 0 &&
          multiplier > 0 &&
          !hidration
        ) {
          return [...prevValues, ...responseData.kits];
        } else {
          return responseData.kits;
        }
      });
    }
    setFiltered(true);
    setShowLoadMore(responseData.hasMoreItems);
    if (setMultiplier) {
      setMultiplier(0);
    }
    if (setFirstLoad) {
      setFirstLoad(true);
    }
  } catch (err) {
    console.log(err);
  }
};

// GET KIT BY ID ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface GetKitByIdProps extends FetchProps {
  kitId: string;
  auth: AuthContextProps;
  setLoadedKit: React.Dispatch<React.SetStateAction<KitData>>;
  setAdditionalItems?: React.Dispatch<React.SetStateAction<string[]>>;
  setPvModItems?: React.Dispatch<React.SetStateAction<string[]>>;
  setInvItems?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const getKitById = async (props: GetKitByIdProps) => {
  const {
    sendRequest,
    auth,
    setLoadedKit,
    kitId,
    setAdditionalItems,
    setPvModItems,
    setInvItems,
  } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/kits/single/${kitId}`;
    const responseData: { kit: KitData } = await sendRequest(
      apiUrl,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedKit(responseData.kit);

    if (setAdditionalItems) {
      setAdditionalItems(
        responseData.kit?.additionalItems?.length > 0
          ? responseData.kit?.additionalItems?.map((item, i) => {
            return `${i}`;
          }) || ["0"]
          : ["0"]
      );
    }

    if (setPvModItems) {
      setPvModItems(
        responseData.kit?.pvModuleData?.length > 0
          ? responseData.kit?.pvModuleData?.map((item, i) => {
            return `${i}`;
          }) || ["0"]
          : ["0"]
      );
    }

    if (setInvItems) {
      setInvItems(
        responseData.kit?.inverterData?.length > 0
          ? responseData.kit?.inverterData?.map((item, i) => {
            return `${i}`;
          }) || ["0"]
          : ["0"]
      );
    }
  } catch (err) { }
};

// REGISTER KIT ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface RegisterKitProps extends FetchProps {
  kitId: string;
  auth: AuthContextProps;
  formState: FormHookDispState;
  pvModItemsLength: number;
  invItemsLength: number;
  additionalItemsLength: number;
  modPriceUnits: {
    id: string;
    idx: number;
    value: number;
    qtd: number;
  }[];
  invPriceUnits: {
    id: string;
    idx: number;
    value: number;
    qtd: number;
  }[];
  eqpPriceUnits: {
    id: string;
    idx: number;
    value: number;
    qtd: number;
  }[];
}

export const registerKit = async (props: RegisterKitProps) => {
  const {
    sendRequest,
    auth,
    kitId,
    formState,
    pvModItemsLength,
    invItemsLength,
    additionalItemsLength,
    modPriceUnits,
    invPriceUnits,
    eqpPriceUnits,
  } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/kits/kit-data/${auth.userId}/${kitId}`;
    await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        ...setPostFormDataRegisterOrEditKit(
          formState,
          pvModItemsLength,
          invItemsLength,
          additionalItemsLength
        ),
        modPriceUnits,
        invPriceUnits,
        eqpPriceUnits,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );
  } catch (err) { }
};

// UPLOAD KITS ///////////////////////////////////////////
//////////////////////////////////////////////////////////
interface UploadKitsProps extends FetchProps {
  mapping: Record<string, string>;
  fileContent: string;
}

export const uploadKits = async ({
  sendRequest,
  auth,
  mapping,
  fileContent
}: UploadKitsProps) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/kits/upload/${auth.userId}`,
      "POST",
      JSON.stringify({
        mapping,
        fileContent
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );
  } catch (err) {
    throw err;
  }
};

// USE KIT ///////////////////////////////////////////////
//////////////////////////////////////////////////////////
interface UseKitProps extends FetchProps {
  settingsCtx: SettingsContextProps;
  loadedKit: KitData;
  history: History<unknown>;
  pid: string;
}

export const chooseKit = async (props: UseKitProps) => {
  const { sendRequest, auth, loadedKit, settingsCtx, history, pid } = props;
  const hasPriceEditPerm =
    auth.type === "MANAGER" ||
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip.user === auth.userId;
    })?.pricingEdit;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/proposals/step-2-registered-kit/${loadedKit.id}/${pid}`;
    await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        frontPrice:
          auth.type === "VENDOR" &&
            !settingsCtx.permissions.vendorPriceEdit &&
            !hasPriceEditPerm
            ? loadedKit.kitData.kitPrice
            : 0,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    history.push(`/proposta/gerar-proposta/escolha/precificacao/${pid}`);
  } catch (err) { }
};

// DELETE SELECTED KITS //////////////////////////////////
//////////////////////////////////////////////////////////
interface DeleteSelectedKitsProps extends FetchProps {
  kitsIds: string[];
  setLoadedItems: React.Dispatch<React.SetStateAction<KitData[]>>;
}

export const deleteSelectedKits = async (props: DeleteSelectedKitsProps) => {
  const { sendRequest, auth, kitsIds, setLoadedItems } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/kits/selected`;
    await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        kitsIds,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    setLoadedItems((prevValues) => {
      return prevValues.filter((item) => {
        return !kitsIds?.includes(item?.id);
      });
    });
  } catch (err) { }
};

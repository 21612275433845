import Popover from "@material-ui/core/Popover";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useHistory } from "react-router-dom";

import { ButtonTealContained } from "../../shared/components/MUIThemed/ButtonTealContained";
import { FileUploadSemantic } from "../../shared/components/FormElements/FileUploadSemantic";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { InputSelectRequired } from "../../shared/components/FormElements/InputSelectRequired";
import { useForm } from "../../shared/hooks/formHook";
import { SettingsContext } from "../../shared/context/settingsContext";
import { editCustomerAssociates } from "../api/customersAPI";
import { openWhatsapp } from "../../shared/util/openWhatsapp";
import {
  ArchiveData,
  AuthContextProps,
  FinancingData,
} from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface FormUploadDocsCNPJProps {
  auth: AuthContextProps;
  financing: FinancingData;
  setLoadedData: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  isLoading: boolean;
  uniqueFinProps: FinancingData["proposals"];
  setTab: React.Dispatch<React.SetStateAction<number>>;
  isSmallWidth: boolean;
}

const OPTIONS: string[] = [];
for (let i = 1; i < 26; i++) {
  OPTIONS.push(`${i}`);
}

export const FormUploadDocsCNPJ = (props: FormUploadDocsCNPJProps) => {
  const {
    auth,
    financing,
    sendRequest,
    setLoadedData,
    isLoading,
    uniqueFinProps,
    setTab,
    isSmallWidth,
  } = props;
  const classes = PopoverFormStyles();
  const settingsCtx = useContext(SettingsContext);
  const [associates, setAssociates] = useState<number[]>([]);
  const { formState, inputHandler, reportInvalid } = useForm({}, false);
  const history = useHistory();

  useEffect(() => {
    if (!!financing?.customer?.associates) {
      let ass: number[] = [];
      for (let i = 0; i < financing.customer.associates; i++) {
        ass.push(i);
      }
      setAssociates(ass);
    }
  }, [financing?.customer?.associates]);

  const customerArchive = financing.customer?.archive as ArchiveData;
  const cnhFiles = customerArchive?.files?.filter((f) =>
    f.semantic?.includes("CNH Sócio")
  );
  const rgFiles = customerArchive?.files?.filter((f) =>
    f.semantic?.includes("RG Sócio")
  );
  const irFiles = customerArchive?.files?.filter((f) =>
    f.semantic?.includes("IR Sócio")
  );
  const energyBillFile = customerArchive?.files?.find(
    (f) => f.semantic === "Conta de Energia"
  );
  const dreFile = customerArchive?.files?.find((f) => f.semantic === "DRE");
  const balanceFile = customerArchive?.files?.find(
    (f) => f.semantic === "Balanço"
  );

  const openWhatsappHandler = () => {
    openWhatsapp(
      "31986281765",
      "Quero contratar um financiamento pré-aprovado."
    );
  };

  const editCustomerAssociatesHandler = (associates: number) => {
    editCustomerAssociates({
      sendRequest,
      auth,
      setLoadedFinancings: setLoadedData,
      cid: financing?.customer?._id,
      associates,
    });
  };

  return (
    <Fragment>
      <form style={{ zIndex: 502 }} className={classes.formRoot3}>
        {isLoading && <LoadingSpinnerOverlayRegular image />}
        <div className={classes.inputContainer}>
          <InputSelectRequired
            trackFocus
            id="associates"
            label="Qtd. de Sócios"
            variant="outlined"
            onInput={inputHandler}
            options={OPTIONS}
            initialValue={financing?.customer?.associates?.toString() || ""}
            forceError={formState.inputs?.yearValidity?.forceError}
            onChangeHandler={editCustomerAssociatesHandler}
          />
        </div>
        {!!financing?.customer?.associates && (
          <Fragment>
            <FileUploadSemantic
              fid={financing?._id}
              setLoadedFinancings={setLoadedData}
              cid={financing?.customer?._id}
              initFile={energyBillFile}
              initialFileName={energyBillFile?.name || energyBillFile?.semantic}
              label="Conta de Luz (atualizada)"
              fileSemantic="Conta de Energia"
              folderSemantic="Financiamento"
              helperText="Conta do Último Mês"
              isSmallWidth={isSmallWidth}
              useStatus
            />
            <FileUploadSemantic
              fid={financing?._id}
              setLoadedFinancings={setLoadedData}
              cid={financing?.customer?._id}
              initFile={dreFile}
              initialFileName={dreFile?.name || dreFile?.semantic}
              label="DRE"
              fileSemantic="DRE"
              folderSemantic="Financiamento"
              helperText="DRE"
              isSmallWidth={isSmallWidth}
              useStatus
            />
            <FileUploadSemantic
              fid={financing?._id}
              setLoadedFinancings={setLoadedData}
              cid={financing?.customer?._id}
              initFile={balanceFile}
              initialFileName={balanceFile?.name || balanceFile?.semantic}
              label={
                !isSmallWidth ? "Balanço (Últimos 2 Anos)" : "Balanço (2 Anos)"
              }
              fileSemantic="Balanço"
              folderSemantic="Financiamento"
              helperText="Balanço dos Últimos 2 Anos"
              isSmallWidth={isSmallWidth}
              style={{ marginBottom: "1rem" }}
              useStatus
            />
            {associates?.map((a, i) => {
              return (
                <div
                  className={
                    classes.groupContainer +
                    " " +
                    (associates?.length - 1 === i
                      ? classes.groupContainerLast
                      : "")
                  }
                  key={`associate-${i}`}
                >
                  <span className={classes.groupContainerLabel}>
                    Sócio {i + 1}
                  </span>
                  <FileUploadSemantic
                    fid={financing?._id}
                    setLoadedFinancings={setLoadedData}
                    cid={financing?.customer?._id}
                    initFile={rgFiles?.[i]}
                    initialFileName={
                      rgFiles?.[i]?.name || rgFiles?.[i]?.semantic
                    }
                    label={`RG`}
                    fileSemantic={`RG Sócio ${i + 1}` as "RG Sócio"}
                    key={`rg-socio-${i}`}
                    folderSemantic="Financiamento"
                    helperText="RG"
                    isSmallWidth={isSmallWidth}
                    useStatus
                  />
                  <FileUploadSemantic
                    fid={financing?._id}
                    setLoadedFinancings={setLoadedData}
                    cid={financing?.customer?._id}
                    initFile={cnhFiles?.[i]}
                    initialFileName={
                      cnhFiles?.[i]?.name || cnhFiles?.[i]?.semantic
                    }
                    label={`CNH`}
                    fileSemantic={`CNH Sócio ${i + 1}` as "CNH Sócio"}
                    key={`cnh-socio-${i}`}
                    folderSemantic="Financiamento"
                    helperText="CNH"
                    isSmallWidth={isSmallWidth}
                    useStatus
                  />
                  <FileUploadSemantic
                    fid={financing?._id}
                    setLoadedFinancings={setLoadedData}
                    cid={financing?.customer?._id}
                    initFile={irFiles?.[i]}
                    initialFileName={
                      irFiles?.[i]?.name || irFiles?.[i]?.semantic
                    }
                    label={`Imposto de Renda`}
                    fileSemantic={`IR Sócio ${i + 1}` as "IR Sócio"}
                    key={`ir-socio-${i}`}
                    folderSemantic="Financiamento"
                    helperText="IR"
                    isSmallWidth={isSmallWidth}
                    style={{ marginBottom: 0 }}
                    useStatus
                  />
                </div>
              );
            })}
          </Fragment>
        )}
        {!isLoading && (
          <ButtonTealContained
            variant="contained"
            color="primary"
            onClick={openWhatsappHandler}
          >
            <WhatsAppIcon />
            &nbsp; CHAMAR CONSULTOR
          </ButtonTealContained>
        )}
        {/* {isLoading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "1.5rem",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        )} */}
      </form>
    </Fragment>
  );
};

import React, { useContext, Fragment } from "react";
import { ImportMapping } from "../../shared/components/ImportMapping/ImportMapping";
import { uploadPreSales } from "../api/preSalesAPI"; // Precisamos criar esta função
import { useHttpClient } from "../../shared/hooks/httpHook";
import { AuthContext } from "../../shared/context/authContext";
import { ModalError } from "../../shared/components/UIElements/ModalError";

const availableProperties = [
  {
    value: "nome_cliente",
    aliases: ["nome_cliente", "nome", "cliente"],
    required: true,
  },
  {
    value: "email_cliente",
    aliases: ["email_cliente", "email", "e-mail"],
    required: false,
  },
  {
    value: "cpf_ou_cnpj_cliente",
    aliases: ["cpf_ou_cnpj_cliente", "cpf", "cnpj"],
    required: false,
  },
  { value: "rg_cliente", aliases: ["rg_cliente", "rg"], required: false },
  {
    value: "telefone_cliente",
    aliases: ["telefone_cliente", "telefone"],
    required: false,
  },
  {
    value: "whatsapp_cliente",
    aliases: ["whatsapp_cliente", "whatsapp", "celular"],
    required: false,
  },
  {
    value: "UF_cliente",
    aliases: ["UF_cliente", "uf", "estado"],
    required: false,
  },
  {
    value: "cidade_cliente",
    aliases: ["cidade_cliente", "cidade"],
    required: false,
  },
  {
    value: "origem_cliente",
    aliases: ["origem_cliente", "origem"],
    required: false,
  },
  {
    value: "endereco_cliente",
    aliases: ["endereco_cliente", "endereco"],
    required: false,
  },
  { value: "cep_cliente", aliases: ["cep_cliente", "cep"], required: false },
  {
    value: "logradouro_cliente",
    aliases: ["logradouro_cliente", "logradouro"],
    required: false,
  },
  {
    value: "numero_cliente",
    aliases: ["numero_cliente", "numero"],
    required: false,
  },
  {
    value: "bairro_cliente",
    aliases: ["bairro_cliente", "bairro"],
    required: false,
  },
  {
    value: "complemento_cliente",
    aliases: ["complemento_cliente", "complemento"],
    required: false,
  },
  {
    value: "profissao_cliente",
    aliases: ["profissao_cliente", "profissao"],
    required: false,
  },
  {
    value: "nacionalidade_cliente",
    aliases: ["nacionalidade_cliente", "nacionalidade"],
    required: false,
  },
  {
    value: "renda_mensal_cliente",
    aliases: [
      "renda_mensal_cliente",
      "faturamento_mensal_cliente",
      "renda",
      "faturamento",
    ],
    required: false,
  },
  {
    value: "data_de_registro_cliente",
    aliases: ["data_de_registro_cliente", "data_registro"],
    required: false,
  },
  {
    value: "nome_responsavel_cliente",
    aliases: ["nome_responsavel_cliente", "nome_responsavel"],
    required: false,
  },
  {
    value: "cpf_responsavel_cliente",
    aliases: ["cpf_responsavel_cliente", "cpf_responsavel"],
    required: false,
  },
  {
    value: "observacoes_cliente",
    aliases: ["observacoes_cliente", "observacoes"],
    required: false,
  },
  { value: "nome_lead", aliases: ["nome_lead"], required: true },
  { value: "valor_lead", aliases: ["valor_lead", "valor"], required: true },
  { value: "status_lead", aliases: ["status_lead", "status"], required: false },
  {
    value: "data_registro_lead",
    aliases: ["data_registro_lead"],
    required: false,
  },
  {
    value: "data_finalizacao_lead",
    aliases: ["data_finalizacao_lead"],
    required: false,
  },
  {
    value: "etapa_funil_lead",
    aliases: ["etapa_funil_lead", "etapa_funil"],
    required: false,
  },
  { value: "observacoes_lead", aliases: ["observacoes_lead"], required: false },
  {
    value: "id_colaborador_proprietario",
    aliases: [
      "id_colaborador_proprietario",
      "vendedor",
      "proprietario",
      "dono",
    ],
    required: false,
  },
];

export const PreSalesImportMapping: React.FC = () => {
  const { sendRequest, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const handleSubmit = async (
    mapping: Record<string, string>,
    fileContent: string
  ) => {
    try {
      await uploadPreSales({ sendRequest, auth, mapping, fileContent });
      return true;
    } catch (error) {
      console.error("Erro ao fazer upload:", error);
      return false;
    }
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ImportMapping
        availableProperties={availableProperties}
        onSubmit={handleSubmit}
        importType="preSale"
        title="Importação - Mapeamento de Leads"
      />
    </Fragment>
  );
};

import { TemplateData, CashFlowData } from "../data/types";

export const cashFlowCalculation = (
  initialInvestment: number,
  cashFlow: CashFlowData[],
  template: TemplateData,
  isMobile: boolean
): {
  year: string;
  flow1: number;
  flow2: number;
  flow3: number;
}[] => {
  let cashFlowComp: {
    year: string;
    flow1: number;
    flow2: number;
    flow3: number;
  }[] = [
    {
      year: "1",
      flow1: cashFlow[0].flow,
      flow2: Math.round(initialInvestment),
      flow3: Math.round(initialInvestment),
    },
  ];
  const correctedInterestRate1 = template.section7FinViability.interestRate1
    ? template.section7FinViability.interestRate1 / 100 + 1
    : 0;
  const correctedInterestRate2 = template.section7FinViability.interestRate2
    ? template.section7FinViability.interestRate2 / 100 + 1
    : 0;
  for (let i = 1; i < 25; i++) {
    cashFlowComp.push({
      year: (i + 1).toString(),
      flow1: cashFlow[i].flow,
      flow2: Math.round(cashFlowComp[i - 1].flow2 * correctedInterestRate1),
      flow3: Math.round(cashFlowComp[i - 1].flow3 * correctedInterestRate2),
    });
  }
  if (isMobile) {
    cashFlowComp = cashFlowComp.filter((data, index) => {
      return index % 2 === 0;
    });
  }
  return cashFlowComp;
};

import React, { useState, Fragment } from "react";

import { MobileNavLinks } from "./MobileNavLinks";
import { ModalPurchaseModule } from "../UIElements/ModalPurchaseModule";
import { BeforeInstallPromptEvent } from "../../../App";
import "./SideMenuStyles.scss";

interface SideMenuProps {
  addToHomescreenEvent: BeforeInstallPromptEvent;
  setAddToHomescreenEvent: React.Dispatch<
    React.SetStateAction<BeforeInstallPromptEvent>
  >;
  slug: string;
  hide: boolean;
}

export const SideMenu = (props: SideMenuProps) => {
  const { addToHomescreenEvent, setAddToHomescreenEvent, slug, hide } = props;
  const [showPurchaseModal, setShowPurchaseModule] = useState<boolean>(false);

  const openPurchaseModuleModalHandler = () => {
    setShowPurchaseModule(true);
  };

  return (
    <Fragment>
      <ModalPurchaseModule
        open={showPurchaseModal}
        setOpen={setShowPurchaseModule}
        title="FAÇA O UPGRADE PARA O PLANO EMPRESARIAL"
        content="No plano empresarial você terá acesso a: Website com Simulador/Calculadora Solar, Gerenciador de Contratos, Gerenciador de Arquivos, Gerenciador de Obras, Gerenciador de Projetos, Gerenciador de Pós-Venda, Múltiplas Contas de Colaboradores e muito mais. Chame-nos no WhatsApp e saiba mais sobre o Upgrade do seu plano!"
      />
      <nav className={`side-menu ${hide ? "side-menu--hidden" : ""}`}>
        <MobileNavLinks
          clickCloser={() => {}}
          sideMenu
          addToHomescreenEvent={addToHomescreenEvent}
          setAddToHomescreenEvent={setAddToHomescreenEvent}
          slug={slug}
          openPurchaseModuleModalHandler={openPurchaseModuleModalHandler}
        />
      </nav>
    </Fragment>
  );
};

import {
  AuthContextProps,
  FormHookDispState,
  ModuleData,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// GET SINGLE MODULE /////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
interface GetSingleModuleProps extends FetchProps {
  modId: string;
  setLoadedData: React.Dispatch<React.SetStateAction<ModuleData>>;
}

export const getSingleModule = async (props: GetSingleModuleProps) => {
  const { sendRequest, auth, modId, setLoadedData } = props;

  try {
    const responseData: { item: ModuleData } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/modules/single/${modId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      false
    );

    setLoadedData(responseData?.item);
  } catch (err) { }
};

// GET PAGINATED MODULES /////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
interface GetPaginatedModulesProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<ModuleData[]>>;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  hidration: boolean;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const getPaginatedModules = async (props: GetPaginatedModulesProps) => {
  const {
    sendRequest,
    auth,
    setLoadedData,
    setShowLoadMore,
    multiplier,
    hidration,
    setFirstLoad,
  } = props;

  try {
    const responseData: { items: ModuleData[]; hasMoreItems: boolean } =
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/modules/paginated/${multiplier}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        },
        false
      );

    setShowLoadMore(responseData?.hasMoreItems);
    setLoadedData((prevValues) => {
      if (!!prevValues && prevValues?.length > 0 && !hidration) {
        return [...prevValues, ...responseData?.items];
      } else {
        return responseData?.items;
      }
    });
    if (setFirstLoad) {
      setFirstLoad(true);
    }
  } catch (err) { }
};

// GET FILTERED MODULES ///////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
interface GetFilteredModulesProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<ModuleData[]>>;
  setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue: string;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  fetchId: string;
  setMultiplier?: React.Dispatch<React.SetStateAction<number>>;
}

export const getFilteredModules = async (props: GetFilteredModulesProps) => {
  const {
    auth,
    sendRequest,
    setLoadedData,
    setFiltered,
    searchValue,
    setShowLoadMore,
    multiplier,
    setMultiplier,
    fetchId,
  } = props;

  try {
    const responseData: { items: ModuleData[]; hasMoreItems: boolean } =
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/modules/filtered/${multiplier}`,
        "POST",
        JSON.stringify({
          fetchId,
          searchValue,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        false,
        "items"
      );

    if (responseData && responseData.items?.length > 0) {
      setLoadedData((prevValues) => {
        if (!!prevValues && prevValues.length > 0 && multiplier > 0) {
          return [...prevValues, ...responseData.items];
        } else {
          return responseData.items;
        }
      });
    }
    setFiltered(true);
    setShowLoadMore(responseData.hasMoreItems);
    if (setMultiplier) {
      setMultiplier(0);
    }
  } catch (err) { }
};

// CREATE MODULE /////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
interface CreateModuleProps extends FetchProps {
  formState: FormHookDispState;
}

export const createModule = async (props: CreateModuleProps) => {
  const { sendRequest, auth, formState } = props;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/modules/single`,
      "POST",
      JSON.stringify({
        model: formState.inputs?.model?.value,
        manufacturer: formState.inputs?.manufacturer?.value,
        power: +formState.inputs?.power?.value,
        warrantyDefect: +formState.inputs?.warrantyDefect?.value,
        warrantyEfficiency: +formState.inputs?.warrantyEfficiency?.value,
        weight: +formState.inputs?.weight?.value || 0,
        mLength: +formState.inputs?.mLength?.value || 0,
        width: +formState.inputs?.width?.value || 0,
        stock: +formState.inputs?.stock?.value || 0,
        price: +formState.inputs?.price?.value || 0,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
  } catch (err) { }
};

// EDIT MODULE /////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
interface EditModuleProps extends FetchProps {
  modId: string;
  formState: FormHookDispState;
}

export const editModule = async (props: EditModuleProps) => {
  const { sendRequest, auth, modId, formState } = props;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/modules/single/${modId}`,
      "PUT",
      JSON.stringify({
        model: formState.inputs?.model?.value,
        manufacturer: formState.inputs?.manufacturer?.value,
        power: +formState.inputs?.power?.value,
        warrantyDefect: +formState.inputs?.warrantyDefect?.value,
        warrantyEfficiency: +formState.inputs?.warrantyEfficiency?.value,
        weight: +formState.inputs?.weight?.value || 0,
        mLength: +formState.inputs?.mLength?.value || 0,
        width: +formState.inputs?.width?.value || 0,
        stock: +formState.inputs?.stock?.value || 0,
        price: +formState.inputs?.price?.value || 0,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
  } catch (err) { }
};

// CLONE MODULE /////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
interface CloneModuleProps extends FetchProps {
  modId: string;
}

export const cloneModule = async (props: CloneModuleProps) => {
  const { sendRequest, auth, modId } = props;

  try {
    const responseData: { itemId: string } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/modules/single/${modId}`,
      "POST",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    return responseData.itemId;
  } catch (err) { }
};

// UPLOAD MODULES ///////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
interface UploadModulesProps extends FetchProps {
  mapping: Record<string, string>;
  fileContent: string;
}

export const uploadModules = async ({
  sendRequest,
  auth,
  mapping,
  fileContent
}: UploadModulesProps) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/modules/many`,
      "POST",
      JSON.stringify({
        mapping,
        fileContent
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
  } catch (err) { }
};

// DELETE MODULE ////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
interface DeleteModuleProps extends FetchProps {
  modId: string;
  setLoadedData: React.Dispatch<React.SetStateAction<ModuleData[]>>;
}

export const deleteModule = async (props: DeleteModuleProps) => {
  const { sendRequest, auth, modId, setLoadedData } = props;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/modules/single/${modId}`,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setLoadedData((prevValues) => {
      return prevValues.filter((item) => {
        return item?._id !== modId;
      });
    });
  } catch (err) { }
};

// DELETE SELECTED MODULE ///////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
interface DeleteSelectedModulesProps extends FetchProps {
  itemsIds: string[];
  setLoadedData: React.Dispatch<React.SetStateAction<ModuleData[]>>;
}

export const deleteSelectedModules = async (
  props: DeleteSelectedModulesProps
) => {
  const { sendRequest, auth, itemsIds, setLoadedData } = props;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/modules/selected`,
      "POST",
      JSON.stringify({ itemsIds }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setLoadedData((prevValues) => {
      return prevValues.filter((item) => {
        return !itemsIds?.includes(item?._id);
      });
    });
  } catch (err) { }
};

// GET FILTERED FOR SELECTION MODULES /////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
interface GetFilteredForSelectionModulesProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<ModuleData[]>>;
  searchValue: string;
}

export const getFilteredForSelectionModules = async (
  props: GetFilteredForSelectionModulesProps
) => {
  const { auth, sendRequest, setLoadedData, searchValue } = props;

  try {
    const responseData: { items: ModuleData[] } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/modules/filtered`,
      "POST",
      JSON.stringify({
        searchValue,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      false
    );

    if (responseData && responseData.items?.length > 0) {
      setLoadedData(responseData.items || []);
    }
  } catch (err) { }
};

import React, { Fragment, useContext } from "react";
import { ImportMapping } from "../../shared/components/ImportMapping/ImportMapping";
import { uploadCustomers } from "../api/customersAPI";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { AuthContext } from "../../shared/context/authContext";
import { ModalError } from "../../shared/components/UIElements/ModalError";

const availableProperties = [
  {
    value: "nome",
    aliases: [
      "nome",
      "name",
      "cliente",
      "nome_completo",
      "razao_social",
      "fullname",
    ],
    required: true,
  },
  {
    value: "email",
    aliases: ["email", "e-mail", "endereco_eletronico", "correio_eletronico"],
    required: false,
  },
  {
    value: "cpf_ou_cnpj",
    aliases: ["cpf", "cnpj", "cpf_ou_cnpj", "documento", "id_fiscal", "tax_id"],
    required: false,
  },
  {
    value: "rg",
    aliases: ["rg", "identidade", "registro_geral"],
    required: false,
  },
  {
    value: "telefone",
    aliases: ["telefone", "phone", "fone", "tel", "numero_telefone"],
    required: false,
  },
  {
    value: "whatsapp",
    aliases: ["whatsapp", "celular", "mobile", "telefone_movel"],
    required: false,
  },
  {
    value: "UF",
    aliases: ["uf", "estado", "state", "provincia"],
    required: false,
  },
  {
    value: "cidade",
    aliases: ["cidade", "city", "municipio", "localidade"],
    required: false,
  },
  {
    value: "origem",
    aliases: ["origem", "origin", "fonte", "source", "canal"],
    required: false,
  },
  {
    value: "endereco",
    aliases: ["endereco", "address", "local", "localizacao"],
    required: false,
  },
  {
    value: "cep",
    aliases: ["cep", "postal_code", "codigo_postal", "zip"],
    required: false,
  },
  {
    value: "logradouro",
    aliases: ["logradouro", "street", "rua", "avenida", "via"],
    required: false,
  },
  {
    value: "numero",
    aliases: ["numero", "number", "num", "numero_endereco"],
    required: false,
  },
  {
    value: "bairro",
    aliases: ["bairro", "district", "neighborhood", "zona"],
    required: false,
  },
  {
    value: "complemento",
    aliases: ["complemento", "complement", "adicional", "info_adicional"],
    required: false,
  },
  {
    value: "profissao",
    aliases: ["profissao", "profession", "ocupacao", "cargo"],
    required: false,
  },
  {
    value: "nacionalidade",
    aliases: ["nacionalidade", "nationality", "pais_origem", "cidadania"],
    required: false,
  },
  {
    value: "renda_mensal",
    aliases: ["renda_mensal", "income", "salario", "ganhos_mensais"],
    required: false,
  },
  {
    value: "faturamento_mensal",
    aliases: [
      "faturamento_mensal",
      "monthly_revenue",
      "receita_mensal",
      "vendas_mensais",
    ],
    required: false,
  },
  {
    value: "data_de_registro",
    aliases: [
      "data_de_registro",
      "registration_date",
      "data_cadastro",
      "data_inscricao",
    ],
    required: false,
  },
  {
    value: "nome_responsavel",
    aliases: [
      "nome_responsavel",
      "responsible_name",
      "nome_contato",
      "representante",
    ],
    required: false,
  },
  {
    value: "cpf_responsavel",
    aliases: [
      "cpf_responsavel",
      "responsible_cpf",
      "documento_responsavel",
      "id_responsavel",
    ],
    required: false,
  },
  {
    value: "observacoes",
    aliases: [
      "observacoes",
      "notes",
      "comentarios",
      "anotacoes",
      "informacoes_adicionais",
    ],
    required: false,
  },
  {
    value: "id_colaborador_proprietario",
    aliases: [
      "id_colaborador_proprietario",
      "vendedor",
      "proprietario",
      "dono",
    ],
    required: false,
  },
];

export const CustomerImportMapping: React.FC = () => {
  const { sendRequest, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const handleSubmit = async (
    mapping: Record<string, string>,
    fileContent: string
  ) => {
    try {
      await uploadCustomers({ sendRequest, auth, mapping, fileContent });
      return true;
    } catch (error) {
      console.error("Erro ao fazer upload:", error);
      return false;
    }
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ImportMapping
        availableProperties={availableProperties}
        onSubmit={handleSubmit}
        importType="customer"
        title="Importação - Mapeamento de Clientes"
      />
    </Fragment>
  );
};

import Popover from "@material-ui/core/Popover";
import React, { Fragment, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Docxtemplater from "docxtemplater";
import InspectModule from "docxtemplater/js/inspect-module";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";

import { ModalError } from "../../shared/components/UIElements/ModalError";
import { ModalWarning } from "../../shared/components/UIElements/ModalWarning";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { useForm } from "../../shared/hooks/formHook";
import { PopoverFormStyles } from "./PopoverFormStyles";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { getContractTagsValues } from "../../contract/util/getContractTagsValues";
import { getProposalTagsValues } from "../../proposal/util/getProposalTagsValues";
import { getUserContracts } from "../../contract/api/contractsAPI";
import { generateContract } from "../../contract/api/salesAPI";
import { fetchUserData } from "../../general/api/userAPI";
import { getTemplate } from "../../proposal/api/templateAPI";
import { cashFlowCalculation } from "../../shared/util/cashFlowCalculation";
import {
  setMonthlyGenerationData,
  setCashFlowData,
} from "../../shared/data/dynamic";
import {
  AuthContextProps,
  KanbanFilledData,
  ContractData,
  UserData,
  TemplateData,
} from "../../shared/data/types";

interface PopoverFormGenerareContractProps {
  itemId: string;
  item: KanbanFilledData["items"][0];
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement>
  >;
  auth: AuthContextProps;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

let num = 0;

export const PopoverFormGenerareContract = (
  props: PopoverFormGenerareContractProps
) => {
  const { itemId, anchorEl, setAnchorEl, auth, item, setKanbanData } = props;
  const customerProposals = item?.customer?.proposals || [];
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const [chosenProp, setChosenProp] = useState<string>(
    customerProposals?.slice(-1)?.[0]?.id || "none"
  );
  const [loadedContracts, setLoadedContracts] = useState<ContractData[]>([]);
  const [loadedUser, setLoadedUser] = useState<UserData>(null);
  const [docType, setDocType] = useState<"CONTRATO" | "PROPOSTA">("CONTRATO");
  const [chosenContract, setChosenContract] = useState<string>("");
  const [chosenPropTemplate, setChosenPropTemplate] =
    useState<TemplateData["pdfTemplates"][0]>(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const [loadedTemplate, setLoadedTemplate] = useState<TemplateData>(null);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { formState, inputHandler } = useForm({}, true);
  const genContract = loadedContracts?.find((c) => {
    return c?._id === chosenContract;
  });
  const genProp = customerProposals?.find((c) => {
    return c?.id === chosenProp;
  });
  const templateOpts =
    loadedTemplate?.pdfTemplates?.filter((t) => {
      return !t?.type || t?.type === "SALES";
    }) || [];

  useEffect(() => {
    if (auth?.isLoggedIn) {
      getUserContracts({
        sendRequest,
        auth,
        setLoadedContracts,
      });
    }
  }, [sendRequest, auth?.isLoggedIn]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      fetchUserData({ sendRequest, auth, setLoadedData: setLoadedUser });
      getTemplate({ sendRequest, auth, setLoadedTemplate });
    }
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (loadedContracts?.length > 0) {
      setChosenContract(loadedContracts?.[0]?._id);
    }
  }, [loadedContracts]);

  useEffect(() => {
    if (customerProposals?.length > 0) {
      setChosenProp(customerProposals?.slice(-1)?.[0]?.id);
    } else {
      setChosenProp("none");
    }
  }, [customerProposals]);

  useEffect(() => {
    if (templateOpts?.[0]) {
      setChosenPropTemplate(templateOpts?.[0]);
    }
  }, [templateOpts?.[0]]);

  const changePropValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChosenProp(e.target.value);
  };

  const changeContractValueHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChosenContract(e.target.value);
  };

  const changeDocTypeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDocType(e.target.value as "CONTRATO" | "PROPOSTA");
  };

  const changePropTemplateHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const c = templateOpts?.find((t) => t._id === e.target.value);

    setChosenPropTemplate(c);
  };

  const loadFile = (
    url: string,
    callback: (err: Error, data: string) => void
  ) => {
    PizZipUtils.getBinaryContent(url, callback);
  };

  const populateTemplate = (err: Error, data: string) => {
    if (err) {
      console.log(err);
      alert("Erro ao tentar carregar o arquivo.");
      setIsGenerating(false);
      return;
    }
    const iModule = new InspectModule();
    const zip = new PizZip(data);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
      modules: [iModule],
    });
    // const tags = iModule.getAllTags();

    // if (!tags || Object.keys(tags)?.length === 0) {
    //   setOpenWarning(true);
    //   setIsGenerating(false);
    //   return;
    // }

    if (docType === "CONTRATO") {
      doc.render(getContractTagsValues(item, num, genProp, loadedUser));
    } else if (docType === "PROPOSTA") {
      const prop = customerProposals?.find((p) => p._id === chosenProp);

      if (!!prop) {
        const monthlyGeneration = setMonthlyGenerationData({
          proposal: prop,
        });
        const cashFlow = setCashFlowData({ proposal: prop });

        const cashFlowComp = cashFlowCalculation(
          prop.revenueData.finalPrice,
          cashFlow,
          loadedTemplate,
          false
        );

        if (monthlyGeneration && cashFlow && cashFlowComp) {
          doc.render(
            getProposalTagsValues(
              prop,
              cashFlow,
              cashFlowComp,
              monthlyGeneration,
              loadedTemplate
            )
          );
        } else {
          alert("Erro ao tentar carregar o arquivo.");
          setIsGenerating(false);
          return;
        }
      } else {
        alert("Erro ao tentar carregar o arquivo.");
        setIsGenerating(false);
        return;
      }
    }

    const blob = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    }); //Output the document using Data-URI
    const file = new File(
      [blob],
      `Contrato ${item?.customer?.name || ""}.docx`,
      {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }
    );

    generateContract({
      sendRequest,
      auth,
      contractId: genContract?._id || "",
      saleId: item?._id || "",
      file: file,
      name:
        docType === "CONTRATO"
          ? genContract?.name || ""
          : chosenPropTemplate?.name || "",
      setKanbanData,
    })
      .then(() => {
        saveAs(blob, `Contrato ${item?.customer?.name || ""}.docx`);
      })
      .finally(() => {
        setIsGenerating(false);
        setAnchorEl(null);
      });
  };

  const generateContractHandler = async () => {
    setIsGenerating(true);

    num = await getUserContracts({
      sendRequest,
      auth,
      setLoadedContracts,
    });

    if (
      docType === "CONTRATO" &&
      genContract &&
      genProp &&
      !!genContract.fileUrl
    ) {
      loadFile(
        `${process.env.REACT_APP_ASSET_URL}/${genContract.fileUrl}`,
        populateTemplate
      );
    } else if (docType === "PROPOSTA" && !!chosenPropTemplate) {
      loadFile(
        `${process.env.REACT_APP_ASSET_URL}/${chosenPropTemplate.url}`,
        populateTemplate
      );
    } else {
      setIsGenerating(false);
    }
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ModalWarning
        onClose={() => {
          setOpenWarning(false);
        }}
        open={openWarning}
        warning="Este modelo de contrato precisa de configuração. Chame nosso suporte para receber orientações de como configurar seus templates de contratos."
      />
      <Popover
        id={itemId}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 502 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form className={classes.formRoot}>
          {auth.activeModules?.includes("Proposta 2.0") && (
            <FormControl>
              <InputLabel>Modelo de Documento</InputLabel>
              <Select value={docType} onChange={changeDocTypeHandler}>
                {["CONTRATO", "PROPOSTA"]?.map((option, i) => {
                  return (
                    <MenuItem key={`opt-1-${i}`} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {docType === "CONTRATO" && (
            <FormControl>
              <InputLabel>Selecionar contrato</InputLabel>
              <Select
                value={chosenContract}
                onChange={changeContractValueHandler}
              >
                {loadedContracts?.map((option, i) => {
                  return (
                    <MenuItem key={`opt-2-${i}`} value={option?._id}>
                      {option?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {docType === "PROPOSTA" && (
            <FormControl>
              <InputLabel>Selecionar Template da Proposta</InputLabel>
              <Select
                value={chosenPropTemplate._id}
                onChange={changePropTemplateHandler}
              >
                {templateOpts?.map((option, i) => {
                  return (
                    <MenuItem key={`opt-3-${i}`} value={option._id}>
                      {option.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          <FormControl>
            <InputLabel>Selecionar proposta</InputLabel>
            <Select value={chosenProp} onChange={changePropValueHandler}>
              {customerProposals?.map((option, i) => {
                return (
                  <MenuItem key={`opt-4-${i}`} value={option?.id}>
                    {`#${
                      option.count?.toLocaleString("pt-BR", {
                        minimumIntegerDigits: 5,
                        useGrouping: false,
                      }) || "000000"
                    } - ${addThousandSeparator(
                      option?.realSystemData?.power || 0
                    )} kWp - R$ ${addThousandSeparator(
                      option?.revenueData?.finalPrice || 0
                    )}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {!isLoading && !isGenerating && (
            <Button
              variant="contained"
              color="primary"
              disabled={!genContract || !genProp}
              onClick={() => {
                generateContractHandler();
              }}
            >
              GERAR CONTRATO
            </Button>
          )}
          {(isLoading || isGenerating) && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "1.5rem",
              }}
            >
              <CircularProgress color="primary" />
            </div>
          )}
        </form>
      </Popover>
    </Fragment>
  );
};

import {
  AuthContextProps,
  FinancingData,
  FormHookDispState,
  CustomerData,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// EDIT MONITORING LINK ////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface EditCustomerAssociatesProps extends FetchProps {
  cid: string;
  setLoadedFinancings?: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  associates: number;
}

export const editCustomerAssociates = async (
  props: EditCustomerAssociatesProps
) => {
  const { sendRequest, auth, setLoadedFinancings, cid, associates } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/customers/single/associates/${cid}`;
    const responseData: { customer: CustomerData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        associates: associates || 0,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    if (setLoadedFinancings) {
      setLoadedFinancings((prevValues) => {
        prevValues = prevValues?.map((item) => {
          if (item?.customer?._id === cid) {
            item.customer = responseData.customer;
          }

          return item;
        });

        return prevValues;
      });
    }
  } catch (err) {}
};

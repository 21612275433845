
import {
    AuthContextProps,
} from "../../shared/data/types";

export interface FetchProps {
    sendRequest: (
        url: string,
        method?: string,
        body?: BodyInit,
        headers?: HeadersInit,
        successMessage?: boolean,
        zeroResultsKey?: string
    ) => Promise<any>;
    auth: AuthContextProps;
}

interface GetTemplateProps extends FetchProps {
    importType: string;
    hash: string;
}

interface SaveTemplateProps extends FetchProps {
    templateName: string;
    mapping: any;
    importType: string;
    hash: string;
}

export const saveImportTemplate = async ({ sendRequest, auth, templateName, mapping, importType, hash }: SaveTemplateProps) => {
    try {
        const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/imports/template`,
            'POST',
            JSON.stringify({
                name: templateName,
                mapping,
                importType,
                hash,
            }),
            {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
            }
        );
        return responseData.template;
    } catch (err) {
        throw err;
    }
};

export const getImportTemplate = async ({ sendRequest, auth, importType, hash }: GetTemplateProps) => {
    try {
        const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/imports/template/${importType}/${hash}`,
            'GET',
            null,
            {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
            }
        );
        return responseData.template;
    } catch (err) {
        throw err;
    }
};
import { Fragment, useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Collapse from "@material-ui/core/Collapse";
import PostAddRoundedIcon from "@material-ui/icons/PostAddRounded";

import { useHttpClient } from "../../shared/hooks/httpHook";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { ModalFinancing } from "../../financings/components/ModalFinancing";
import { PopoverAddFinToProposal } from "./PopoverAddFinToProposal";
import { SaleDashFinacingProposalItem } from "./SaleDashFinacingProposalItem";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { removeDuplicateObjectsFromArray } from "../../shared/util/removeDuplicateObjectsFromArray";
import { openWhatsapp } from "../../shared/util/openWhatsapp";
import { getFinancingStage } from "../../financings/util/getFinancingStage";
import { getFinancingCta } from "../../financings/util/getFinancingCta";
import { editFinancingStage } from "../api/financingsAPI";
import { BTG_STATUS_MAP } from "../../shared/data/static";
import {
  FinancingData,
  AuthContextProps,
  KanbanFilledData,
  ArchiveData,
} from "../../shared/data/types";

import "./SaleDashNoteItem.scss";

interface SaleDashFinacingItemProps {
  auth: AuthContextProps;
  customerId: string;
  loadedFinancing: FinancingData;
  setLoadedFinancings: React.Dispatch<React.SetStateAction<FinancingData[]>>;
}

export const SaleDashFinacingItem = (props: SaleDashFinacingItemProps) => {
  const { loadedFinancing, auth, customerId, setLoadedFinancings } = props;
  const { sendRequest, isLoading, error, clearError } = useHttpClient();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const [anchorElForm, setAnchorElForm] = useState<
    null | HTMLLIElement | HTMLButtonElement
  >(null);
  const [openModal, setOpenModal] = useState(false);
  const [isSmallWidth, setIsSmallWidth] = useState<boolean>(false);

  const financingProposals = loadedFinancing?.proposals?.length
    ? loadedFinancing?.proposals
    : ([
        {
          bank: "BTG",
          installments: 0,
          installmentValue: 0,
          gracePeriod: 0,
          entry: 0,
          fee: 0,
          feeYearly: 0,
          totalFinanced: 0,
          totalPayment: 0,
        },
      ] as FinancingData["proposals"]);

  const uniqueBTGFinProps =
    removeDuplicateObjectsFromArray<FinancingData["proposals"][0]>(
      financingProposals?.filter((p) => p.bank === "BTG"),
      "totalPayment"
    ) || [];

  const uniqueFinProps = uniqueBTGFinProps;

  const cta = getFinancingCta(loadedFinancing);

  const preApproved =
    typeof loadedFinancing.btg?.preApproved === "boolean"
      ? loadedFinancing.btg?.preApproved
      : null;

  const { foundStatus, approvedAllDocs, hasChosenProp } =
    getFinancingStage(loadedFinancing);
  const custArchive = loadedFinancing?.customer?.archive as ArchiveData;

  const resizeHandler = () => {
    if (window.innerWidth < 900) {
      setIsSmallWidth(true);
    } else {
      setIsSmallWidth(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    var timer = setTimeout(() => {
      resizeHandler();
    }, 1000);

    return () => {
      window.removeEventListener("resize", resizeHandler);
      clearTimeout(timer);
    };
  }, []);

  const contractHandler = () => {
    if (loadedFinancing?.stage !== "DEAL") {
      editFinancingStage({
        sendRequest,
        auth,
        fid: loadedFinancing?._id,
        setLoadedFinancings: setLoadedFinancings,
      }).then(() => {
        setOpenModal(true);
      });
    } else {
      setOpenModal(true);
    }
  };

  return (
    <Fragment>
      <div
        className={`sale-prop-item sale-prop-item--non-clickable`}
        onClick={(e) => {}}
      >
        <PopoverAddFinToProposal
          anchorEl={anchorElForm}
          setAnchorEl={setAnchorElForm}
          auth={auth}
          financing={loadedFinancing}
          uniqueFinProps={uniqueFinProps}
          setLoadedFinancings={setLoadedFinancings}
        />
        <ModalError error={error} onClear={clearError} />
        <ModalFinancing
          open={openModal}
          setOpen={setOpenModal}
          auth={auth}
          financing={loadedFinancing}
          setLoadedData={setLoadedFinancings}
          sendRequest={sendRequest}
          isLoading={isLoading}
          uniqueFinProps={uniqueFinProps}
          isSmallWidth={isSmallWidth}
        />
        {isLoading && <LoadingSpinnerOverlayRegular image color="primary" />}
        <div className="sale-prop-note-item__data">
          <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
            Proposta:{" "}
            {`#${
              loadedFinancing.proposal?.count?.toLocaleString("pt-BR", {
                minimumIntegerDigits: 5,
                useGrouping: false,
              }) || "000000"
            } - ${addThousandSeparator(
              loadedFinancing.proposal?.realSystemData?.power || 0
            )} kWp - R$ ${addThousandSeparator(
              loadedFinancing.proposal?.revenueData?.finalPrice || 0
            )}`}
          </p>
        </div>
        <div className="sale-prop-note-item__controls">
          <IconButton
            size="small"
            title={open ? "Ver Mais" : "Fechar"}
            className={`table__item-action${open ? "--open" : ""}`}
            onClick={(e) => {
              if (!anchorEl && !anchorElForm) {
                setOpen(!open);
                e.stopPropagation();
              }
            }}
          >
            <ExpandMoreRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
          <IconButton
            size="small"
            title="Adicionar à proposta"
            className="table__item-action"
            disabled={!loadedFinancing?.btg?.preApproved}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorElForm(e.currentTarget);
            }}
          >
            <PostAddRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
        </div>
        <div className="sale-prop-note-item__data sale-prop-note-item__data--full-width">
          <p
            className={`sale-prop-note-item__data-item sale-prop-note-item__data-item--thin u-margin-top-sm`}
          >
            <b>
              {loadedFinancing?.stage !== "DEAL"
                ? "ANÁLISE DE CRÉDITO"
                : "CONTRATAÇÃO"}{" "}
              BTG
              <span className="sale-prop-note-item__txt-highlight">
                {loadedFinancing?.btg?.preApproved ? " - PRÉ-APROVADO" : ""}
              </span>
            </b>
          </p>
          <p
            className={`sale-prop-note-item__status sale-prop-note-item__status--${
              preApproved
                ? foundStatus.color || "neutral"
                : typeof preApproved === "boolean"
                ? "negative"
                : "neutral"
            }`}
          >
            {preApproved
              ? foundStatus?.txt || "-"
              : typeof preApproved === "boolean"
              ? "NEGADO"
              : "PENDENTE"}
          </p>
          {loadedFinancing?.stage !== "DEAL" && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (loadedFinancing?.btg?.preApproved) {
                  contractHandler();
                }
              }}
              className={`sale-prop-note-item__whatsapp-cta sale-prop-note-item__whatsapp-cta--light ${
                !loadedFinancing?.btg?.preApproved
                  ? "sale-prop-note-item__whatsapp-cta--disabled"
                  : ""
              }`}
            >
              {cta?.icon}
              &nbsp; {cta?.txt}
            </div>
          )}
          {loadedFinancing?.stage === "DEAL" && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (loadedFinancing?.btg?.preApproved) {
                  contractHandler();
                }
              }}
              className={`sale-prop-note-item__whatsapp-cta ${
                !loadedFinancing?.btg?.preApproved
                  ? "sale-prop-note-item__whatsapp-cta--disabled"
                  : ""
              }`}
            >
              {cta?.icon}
              &nbsp; {cta?.txt}
            </div>
          )}
        </div>
        <div className="sale-prop-note-item__details">
          <Collapse in={open}>
            <div className="sale-prop-note-item__details-content">
              {uniqueFinProps?.map((p, i) => {
                return (
                  <SaleDashFinacingProposalItem
                    financingProposal={p}
                    financing={loadedFinancing}
                    index={i}
                    key={`financing-prop-${i}`}
                  />
                );
              })}
            </div>
            <div className="sale-prop-note-item__details-content">
              <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
                Atualizado em: <span>{loadedFinancing?.lastUpdated || ""}</span>
              </p>
              <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
                Registro: <span>{loadedFinancing?.registryDate || ""}</span>
              </p>
            </div>
          </Collapse>
        </div>
      </div>
    </Fragment>
  );
};

export const getAllQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params: { [key: string]: string } = {};

  // Use URLSearchParams to parse the query string from location.search
  urlParams?.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};
